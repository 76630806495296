import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ReservationSlot } from '../../models/reservation-slot';
import {
  BehaviorSubject,
  Observable,
  Subscribable,
  catchError,
  firstValueFrom,
  from,
  of,
  switchMap,
} from 'rxjs';
import {
  CollectionReference,
  DocumentData,
  collection,
  getDocs,
  getFirestore,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import { FirebaseApp } from '@angular/fire/app';
import { Response } from '../../models/response';
import { Reservation } from '../../models/reservation';
import { CartService } from '../cart/cart.service';
import { UserReservation } from '../../models/user-reservations';
import { Cart } from '../../models/cart';
import { User } from '../../models/user';
import { Firestore } from '@angular/fire/firestore';
import { User as FireUser, getAuth } from 'firebase/auth';

@Injectable({
  providedIn: 'root',
})
export class ReservationService {
  private db: Firestore;
  private reservationCollection: CollectionReference;
  private userReservations: BehaviorSubject<UserReservation[]> =
    new BehaviorSubject<UserReservation[]>([]);
  public reservationsObserver: Observable<UserReservation[]> =
    this.userReservations.asObservable();
  private user: User | null = null;

  constructor(
    private app: FirebaseApp,
    private authService: AuthService,
    private cartService: CartService,
    private httpClient: HttpClient
  ) {
    this.db = getFirestore(this.app);
    this.reservationCollection = collection(this.db, 'booking');
    this.userStatusObserver();
  }

  private userStatusObserver() {
    this.authService.userObserver.subscribe({
      next: (user) => {
        this.user = user;
        this.getUserReservations();
      },
    });
  }

  private getReservations(): Promise<Reservation[]> {
    let ris: Reservation[] = [];
    if(this.user){
      let q1 = query(
        this.reservationCollection,
        where('userId', '==', this.user.uid),
        where('status', '==', 'ACTIVE'),
        orderBy('executionEnd', 'desc')
      );
      return new Promise((resolve, reject) => {
        getDocs(q1).then((docs) => {
          if(docs.empty){resolve(ris)}
          docs.forEach((doc) => {
            ris.push(this.getReservationFromData(doc.id, doc.data()));
          });
        }).catch(error => {reject(error)}).finally(() => {resolve(ris)});
      })
    }
    return Promise.resolve(ris);
  }

  private getUserReservations() {
    this.getReservations().then(ris => {
      this.cartService.getPayedCarts().subscribe({
        next: (carts) => {
          this.removeReservationAlreadyInCart(ris, carts);
        },
        error: (error) => {
          throw error;
        },
      });
    }).catch(error => {throw error});
  }

  private removeReservationAlreadyInCart(
    reservations: Reservation[],
    carts: Cart[]
  ) {
    for (let i = 0; i < reservations.length; i++) {
      for (let j = 0; j < carts.length; j++) {
        for (let k = 0; k < carts[j].reservations.length; k++) {
          if (reservations[i].id == carts[j].reservations[k].reservation.id) {
            reservations.splice(i, 1);
            i--;
            break;
          }
        }
      }
    }
    let userReservations: UserReservation[] = [];
    let i = 0,
      j = 0;
    while (i < reservations.length && j < carts.length) {
      if (reservations[i].executionEnd > carts[j].timestamp) {
        let b = new UserReservation();
        b.reservation = reservations[i];
        userReservations.push(b);
        i++;
      } else {
        let b = new UserReservation();
        b.cart = carts[j];
        userReservations.push(b);
        j++;
      }
    }
    while (i < reservations.length) {
      let b = new UserReservation();
      b.reservation = reservations[i];
      userReservations.push(b);
      i++;
    }
    while (j < carts.length) {
      let b = new UserReservation();
      b.cart = carts[j];
      userReservations.push(b);
      j++;
    }
    this.userReservations.next(userReservations);
  }

  public getReservationFromData(id: string, data: DocumentData): Reservation {
    let b = data as Reservation;
    b.id = id;
    b.executionEnd = data['executionEnd'].toDate();
    b.executionStart = data['executionStart'].toDate();
    b.timestamp = data['timestamp'].toDate();
    return b;
  }

  public book(
    store: string,
    service: string,
    slot: ReservationSlot,
    friend: string,
    offer: string = ''
  ): Observable<Reservation> {
    return from(this.bookRequest(store, service, slot, friend, offer)).pipe(
      switchMap((response) => {
        if (response.status.code == 'OK') {
          let reservation = response.data.reservation as Reservation;
          reservation.id = response.data.reservationId;
          this.getUserReservations();
          return of(reservation);
        } else {
          throw response;
        }
      }),
      catchError((error) => {
        throw error;
      })
    );
  }

  private bookRequest(
    store: string,
    service: string,
    slot: ReservationSlot,
    friend: string,
    offer: string = ''
  ): Observable<Response> {
    if (this.user) {
      const url = environment.apiBaseUrl + environment.postReservationUrl;
      const options = {
        headers: new HttpHeaders({
          AuthToken: this.user.token as string,
        }),
      };
      const data = {
        selectedStore: {
          id: store,
        },
        selectedService: {
          id: service,
        },
        selectedSlot: {
          startDate: slot.startDate,
          endDate: slot.endDate,
          operatorId: slot.operatorId,
          otherOperators: slot.otherOperators,
        },
        thirdParties: friend != '',
        thirdPartiesName: friend,
        offerId: offer,
      };
      return this.httpClient.post<Response>(url, data, {
        headers: options.headers,
      });
    }
    throw new Error('User not found');
  }

  public delete(r: Reservation): Observable<boolean> {
    return this.deleteRequest(r).pipe(
      switchMap((response: Response) => {
        if (response.status.code == 'OK') {
          this.getUserReservations();
          if (this.user) this.cartService.getCart();
          return of(true);
        } else {
          throw response;
        }
      }),
      catchError((error) => {
        throw error;
      })
    );
  }

  private deleteRequest(b: Reservation): Observable<Response> {
    if (this.user) {
      const httpOptions = {
        headers: new HttpHeaders({
          AuthToken: this.user.token as string,
        }),
      };
      return this.httpClient.delete<Response>(
        environment.apiBaseUrl + environment.deleteReservationUrl + '/' + b.id,
        {
          headers: httpOptions.headers,
        }
      );
    }
    throw new Error('User not found');
  }

  public getAvailableReservationSlots(
    service: string,
    date: string,
    store: string,
    operator: string,
    offer: string = ''
  ): Observable<ReservationSlot[]> {
    return this.getAvailableReservationSlotsRequest(
      service,
      date,
      store,
      operator,
      offer
    ).pipe(
      switchMap((response: Response) => {
        if (response.status.code == 'OK') {
          return of(response.data.availableSlots as ReservationSlot[]);
        } else {
          throw response;
        }
      }),
      catchError((error) => {
        throw error;
      })
    );
  }

  private getAvailableReservationSlotsRequest(
    service: string,
    date: string,
    store: string,
    operator: string,
    offer: string
  ): Observable<Response> {
    if (this.user) {
      const httpOptions = {
        headers: new HttpHeaders({
          AuthToken: this.user.token as string,
        }),
      };
      const params = new HttpParams({
        fromObject: {
          serviceId: service,
          startDate: date,
          storeId: store,
          operatorId: operator,
          offerId: offer,
        },
      });
      return this.httpClient.get<Response>(
        environment.apiBaseUrl + environment.getReservationUrl,
        {
          params: params,
          headers: httpOptions.headers,
        }
      );
    }
    throw new Error('User not found');
  }

  public deletePayedCart(cartId: string): Observable<boolean> {
    return from(this.deletePayedCartRequest(cartId)).pipe(
      switchMap((response) => {
        if (response.status.code == 'OK') {
          this.getUserReservations();
          return of(true);
        } else {
          throw response;
        }
      }),
      catchError((error) => {
        throw error;
      })
    );
  }

  private deletePayedCartRequest(cartId: string): Observable<Response> {
    if (this.user) {
      const options = {
        headers: new HttpHeaders({
          AuthToken: this.user.token as string,
        }),
      };
      return this.httpClient.delete<Response>(
        environment.apiBaseUrl + environment.updateCart + '/' + cartId,
        options
      );
    }
    throw new Error('User not found');
  }

  public removeProductFromPayedCart(
    cartId: string,
    productId: string
  ): Observable<boolean> {
    return from(this.removeProductFromPayedCartRequest(cartId, productId)).pipe(
      switchMap((response) => {
        if (response.status.code == 'OK') {
          this.getUserReservations();
          return of(true);
        } else {
          throw response;
        }
      }),
      catchError((error) => {
        throw error;
      })
    );
  }

  private removeProductFromPayedCartRequest(
    cartId: string,
    productId: string
  ): Observable<Response> {
    if (this.user) {
      const options = {
        headers: new HttpHeaders({
          AuthToken: this.user.token as string,
        }),
      };
      return this.httpClient.delete<Response>(
        environment.apiBaseUrl +
          environment.updateCart +
          '/' +
          cartId +
          '/' +
          productId,
        options
      );
    }
    throw new Error('User not found');
  }
}
