<footer class="bg-retro_gray text-white pt-10 ">
    <div class="grid  justify-center lg:grid-cols-12 gap-4 container mx-auto mb-4">
        <div class="row-span-1 lg:col-span-3">
            <div class="grid grid-rows-2 gap-8">
                <div class="row-span-1">
                    <h3 class="font-semibold text-xs sm:text-sm md:text-lg">SEGUICI SUI SOCIAL:</h3>
                    <div class="flex gap-x-4 mt-2">
                        <a [href]="urls.facebook" target="_blank">
                            <fa-icon size="2xl" [icon]="faFacebook"></fa-icon>
                        </a>
                        <a [href]="urls.instagram" target="_blank">
                            <fa-icon size="2xl" [icon]="faInstagram"></fa-icon>
                        </a>
                        <a [href]="urls.tiktok" target="_blank">
                            <fa-icon size="2xl" [icon]="faTiktok"></fa-icon>
                        </a>
                        <a [href]="urls.youtube" target="_blank">
                            <fa-icon size="2xl" [icon]="faYoutube"></fa-icon> 
                        </a> 
                        <a [href]="urls.email" target="_blank">
                            <fa-icon size="2xl" [icon]="faEnvelope"></fa-icon>
                        </a>
                       
                    </div>
                </div>
                <div class="row-span-1">
                    <h3 class="font-semibold text-xs sm:text-sm md:text-lg">SCARICA LA NOSTRA APP:</h3>
                    <div class="flex gap-x-4 mt-2">
                        <a [href]="urls.appstore" target="_blank">
                            <fa-icon size="2xl" [icon]="faAppStore"></fa-icon>
                        </a>
                        <a [href]="urls.playstore" target="_blank">
                            <fa-icon size="2xl" [icon]="faGooglePlay"></fa-icon>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="row-span-1 lg:col-span-6">
            <h3 class="font-semibold text-xs sm:text-sm md:text-lg">LE NOSTRE SEDI</h3>
            &nbsp;
            <div class="flex" *ngFor="let s of stores">
                <h5 class="font-semibold text-xs sm:text-sm md:text-lg">{{s.city}} - </h5> 
                <p class="font-thin text-xs sm:text-sm md:text-lg"> {{s.address}} - </p>
                <a [href]="'tel:'+s.phoneNumber">{{s.phoneNumber}}</a>
            </div>
        </div>
        <div class="row-span-1 lg:col-span-3">
            <h3 class="font-semibold text-xs sm:text-sm md:text-lg">LINK UTILI</h3>
            &nbsp;
            <p>
                <a [href]="urls.workWithUs" class="font-thin text-xs sm:text-sm md:text-lg">Lavora con noi</a>
            </p>
            <p>
                <button routerLink="/privacy-policy" class="font-thin text-xs sm:text-sm md:text-lg">Privacy Policy</button>
            </p>
            <p>
                <button routerLink="/cookie-policy" class="font-thin text-xs sm:text-sm md:text-lg">Cookie Policy</button>
            </p>
        </div>
    </div>
    <div class="text-center">
        <p class="font-thin text-xs sm:text-sm md:text-lg">Retroscena Barbieri Srl - P.IVA 03433600784 © Copyright 2023 | Tutti i diritti
            riservati</p>
    </div>
</footer>
