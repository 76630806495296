<div class="fixed z-10 w-full h-full backdrop-blur-xl flex justify-center items-center" *ngIf="show">
    <div class="mx-6 rounded-3xl shadow-2xl bg-white justify-center w-[400px] max-h-[450px] md:w-[500px] lg:w-[600px] lg:max-h-[600px] p-6 ">
        <div class="flex justify-between">
            <h2 class="text-xl md:text-2xl lg:text-3xl font-bold text-center">{{titles[current]}}</h2>
        </div>
        <p class="overflow-scroll max-h-[250px] lg:max-h-[350px] no-scroll hide-scrollbar mt-6">{{texts[current]}}</p>
        <div class="flex w-full justify-center mt-4">
            <button (click)="next()" class="rounded-lg bg-default_grey font-semibold text-white h-[50px] w-[300px] lg:w-[379px]">{{ctas[current]}}</button>
        </div>
    </div>
</div>
