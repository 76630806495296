import { Component, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { AuthService } from '../../../services/auth/auth.service';
import { Router, RouterModule } from '@angular/router';
import { SnackbarComponent } from '../../utilities/snackbar/snackbar.component';
import { GoogleButtonComponent } from '../../utilities/google-button/google-button.component';
import { AnimationOptions, LottieModule } from 'ngx-lottie';
import { sendEmailVerification } from 'firebase/auth';

@Component({
  selector: 'app-register',
  standalone: true,
  imports: [LottieModule, CommonModule, FormsModule, ReactiveFormsModule, RouterModule, SnackbarComponent, GoogleButtonComponent],
  templateUrl: './register.component.html',
  styleUrl: './register.component.css'
})
export class RegisterComponent {
  @ViewChild(SnackbarComponent) private snackbar!: SnackbarComponent;
  protected form = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.minLength(3)]),
    surname: new FormControl('', [Validators.required, Validators.minLength(3)]),
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required, Validators.minLength(6)]),
    passwordConfirm: new FormControl('', [Validators.required, Validators.minLength(6)])
  });
  protected currentState: number = 0;
  protected states = {
    LOADING: 0,
    DEFAULT: 1,
    VERIFICATION: 2
  }
  protected loader: AnimationOptions = {
    path: 'assets/lottie/loader.json'
  };
  protected success: AnimationOptions = {
    path: 'assets/lottie/success.json'
  };

  constructor(private authService: AuthService, private router: Router) {
    this.currentState = this.states.DEFAULT;
  }

  protected register(): void {
    if (this.form.valid) {
      if (this.form.value.password != this.form.value.passwordConfirm) {
        this.snackbar.showMessage("Le password non coincidono");
      } else {
        this.authService.register(this.form.value.email as string, this.form.value.password as string).then(userC => {
          if(userC.user){
            sendEmailVerification(userC.user).then((r) => {
              this.currentState = this.states.VERIFICATION;
            }).catch((error) => {
              this.snackbar.showMessage("Errore durante l'invio della mail di verifica");
            });
          }
        }).catch((error) => {
          this.snackbar.showMessage("Errore durante la registrazione");
        });
      }
    } else {
      this.snackbar.showMessage("Compila tutti i campi");
    }
  }

  protected loginWithGoogle(): void {
    this.authService.loginWithGoogle().then(() => {
      this.router.navigate(["/"]);
    }).catch((error) => {
      this.snackbar.showMessage("Errore durante il login con Google");
    });
  }
}
