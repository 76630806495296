import { Component, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, InMemoryScrollingFeature, InMemoryScrollingOptions, Router, RouterLink, RouterOutlet, provideRouter, withInMemoryScrolling } from '@angular/router';
import { FooterComponent } from './components/utilities/footer/footer.component';
import { HeaderComponent } from './components/utilities/header/header.component';
import { CartComponent } from './components/utilities/cart/cart.component';
import { AnimationOptions, LottieModule } from 'ngx-lottie';
import { SnackbarComponent } from './components/utilities/snackbar/snackbar.component';
import { routes } from './app.routes';
import { bootstrapApplication } from '@angular/platform-browser';



@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, SnackbarComponent, CartComponent, CommonModule, RouterOutlet, RouterLink, HeaderComponent, FooterComponent, LottieModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent {
  @ViewChild(SnackbarComponent) snackbar !: SnackbarComponent;
  protected title = 'Retroscena';
  private apiLoaded = false;
  protected hideCart: boolean = true;
  protected loader: AnimationOptions = {
    path: 'assets/lottie/loader.json'
  }
  protected states = {
    LOADING: 0,
    DEFAULT: 1
  }
  protected currentState: number = 0;

  constructor(private router: Router) {

  }

  protected setHide(hide: boolean) {
    this.hideCart = hide;
  }

  public showSnackBar(message: string) {
    this.snackbar.showMessage(message);
  }

  public ngOnInit() {
    this.router.events.subscribe((val) => {
      this.windowScrollToTop();
    });
    if (!this.apiLoaded) {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      document.body.appendChild(tag);
      this.apiLoaded = true;
      this.currentState = this.states.DEFAULT;
    }
  }
  windowScrollToTop() {
    window.scrollTo(0, 0);
  }



}
