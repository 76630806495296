<div *ngIf="cart" class="fixed z-10 w-full h-full backdrop-blur-xl flex justify-center items-center">
    <div 
        class="mx-6 rounded-3xl shadow-2xl bg-white justify-center w-[400px] max-h-[600px] md:w-[500px] lg:w-[600px] lg:max-h-[600px] p-6 ">
        <div class="flex justify-between items-center w-full">
            <div class="w-full text-center">
                <h2 class="text-xl md:text-2xl lg:text-3xl font-bold">Carrello</h2>
            </div>
            <app-cross (click)="closeCart.emit()"></app-cross>
        </div>
        <div class="grid justify-center max-h-[450px] lg:max-h-[350px] overflow-y-scroll">
            <div class="flex flex-col items-center self-start">
                <qrcode [qrdata]="'cart_'+cart.id"></qrcode>
                <p class="text-center font-semibold">{{cart.id}}</p>
            </div>
            <div class="grid gap-y-6 mt-6">
                <div *ngFor="let cp of cart.products">
                    <div class="grid gap-6 place-items-center">
                        <img [src]="cp.product.img" class="rounded-lg w-24 h-24 object-cover">
                        <div class="flex gap-x-3">
                            <p>{{cp.product.name}}</p>
                            <p>{{cp.quantity}}x{{cp.price}}€</p>
                            <button class="text-red-600" (click)="deleteProduct(cp.product.id)">elimina</button>
                        </div>
                    </div>
                    <hr class="border-t-2 border-gray-200 my-8" />
                </div>
                <div *ngFor="let cr of cart.reservations">
                    <div class="grid gap-6 place-items-center">
                        <img [src]="cr.reservation.service.img" class="rounded-lg w-24 h-24 object-cover">
                        <div class="flex gap-x-3">
                            <p>{{cr.reservation.service.name}}</p>
                            <p>{{cr.reservation.executionStart| date: "dd/mm/yyyy"}}</p>
                            <p>{{cr.reservation.executionStart | date: "HH:mm"}}</p>
                            <p>{{cr.price}}€</p>
                        </div>
                    </div>
                    <hr class="border-t-2 border-gray-200 my-8" />
                </div>
                <div class="flex justify-between">
                    <p>Totale</p>
                    <p>{{cart.toPayAmount}}€</p>
                </div>
                <div class="flex justify-center">
                    <button (click)="deleteCart()" class="rounded-lg bg-red-600 text-white py-2 px-8">Elimina
                        carrello</button>
                </div>
            </div>
        </div>
    </div>
</div>