import { Component, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GoogleButtonComponent } from '../../utilities/google-button/google-button.component';
import { AuthService } from '../../../services/auth/auth.service';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { SnackbarComponent } from '../../utilities/snackbar/snackbar.component';
import { StringsService } from '../../../services/strings/strings.service';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [CommonModule, GoogleButtonComponent, SnackbarComponent,FormsModule, ReactiveFormsModule, RouterModule],
  templateUrl: './login.component.html',
  styleUrl: './login.component.css'
})
export class LoginComponent {
  @ViewChild(SnackbarComponent) private snackbar!: SnackbarComponent;
  protected form: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required, Validators.minLength(8)])
  });

  constructor(private authService: AuthService, private router: Router) { }

  protected login(): void {
    if(this.form.valid){
      this.authService.login(this.form.value.email, this.form.value.password).then(()=>{
        this.router.navigate(["/"]);
      }).catch((e)=>{
        this.snackbar.showMessage(e.message);
      })
    }else{
      this.snackbar.showMessage("Inserisci email e password");
    }
  }
  protected loginWithGoogle(): void {
    this.authService.loginWithGoogle().then(()=>{
      this.router.navigate(["/"]);
    }).catch((e)=>{

    })
  }

  saluta(){
    this.router.navigate(["register"]);
    console.log('ciao')
  }
}
