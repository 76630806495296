import { News } from "./news";

export class Store{
    address: string = "";
    available: boolean = false;
    bookingStartHour: Date = new Date();
    city: string = "";
    openingTime: any = null;
    closingTime: any = null;
    coverImage: string = "";
    description: string = "";
    id: string = "";
    name: string = "";
    news: News[] = [];
    phoneNumber: string = "";
  }