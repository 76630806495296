import { CartProduct } from "./cart-product";
import { CartReservation } from "./cart-reservation";
import { Payment } from "./payment";
import { Product } from "./product";
import { Reservation } from "./reservation";
import { Store } from "./store";
import { User } from "./user";

export class Cart {
    id: string = "";
    products: CartProduct[] = [];
    reservations: CartReservation[] = [];
    timestamp: Date = new Date();
    storeId: string = "";
    toPayAmount: number = 0;
    totalAmount: number = 0;
    userId: string = "";
    store!: Store;
    user!: User;
    payment!: Payment;
    paymentStatus!: string;

    constructor(){}
}