import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CrossComponent } from '../../miscellaneous/cross/cross.component';
import { QRCodeModule } from 'angularx-qrcode';
import { Reservation } from '../../../models/reservation';

@Component({
  selector: 'app-user-reservation-info',
  standalone: true,
  imports: [CommonModule, CrossComponent, QRCodeModule],
  templateUrl: './user-reservation-info.component.html',
  styleUrl: './user-reservation-info.component.css'
})
export class UserReservationInfoComponent {
  @Input() reservation: Reservation | null = null;
  @Output() closeReservation: EventEmitter<void> = new EventEmitter<void>();
  @Input() addingToCart: boolean = false;
  @Output() payReservation: EventEmitter<void> = new EventEmitter<void>();

  pay() {
    this.payReservation.emit();
  }

  protected canPay(): boolean {
    if (this.reservation) {
      if (this.reservation.status == "ACTIVE") {
        let date = new Date()
        if (this.reservation.executionStart.getFullYear() >= date.getFullYear()) {
          if (this.reservation.executionStart.getFullYear() == date.getFullYear()) {
            if (this.reservation.executionStart.getMonth() >= date.getMonth()) {
              if (this.reservation.executionStart.getMonth() == date.getMonth()) {
                if (this.reservation.executionStart.getDate() >= date.getDate()) {
                  if (this.reservation.executionStart.getDate() == date.getDate()) {
                    if (this.reservation.executionStart.getHours() >= date.getHours()) {
                      if (this.reservation.executionStart.getHours() == date.getHours()) {
                        if (this.reservation.executionEnd.getMinutes() >= date.getMinutes()) {
                          return false;
                        } else {
                          return true;
                        }
                      } else {
                        return true;
                      }
                    }
                  } else {
                    return true;
                  }
                }
              } else {
                return true;
              }
            }
          } else {
            return true;
          }
        }
      }
    }
    return false;
  }
}
