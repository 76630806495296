import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Reservation } from '../../../models/reservation';
import { ClockComponent } from '../../miscellaneous/clock/clock.component';
import { Cart } from '../../../models/cart';
import { BagComponent } from '../../miscellaneous/bag/bag.component';
import { UserReservation } from '../../../models/user-reservations';

@Component({
  selector: 'app-reservation-card',
  standalone: true,
  imports: [CommonModule, ClockComponent, BagComponent],
  templateUrl: './reservation-card.component.html',
  styleUrl: './reservation-card.component.css'
})
export class ReservationCardComponent {
  @Output() showReservationEmitter = new EventEmitter<void>();
  @Output() showCartEmitter = new EventEmitter<void>();
  @Output() deleteEmitter = new EventEmitter<void>();
  @Input() userReservation!: UserReservation;

  protected showReservation() {
    this.showReservationEmitter.emit();
  }

  protected showCart() {
    this.showCartEmitter.emit();
  }

  protected deleteReservation() {
    this.deleteEmitter.emit();
  }

  protected canDelete(): boolean {
    if(this.userReservation.reservation != null){
      //Controllo se la prenotazione è stata pagata o meno
      if (this.userReservation.reservation.paymentStatus != 'CAPTURABLE') {
        //Controllo se la prenotazione è ancora attiva o meno
        if (this.userReservation.reservation.status == "ACTIVE") {
          let date = new Date();
          date.setMinutes(date.getMinutes()+30);
          return this.userReservation.reservation.executionStart > date;
          
        }
      }
    }
    return false;
  }
}
