<div
    class="grid grid-rows-3 rounded-3xl shadow-2xl w-[310px] h-[467px] md:w-[380px] lg:w-[442.26px] lg:h-[567px] relative">
    <div class="row-span-2 w-full h-full flex items-center justify-center">
        <div class="absolute left-5 top-5 bg-default_grey w-[130.96px] h-[37.8px] text-center rounded-md flex items-center justify-center"
            *ngIf="product.bestSeller">
            <p class="text-white font-bold text-sm px-2">BEST SELLER</p>
        </div>
        <img [src]="product.img" style="object-fit: cover; object-position: center; display: block;"
            class=" h-[250px] self-center justify-self-center">
    </div>
    <div
        class="row-span-1 h-full rounded-b-3xl bg-white flex flex-col justify-end items-center space-y-4 text-center pb-4">
        <h1 class="text-xl md:text-2xl lg:text-3xl font-bold mb-4">{{product.name}}</h1>
        <button (click)="addToCart()"
                        class="uppercase  rounded-md bg-default_grey font-semibold text-white h-[50px] w-[270px] lg:w-[379px]" [ngClass]="(addingToCart) ? 'opacity-60': ''" [disabled]="addingToCart">{{(addingToCart) ? ("aggiungo" | uppercase) : "aggiungi al carrello" | uppercase}}</button>
        
        <button (click)="showDescription()" class=" underline font-light ">leggi la descrizione</button>
    </div>
</div>