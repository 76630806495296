<div class="flex justify-center" style="background-image: url('assets/images/grunge_bg.png');">
    <h1 class="text-3xl md:text-5xl lg:text-6xl font-bold my-8 text-white">PROFILO</h1>
</div>

<div class="container mx-auto my-6 px-6 min-h-screen">
    <div *ngIf="currentState == states.LOADING">
        <div class="h-screen flex justify-center items-center">
            <ng-lottie [options]="loader" class="lg:h-[300px]"></ng-lottie>
        </div>
    </div>
    <div *ngIf="user">
      <div *ngIf="currentState == states.EDIT">
          <div *ngIf="user">
              <form [formGroup]="editForm" class="flex justify-center">
                  <div class="max-w-md w-full text-center">
                      <h2 class="text-xl md:text-2xl lg:text-3xl font-semibold my-8">Modifica il profilo</h2>
                      <div class="max-w-md mx-auto my-8">
                          <label class="block text-gray-700 text-left font-bold mb-2">Nome e cognome</label>
                          <div class="relative rounded-md shadow-lg">
                              <input type="text" id="name" name="name" 
                                  class="form-input py-2 px-4 block w-full leading-5 rounded-md transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                  placeholder="Esempio: Luca Rossi" formControlName="name" />
                          </div>
                      </div>
                      <div>
                          <label class="block text-gray-700 text-left font-bold mb-2">Data di nascita</label>
                          <div class="relative rounded-md shadow-lg">
                              <input type="date" id="birthday" name="birthday" formControlName="birthDay"  
                                  class="form-input py-2 px-4 block w-full leading-5 rounded-md transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
                          </div>
                      </div>
                      <div>
                          <label class="block text-gray-700 text-left font-bold mb-2">Numero di telefono</label>
                          <div class="relative rounded-md shadow-lg">
                              <input type="text" id="cellphone" name="cellphone" formControlName="phoneNumber"
                                  class="form-input py-2 px-4 block w-full leading-5 rounded-md transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                  placeholder="Esempio: 3333333333" />
                          </div>
                      </div>
                      <div>
                          <label class="block text-gray-700 text-left font-bold mb-2">Salone preferito</label>
                          <select id="selectOption" name="selectOption" formControlName="favouriteStoreId"
                              class="form-select py-2 px-4 block w-full leading-5 rounded-md transition duration-150 ease-in-out sm:text-sm sm:leading-5">
                              <option value="" disabled selected hidden>Seleziona un'opzione</option>
                              <option *ngFor="let s of stores" [selected]="s.id == user.favouriteStoreId" [value]="s.id">
                                  {{s.city}}
                              </option>
                          </select>
                      </div>
      
                      <div class="max-w-md mx-auto my-8">
                          <label class="block text-gray-700 text-left font-bold mb-2">Email</label>
                          <div class="relative rounded-md shadow-lg">
                              <input disabled="true" type="email" id="email" name="email" [value]="user.email"
                                  class="form-input py-2 px-4 block w-full leading-5 rounded-md transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                  placeholder="Esempio: nome@example.com" />
                          </div>
                      </div>
                      
                      <div *ngIf="!user.verified">
                          <p class="text-red-700 mb-3">Email non verificata!</p>
                          <button class="text-gray-700 mb-12" (click)="sendEmailVerification()">Clicca qui per inviare email di verifica</button>
                      </div>
      
                      <p class="text-gray-400 mb-12">I dati richiesti saranno utilizzati per identificarti e contattarti
                          in
                          caso di
                          imprevisti che comportano l'assenza
                          dell'operatore scelto. La data di nascita per offerte speciali e profilazione a scopo
                          commerciale
                      </p>
                      <div class="flex flex-col gap-6">
                          <button (click)="updateUserData()" [ngClass]="{'opacity-50': isLoading}"
                              class="bg-green-700 font-semibold text-white px-2 py-2 md:px-8 md:py-4">{{isLoading?
                              'SALVATAGGIO IN CORSO...': 'SALVA' }}</button>
                          <div class="grid grid-cols-2 gap-4">
                              <button (click)="logout()"
                                  class="  font-semibold shadow-xl px-2 py-2 md:px-8 md:py-4">ESCI</button>
                              <button (click)="showUserCard()"
                                  class=" font-semibold shadow-xl px-2 py-2 md:px-8 md:py-4">VISUALIZZA CARTA</button>
                          </div>
                          <button (click)="deleteAccount()"
                          class="bg-red-700 font-semibold text-white px-2 py-2 md:px-8 md:py-4">ELIMINA
                          ACCOUNT</button>
                      </div>
                  </div>
              </form>
          </div>        
      </div>
      <div *ngIf="currentState == states.DELETE">
          <div class="flex justify-center">
              <div class="max-w-md w-full text-center">
                  <div class="flex flex-wrap gap-6 justify-center">
                      <div class="max-w-md w-full flex items-center">
                          <app-left-arrow (click)="back()"></app-left-arrow>
                          <p>indietro</p>
                      </div>
                  </div>
                  <h2 class="text-xl md:text-2xl lg:text-3xl font-semibold my-8 text-center">Per procedere con
                      l'eliminazione
                      effettua nuovamente l'accesso</h2>
                  <form [formGroup]="deleteForm" (ngSubmit)="loginForDelete()">
                      <div class="max-w-md mx-auto my-8">
                          <label class="block text-gray-700 text-left font-bold mb-2">Email</label>
                          <div class="relative rounded-md shadow-lg">
                              <input type="email" id="email" name="email" placeholder="Esempio: nome@mail.it"
                                  formControlName="email"
                                  class="form-input py-2 px-4 block w-full leading-5 rounded-md transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
                          </div>
                      </div>
                      <div class="max-w-md mx-auto my-8">
                          <label class="block text-gray-700 text-left font-bold mb-2">Password</label>
                          <div class="relative rounded-md shadow-lg">
                              <input type="password" id="password" name="password" formControlName="password"
                                  class="form-input py-2 px-4 block w-full leading-5 rounded-md transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
                          </div>
                      </div>
                      <div class="grid grid-rows-1 justify-center gap-4 my-4">
                          <button (click)="loginForDelete()"
                              class="bg-default_grey font-semibold text-white px-8 py-4">ACCEDI</button>
                      </div>
                  </form>
                  <p>oppure</p>
                  <div class="max-w-md mx-auto my-4">
                      <app-google-button (click)="loginWithGoogleForDelete()"></app-google-button>
                  </div>
              </div>
          </div>
      </div>
      <div *ngIf="currentState == states.USER_CARD">
          <div class="flex justify-center">
              <div class="max-w-md w-full text-center">
                  <div class="flex flex-wrap gap-6 justify-center">
                      <div class="max-w-md w-full flex items-center">
                          <app-left-arrow (click)="back()"></app-left-arrow>
                          <p>indietro</p>
                      </div>
                  </div>
                  <img src="assets/images/black_logo.png"/>
                  <div class="flex w-full justify-center">
                      <qrcode [qrdata]="'user_'+user.uid"></qrcode>
                  </div>
                  
                  <p class="font-normal">{{user.uid}}</p>
                  <p class="font-normal">{{user.name}}</p>
                  
              </div>
          </div>
      </div>
    </div>
</div>
<app-snackbar></app-snackbar>