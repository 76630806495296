import { Injectable } from '@angular/core';
import { FirebaseApp } from '@angular/fire/app';
import { environment } from '../../../environments/environment';
import { CollectionReference, collection, getDocs, getFirestore, orderBy, query } from 'firebase/firestore';
import { Product } from '../../models/product';
import { ProductCategory } from '../../models/productCategory';
import { Firestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  private products: Product[] = [];
  private productCategories: ProductCategory[] = [];
  private db: Firestore;
  private productCollection: CollectionReference;
  private categoryCollection: CollectionReference;
  

  constructor(private app: FirebaseApp) {
    this.db = getFirestore(this.app);
    this.productCollection = collection(this.db, 'products');
    this.categoryCollection = collection(this.db, 'productsCategory');
  }

  public async getProducts(forceUpdate: boolean = false): Promise<Product[]> {
    if(forceUpdate || this.products.length == 0){
      this.products = [];
      const q = query(this.productCollection, orderBy('position', 'asc'));
      const docs = await getDocs(q);
      docs.forEach((doc) => {
        let product = doc.data() as Product;
        this.products.push(product);
      });
    }
    return this.products;
  }

  public async getProductCategories(forceUpdate: boolean = false): Promise<ProductCategory[]> {
    if(forceUpdate || this.productCategories.length == 0){
      this.productCategories = [];
      const q = query(this.categoryCollection, orderBy('position', 'asc'));
      const docs = await getDocs(q);
      docs.forEach((doc) => {
        let category = doc.data() as ProductCategory;
        this.productCategories.push(category);
      });
    }
    return this.productCategories;
  }
}
