<app-user-reservation-info [reservation]="selectedReservation" [addingToCart]="addingToCart"
  (payReservation)="payReservation()" (closeReservation)="selectedReservation = null"></app-user-reservation-info>

<app-user-cart-info [cart]="selectedCart" (closeCart)="selectedCart = null" (deletePayedCart)="deletePayedCart()"
  (deleteProductFromCart)="deleteProductFromCart($event)"></app-user-cart-info>


<div class="flex justify-center" style="background-image: url('assets/images/grunge_bg.png');">
  <h1 class="text-3xl md:text-5xl lg:text-6xl font-bold my-8 font-serif text-white">PRENOTAZIONI</h1>
</div>

<div class="container mx-auto my-6 px-6 min-h-screen">
  <div class="flex justify-start">
    <app-settings (click)="showSettings()"></app-settings>
  </div>
  <div *ngIf="currentState == states.LOADING" class="h-screen flex justify-center items-center">
    <ng-lottie [options]="loader" class="lg:h-[300px]"></ng-lottie>
  </div>
  <div *ngIf="currentState == states.DEFAULT">
    <div class="mt-6 text-center mx-6">
      <div class="flex flex-wrap gap-6 justify-center">
        <app-reservation-card (showCartEmitter)="showCart(ur)" (deleteEmitter)="deleteReservation(ur)"
          (showReservationEmitter)="showReservation(ur)" *ngFor="let ur of filteredUserReservations"
          [userReservation]="ur"></app-reservation-card>
      </div>
    </div>
  </div>
  <div *ngIf="currentState == states.EMPTY">
    <div class="h-screen flex justify-center p-12">
      <div class="flex flex-col gap-y-12">
        <h2 class="text-xl md:text-2xl lg:text-3xl font-bold text-center">Non hai prenotazioni</h2>
        <ng-lottie [options]="empty" class="lg:h-[300px]"></ng-lottie>
      </div>
    </div>
  </div>
  <div *ngIf="currentState == states.SETTINGS">
    <div class="mt-6 flex flex-col items-center justify-normal min-h-screen">
      <form [formGroup]="filterForm" class="w-full max-w-lg bg-white rounded-lg shadow-md p-6">
        <div class="flex flex-wrap -mx-3 mb-6">
          <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="start-date">
              Data di inizio
            </label>
            <input formControlName="startDate"
              class="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
              id="start-date" type="date">
          </div>
          <div class="w-full md:w-1/2 px-3">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="end-date">
              Data di fine
            </label>
            <input formControlName="endDate"
              class="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
              id="end-date" type="date">
          </div>
        </div>
        <div class="flex items-center justify-between">
          <button (click)="resetFilter()"
            class="bg-red-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit">
            Reset
          </button>
          <button (click)="setFilter()"
            class="bg-default_grey hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit">
            Filtra
          </button>
        </div>
      </form>
    </div>

  </div>
</div>
<app-snackbar></app-snackbar>