export class Operator{
    name = "Qualsiasi";
    id = 'any';
    description: string = "";
    facebook: string = "";
    img: string = "assets/images/operator.png";
    instagram: string = "";
    isAvailable: boolean = false;
    servicesDuration = new Map<string, number>();
    storeId: string = "";
    surname: string = "";
  }