import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Product } from '../../../models/product';
import { CartProduct } from '../../../models/cart-product';
import { Reservation } from '../../../models/reservation';
import { CartService } from '../../../services/cart/cart.service';
import { CartReservation } from '../../../models/cart-reservation';


@Component({
  selector: 'app-cart-item',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './cart-item.component.html',
  styleUrl: './cart-item.component.css'
})
export class CartItemComponent {
  @Output() addEmitter = new EventEmitter<void>();
  @Output() rmvEmitter = new EventEmitter<void>();
  @Input() cartReservation!: CartReservation;
  @Input() cartProduct!: CartProduct;
 

  protected addQta(){
    this.addEmitter.emit();
  }

  protected rmvQta(){
    this.rmvEmitter.emit();
  }
}
