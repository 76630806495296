import { Component, ViewChild } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ServicesService } from '../../../services/services/services.service';
import { Service } from '../../../models/service';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SnackbarComponent } from '../../utilities/snackbar/snackbar.component';
import { Store } from '../../../models/store';
import { StoreService } from '../../../services/store/store.service';
import { User } from '../../../models/user';
import { AuthService } from '../../../services/auth/auth.service';
import { Operator } from '../../../models/operator';
import { OperatorService } from '../../../services/operator/operator.service';
import { OperatorCardComponent } from '../../utilities/operator-card/operator-card.component';
import { ReservationSlot } from '../../../models/reservation-slot';
import { StringsService } from '../../../services/strings/strings.service';
import { ActivatedRoute, Route, Router, RouterModule } from '@angular/router';
import { Offer } from '../../../models/offer';
import { OfferService } from '../../../services/offer/offer.service';
import { Amount } from '../../../models/amount';
import { CartService } from '../../../services/cart/cart.service';
import { ServiceCardComponent } from '../../utilities/service-card/service-card.component';
import { CrossComponent } from "../../miscellaneous/cross/cross.component";
import { AnimationOptions, LottieModule } from 'ngx-lottie';
import { Response } from '../../../models/response';
import { ReservationService } from '../../../services/reservation/reservation.service';
import { Reservation } from '../../../models/reservation';
import { LeftArrowComponent } from '../../miscellaneous/left-arrow/left-arrow.component';
import { CreditCardComponent } from '../../miscellaneous/credit-card/credit-card.component';
import { InfoCardComponent } from '../../utilities/info-card/info-card.component';
import { getAuth } from 'firebase/auth';
import { ServiceCategory } from '../../../models/serviceCategory';

@Component({
  selector: 'app-book',
  standalone: true,
  templateUrl: './book.component.html',
  styleUrl: './book.component.css',
  imports: [InfoCardComponent, CrossComponent, CreditCardComponent, LeftArrowComponent, CommonModule, LottieModule, ServiceCardComponent, FormsModule, ReactiveFormsModule, SnackbarComponent, OperatorCardComponent, CrossComponent]
})
export class BookComponent {
  @ViewChild(InfoCardComponent) infoCard!: InfoCardComponent;
  @ViewChild(SnackbarComponent) snackbar!: SnackbarComponent;
  protected services: Service[] = [];
  protected selectedServices: Service[] = [];
  protected selectedInfo: Service | null = null;
  protected selectedService!: Service;
  protected user: User | null = null;
  protected states = {
    LOADING: 0,
    SELECT_SERVICE: 1,
    FOR_OTHER: 2,
    SELECT_STORE: 3,
    SELECT_DATE: 4,
    SELECT_OPERATOR: 5,
    SELECT_SLOT: 6,
    CONFIRMATION: 7,
    ERROR: 8,
    SUCCESS: 9
  }
  protected currentState: number = 0;
  protected stores: Store[] = [];
  protected validDates: Date[] = [];
  protected operators: Operator[] = [];
  protected availableSlots: ReservationSlot[] = [];
  private datePipe: DatePipe = new DatePipe('en-US');
  protected selectedSlot!: ReservationSlot;
  protected selectedSlotHour!: string;
  protected selectedStore!: Store;
  protected selectedOperator!: Operator;
  protected selectedFriend: string = "";
  protected selectedDate!: Date;
  protected form = new FormGroup({
    friend: new FormControl(""),
    store: new FormControl(null),
    date: new FormControl(null),
  });
  protected selectedOffer: Offer | null = null;
  protected days: string[] = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"]
  protected amount!: Amount;
  protected loader: AnimationOptions = {
    path: 'assets/lottie/loader.json'
  };
  protected success: AnimationOptions = {
    path: 'assets/lottie/success.json'
  };
  protected errorAnimation: AnimationOptions = {
    path: 'assets/lottie/error.json'
  };
  protected error: string = "";
  protected trace: string = "";
  private success_audio = new Audio();
  protected showSuccessAnimation: boolean = true;
  protected bookedReservation: Reservation | null = null;
  protected needToPay: boolean = false;
  protected paymentTitle: string = "";
  protected paymentMessage: string = "";
  protected isLoggedIn: boolean = false;
  protected serviceCategory: ServiceCategory[] = [];
  protected forOther: boolean = false;
  protected changingState: boolean = true;
  private oldStates: number[] = [];

  constructor(private route: ActivatedRoute, private router: Router, protected cartService: CartService, private offerService: OfferService, private stringsService: StringsService, private authService: AuthService, private reservationService: ReservationService, private servicesService: ServicesService, private storeService: StoreService, private operatorService: OperatorService) {
    this.success_audio.src = "assets/audio/retroscena.mp3";
    this.changingState = true;
    this.loadData();
  }

  protected async loadData() {
    this.paymentTitle = await this.stringsService.getString(new Response("reservationPaymentRequiredTitle"));
    this.paymentMessage = await this.stringsService.getString(new Response("reservationPaymentRequiredMessage"));
    this.validDates = this.getValiDates();
    this.serviceCategoriesStatusObserver();
  }

  private serviceCategoriesStatusObserver() {
    this.servicesService.serviceCategoriesObserver.subscribe({
      next: (serviceCategories) => {
        this.serviceCategory = serviceCategories;
        this.servicesStatusObserver();
      }
    });
  }


  private servicesStatusObserver() {
    this.servicesService.servicesObserver.subscribe({
      next: (services) => {
        this.services = services;
        this.selectedServices = this.services;
        this.serviceCategoryObserver();
        this.storesStatusObserver();
      }
    });
  }

  private serviceCategoryObserver() {
    this.servicesService.serviceCategoryObserver.subscribe({
      next: (serviceCategory) => {
        if (serviceCategory) {
          this.filterServices(serviceCategory.id);
        }
      }
    });
  }

  private storesStatusObserver() {
    this.storeService.storesObserver.subscribe({
      next: (stores) => {
        this.stores = stores;
        this.operatorsStatusObserver();
      }
    });
  }

  private operatorsStatusObserver() {
    this.operatorService.operatorObserver.subscribe({
      next: (operators) => {
        this.operators = operators;
        this.userStatusObserver();
        this.changeState(this.states.SELECT_SERVICE)
      }
    });
  }

  private userStatusObserver() {
    this.authService.userObserver.subscribe({
      next: (user) => {
        this.user = user;
        this.isLoggedIn = user != null;
        if(user){
          this.loadUserData();  
          this.checkOffer();
        }
      } 
    })
  }

  private checkOffer() {
    this.selectedOffer = this.offerService.getOffer();
    if (this.user && this.selectedOffer) {
      if (this.user.verified) {
        if (this.user.birthDay && this.user.favouriteStoreId) {
          this.getValiDatesFromOffer();
          this.selectedService = this.services.filter(s => s.id == this.selectedOffer?.service)[0];
          this.changeState(this.states.SELECT_STORE);
        } else {
          this.snackbar.showMessage("Devi completare il tuo profilo per poter prenotare");
          this.changeState(this.states.SELECT_SERVICE);
        }
      } else {
        this.snackbar.showMessage("Devi verificare il tuo account per poter prenotare");
        this.changeState(this.states.SELECT_SERVICE);
      }

    } else {
      this.changeState(this.states.SELECT_SERVICE);
    }
  }


  protected async loadUserData() {
    if(this.user){
      let store = this.user.favouriteStoreId;
      this.selectedStore = this.stores.filter(s => s.id == store)[0];
    }
    
  }

  protected resetServices() {
    this.selectedServices = this.services
  }

  protected filterServices(sc_id: string) {
    this.selectedServices = this.services.filter(s => s.category == sc_id);
  }

  protected changeState(state: number, update: boolean = true) {
    window.scrollTo(0, 0);
    if (update) {
      this.oldStates.push(this.currentState);
    }
    this.changingState = false;
    this.currentState = state;
  }

  protected back() {
    if (this.oldStates.length > 0) {
      let oldState = this.oldStates.pop() as number;
      this.changeState(oldState, false);
    }
  }

  private getValiDatesFromOffer(): Date[] {
    if (this.selectedOffer) {
      let ris: Date[] = [];
      let d = new Date();
      for (let i = 0; i < 3; i++) {
        if (this.selectedOffer.validDays[this.days[d.getDay()] as any]) {
          ris.push(new Date(d));
        }
        d.setDate(d.getDate() + 1);
      }
      return ris;
    }
    throw new Error("Offer not found");
  }

  private getValiDates(): Date[] {
    let date = new Date();
    let ris: Date[] = [];
    for (let i = 0; i < 3; i++) {
      ris.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    return ris;
  }

  protected showDescription(service: Service) {
    this.infoCard.addInfos("Descrizione servizio", service.description);
    this.infoCard.open();
  }

  protected setService(s: Service, forOther: boolean) {
    if (this.user) {
      if (this.user.verified) {
        if (this.user.birthDay && this.user.favouriteStoreId) {
          this.changingState = true;
          this.selectedService = s;
          this.forOther = forOther;
          if (forOther) {
            this.changeState(this.states.FOR_OTHER);
          } else {
            this.changeState(this.states.SELECT_STORE);
          }
        } else {
          this.snackbar.showMessage("Devi completare il tuo profilo per poter prenotare");
        }
      } else {
        this.snackbar.showMessage("Devi verificare il tuo account per poter prenotare");
      }
    } else {
      this.snackbar.showMessage("Devi essere loggato per poter prenotare");
    }
  }


  protected setFriend() {
    if (this.form.value.friend) {
      this.changingState = true;
      this.selectedFriend = this.form.value.friend;
      this.changeState(this.states.SELECT_STORE);
    } else {
      this.snackbar.showMessage('Devi selezionare un amico');
    }
  }

  protected async setStore() {
    if (this.form.value.store) {
      if ((this.form.value.store as Store).news) {
        this.changingState = true;
        this.selectedStore.news.forEach(news => {
          if (news.available) {
            this.infoCard.addInfos(news.title, news.msg, news.cta);
          }
        });
        if (this.selectedStore.news.length > 0) {
          this.infoCard.open();
        }
      }
      this.operators = this.operators.filter(o => o.storeId == this.selectedStore.id);
      this.operators = [new Operator()].concat(this.operators);
      this.changeState(this.states.SELECT_DATE)
    } else {
      this.snackbar.showMessage('Devi selezionare un negozio');
    }
  }

  protected async setDate() {
    if (this.form.value.date) {
      this.changingState = true;
      this.selectedDate = this.form.value.date as Date;
      let formattedDate = this.datePipe.transform(this.form.value.date, 'yyyy-MM-dd') as string;
      let offerId = (this.selectedOffer) ? this.selectedOffer.id : "";
      this.cartService.getAmount(this.selectedStore.id, formattedDate, this.selectedService.id, offerId).subscribe({
        next: async (amount) => {
          this.amount = amount;
          if (this.amount.addOns.length != 0) {
            this.infoCard.addInfos("ATTENZIONE", (await this.stringsService.getString(new Response("addOnAddedMessage"))).replace("%n%", this.amount.addOns[0].priceLabel));
            this.infoCard.open();
          }
          this.changeState(this.states.SELECT_OPERATOR);
        },
        error: async (error) => {
          this.error = await this.stringsService.getString(error);
          this.trace = error.status.trace;
          this.snackbar.showMessage(this.error);;
        }
      });
    } else {
      this.snackbar.showMessage('Devi selezionare una data');
    }
  }

  protected selectOperator(o: Operator) {
    this.selectedOperator = o;
  }

  protected async setOperator() {
    if (this.selectedOperator) {
      this.changingState = true;
      let formattedDate = this.datePipe.transform(this.selectedDate, 'dd-MM-yyyy') as string;
      let offerId = (this.selectedOffer) ? this.selectedOffer.id : "";
      let operatorId = (this.selectedOperator.id != "any") ? this.selectedOperator.id : "";
      this.reservationService.getAvailableReservationSlots(this.selectedService.id, formattedDate, this.selectedStore.id, operatorId, offerId).subscribe({
        next: (result) => {
          this.availableSlots = result;
          if (this.availableSlots.length == 0) {
            this.changingState = false;
            this.snackbar.showMessage('Non ci sono slot disponibili');
          } else {
            this.changingState = false;
            this.changeState(this.states.SELECT_SLOT);
          }
        },
        error: async (error) => {
          this.changingState = false;
          this.snackbar.showMessage(await this.stringsService.getString(error));
        }
      });
    } else {
      this.snackbar.showMessage('Devi selezionare un operatore');
    }
  }

  protected selectSlot(s: ReservationSlot) {
    this.selectedSlotHour = this.datePipe.transform(s.startDate, 'HH:mm') as string;
    this.selectedSlot = s;
  }

  protected async setSlot() {
    if (this.selectedSlotHour) {
      this.changingState = true;
      this.changeState(this.states.CONFIRMATION)
    } else {
      this.snackbar.showMessage('Devi selezionare un orario');
    }
  }

  protected async book() {
    this.changingState = true;
    this.reservationService.book(this.selectedStore.id, this.selectedService.id, this.selectedSlot, this.selectedFriend).subscribe({
      next: (reservation) => {
        this.changeState(this.states.SUCCESS);
        this.success_audio.play();
        this.showSuccessAnimation = true;
        setTimeout(() => {
          this.showSuccessAnimation = false;
        }, 3000)
        this.bookedReservation = reservation;
        if (reservation.addOns.length > 0) {
          this.needToPay = true;
          this.cartService.addReservationToCart(reservation).subscribe({
            error: async (error) => {
              this.error = await this.stringsService.getString(error);
              this.trace = error.status.trace;
              this.changeState(this.states.ERROR);
            }
          });
        }
      },
      error: async (error) => {
        this.error = await this.stringsService.getString(error);
        this.trace = error.status.trace;
        this.changeState(this.states.ERROR);
      }
    });
  }

  protected exit() {
    this.currentState = this.states.SELECT_SERVICE;
  }

  protected addProduct() {

    if (this.bookedReservation?.addOns.length == 0) {
      this.cartService.addReservationToCart(this.bookedReservation).subscribe({
        error: async (error) => {
          this.error = await this.stringsService.getString(error);
          this.trace = error.status.trace;
          this.changeState(this.states.ERROR);
        }
      });;
    }
    this.router.navigate(["/products"]);
  }

  protected bookAgain() {
    if (this.bookedReservation?.addOns.length == 0) {
      this.cartService.addReservationToCart(this.bookedReservation).subscribe({
        error: async (error) => {
          this.error = await this.stringsService.getString(error);
          this.trace = error.status.trace;
          this.changeState(this.states.ERROR);
        }
      });
    }
  }
}
