import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from '../../../services/auth/auth.service';
import { User } from '../../../models/user';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Store } from '../../../models/store';
import { StoreService } from '../../../services/store/store.service';
import { GoogleButtonComponent } from '../../utilities/google-button/google-button.component';
import { AnimationOptions, LottieModule } from 'ngx-lottie';
import { SnackbarComponent } from '../../utilities/snackbar/snackbar.component';
import { LeftArrowComponent } from '../../miscellaneous/left-arrow/left-arrow.component';
import { QRCodeModule } from 'angularx-qrcode';
import { Auth, getAuth } from '@angular/fire/auth';
import { sendEmailVerification } from 'firebase/auth';


@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [QRCodeModule ,CommonModule, FormsModule, ReactiveFormsModule, GoogleButtonComponent, LottieModule, SnackbarComponent, LeftArrowComponent],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.css'
})
export class ProfileComponent {
  @ViewChild(SnackbarComponent) snackbar!: SnackbarComponent;
  protected user: User | null = null;
  protected verified: boolean = false;
  protected stores: Store[] = [];
  protected currentState: number = 0;
  protected isLoading: boolean = false;
  protected states = {
    LOADING: 0,
    EDIT: 1,
    DELETE: 2,
    USER_CARD: 3
  }

  protected editForm: FormGroup = new FormGroup({
    name: new FormControl(this.user ? this.user.name : '', Validators.required),
    phoneNumber: new FormControl(this.user ? this.user.phoneNumber : '', Validators.required),
    birthDay: new FormControl(this.user ? this.user.birthDay : new Date(), Validators.required),
    favouriteStoreId: new FormControl(this.user ? this.user.favouriteStoreId : '', Validators.required)
  });

  protected deleteForm: FormGroup = new FormGroup({
    email: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required)
  });
  public loader: AnimationOptions = {
    path: 'assets/lottie/loader.json'
  };


  constructor(private authService: AuthService, private storeService: StoreService) {
    this.userStatusObserver();
    this.loadData();
  }

  private userStatusObserver() {
    this.authService.userObserver.subscribe({
      next: (user) => {
        this.user = user;
        this.isLoading = false;
        if(this.user){
          this.verified = this.user.verified;
          let formattedBirthDay = this.user.birthDay?.toISOString().split('T')[0];
          this.editForm.patchValue({
            name: this.user.name,
            phoneNumber: this.user.phoneNumber,
            birthDay: formattedBirthDay,
            favouriteStoreId: this.user.favouriteStoreId
          })
        }
      }
    });
  }

  private async loadData() {
    this.storeService.storesObserver.subscribe((stores) => {
      this.stores = stores;
    });
    this.currentState = this.states.EDIT;
  }


  protected sendEmailVerification(){
    getAuth().onAuthStateChanged((u) => {
      if(u){
        sendEmailVerification(u).then(() => {
          this.snackbar.showMessage("Email inviata");
        });
      }else{
        this.snackbar.showMessage("Errore");
      }
    });
  }

  protected async updateUserData() {
    this.isLoading = true;
    if(this.user){
      this.user.name = this.editForm.value.name;
      this.user.phoneNumber = this.editForm.value.phoneNumber;
      this.user.birthDay = new Date(this.editForm.value.birthDay);
      this.user.favouriteStoreId = this.editForm.value.favouriteStoreId;
      console.log(this.user)
      this.authService.updateUserData(this.user);
    }
  }

  protected logout() {
    this.authService.logout();
  }

  protected loginForDelete(){
      if(this.user && this.deleteForm.valid){
      let email = this.deleteForm.value.email;
      let password = this.deleteForm.value.password;
      this.authService.login(email, password).then(async (userCredential) => {
        if(this.user && userCredential.user.email == this.user.email){
          this.confirmDelete();
        }
      }).catch((e)=>{
        this.snackbar.showMessage("Email o password errati");
      });
    }
  }

  protected loginWithGoogleForDelete(){
    this.authService.loginWithGoogle().then((userCredential) => {
      if(this.user && userCredential.user.email == this.user.email){
        this.confirmDelete();
      }
    }).catch((e)=>{
      this.snackbar.showMessage("Account errato");
      this.authService.logout();
    });
  }

  protected deleteAccount() {
    this.currentState = this.states.DELETE;
  }

  protected confirmDelete(){
    if(this.user){
      let id = this.user.uid as string;
      this.authService.deleteAccount(id);
    }
  }

  protected back(){
    this.currentState = this.states.EDIT;
  }

  protected showUserCard(){
    this.currentState = this.states.USER_CARD;
  }

}
