<app-info-card ></app-info-card>

<div [id]="'head'" class="flex justify-center" style="background-image: url('assets/images/grunge_bg.png');">
    <h1 class="text-3xl md:text-5xl lg:text-6xl font-bold my-8 font-serif text-white">PRENOTA</h1>
</div>
<div class="container mx-auto px-6 min-h-screen" >

    <div *ngIf="currentState > states.SELECT_SERVICE && currentState <= states.CONFIRMATION" >
        <div class="flex flex-wrap mt-6 gap-6 justify-center">
            <button class="max-w-md w-full flex items-center" (click)="back()">
                <app-left-arrow ></app-left-arrow>
                <p>indietro</p>
            </button>
        </div>
    </div>
    <div *ngIf="currentState == states.SELECT_SERVICE">
        <div class="flex gap-x-2 justify-center my-2">
            <div class="grid gap-y-2 lg:flex gap-x-2 overflow-scroll no-scroll hide-scrollbar">
                <div *ngFor="let sc of serviceCategory">
                    <button (click)="filterServices(sc.id)" 
                        class="font-semibold rounded-lg bg-default_grey text-white text-center h-[50px] w-[310px]" >{{sc.name}}
                    </button>
                </div>
            </div>
        </div>
        <div class="flex flex-wrap mb-6 gap-6 justify-center">
            <app-service-card *ngFor="let s of selectedServices" [service]="s" (descriptionEmitter)="showDescription(s)"
                (serviceEmitter)="setService(s, $event)"></app-service-card>
        </div>
    </div>
    <div *ngIf="currentState == states.FOR_OTHER">
        <div class="flex flex-wrap gap-6 justify-center">
            <div class="max-w-md w-full">
                <form [formGroup]="form" (ngSubmit)="setFriend()">

                    <h2 class="text-xl md:text-2xl lg:text-3xl font-semibold my-8">Insceriscil il nome e il cognome
                        della
                        persona per cui stai prenotando</h2>
                    <div class=" mx-auto my-8">
                        <label class="block text-gray-700 text-left font-bold mb-2">Nome e cognome</label>
                        <div class="relative rounded-md shadow-sm">
                            <input type="text"
                                class="form-input py-2 px-4 block w-full leading-5 rounded-md transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                placeholder="Esempio: Luca Rossi" formControlName="friend" />
                        </div>
                        <button type="submit"
                            class="w-full rounded-lg bg-default_grey font-semibold text-white my-4 py-4">AVANTI</button>
                    </div>

                </form>
            </div>
        </div>
    </div>
    <div *ngIf="currentState == states.SELECT_STORE">
        <div class="flex flex-wrap gap-6 justify-center">
            <div class="max-w-md w-full">
                <h2 class="text-xl md:text-2xl lg:text-3xl font-semibold my-8">Seleziona il negozio in cui vuoi
                    prenotare
                </h2>
                <form [formGroup]="form" (ngSubmit)="setStore()">
                    <select formControlName="store" [(ngModel)]="selectedStore"
                        class="form-select py-2 px-4 block w-full leading-5 rounded-md transition duration-150 ease-in-out sm:text-sm sm:leading-5">
                        <option *ngFor="let s of stores" [ngValue]="s" >
                            {{s.city}}
                        </option>
                    </select>
                    <button type="submit"
                        class="w-full rounded-lg bg-default_grey font-semibold text-white my-4 py-4">AVANTI</button>
                </form>
            </div>
        </div>
    </div>
    <div *ngIf="currentState == states.SELECT_DATE">
        <div class="flex flex-wrap gap-6 justify-center">
            <div class="max-w-md w-full">
                <h2 class="text-xl md:text-2xl lg:text-3xl font-semibold my-8">Seleziona la data
                </h2>
                <form [formGroup]="form" (ngSubmit)="setDate()">
                    <select formControlName="date" [(ngModel)]="validDates[0]"
                        class="form-select py-2 px-4 block w-full leading-5 rounded-md transition duration-150 ease-in-out sm:text-sm sm:leading-5">
                        <option value="" hidden disabled selected>Seleziona un'opzione</option>
                        <option *ngFor="let d of validDates" [ngValue]="d" >
                            {{d | date: 'dd/MM/yyyy'}}
                        </option>
                    </select>
                    <button type="submit"
                        class="w-full rounded-lg bg-default_grey font-semibold text-white my-4 py-4" [ngClass]="(changingState) ? 'opacity-60': ''" [disabled]="changingState">{{(changingState) ? ("carico" | uppercase) : "avanti" | uppercase}}</button>
                </form>
            </div>
        </div>
    </div>
    <div *ngIf="currentState == states.SELECT_OPERATOR">
        <div class="flex flex-wrap gap-6 justify-center">
            <div class="max-w-[460px] w-full">
                <h2 class="text-xl md:text-2xl lg:text-3xl font-semibold my-8">Seleziona l'operatore</h2>
                <div class="flex gap-6 justify-start max-h-full py-6 overflow-scroll">
                    <app-operator-card *ngFor="let o of operators" [operator]="o"
                        [ngClass]="(o == selectedOperator) ? 'border-4 border-retro_gray rounded-3xl': ''"
                        (click)="selectOperator(o)"></app-operator-card>
                </div>
                <button (click)="setOperator()"
                    class="max-w-md w-full rounded-lg bg-default_grey font-semibold text-white my-4  py-4" [ngClass]="(changingState) ? 'opacity-60': ''" [disabled]="changingState">{{(changingState) ? ("carico" | uppercase) : "avanti" | uppercase}}</button>
            </div>
        </div>

    </div>
    <div *ngIf="currentState == states.SELECT_SLOT">
        <div class="flex flex-wrap gap-6 justify-center">
            <div class="max-w-md w-full  text-center">
                <h2 class="text-xl md:text-2xl lg:text-3xl font-semibold my-8">Seleziona l'orario</h2>
                <div class="flex flex-wrap gap-6 py-6 justify-center overflow-y-scroll ">
                    <button *ngFor="let slot of availableSlots" (click)="selectSlot(slot)"
                        [ngClass]="(slot == selectedSlot) ? 'border-2 border-retro_gray rounded-xl': ''"
                        class="rounded-xl shadow-lg px-12 py-4 font-bold">
                        {{slot.startDate | date: 'HH:mm'}}
                    </button>
                </div>
                <button (click)="setSlot()"
                    class="w-full rounded-lg bg-default_grey font-semibold text-white my-4 py-4" [ngClass]="(changingState) ? 'opacity-60': ''" [disabled]="changingState">{{(changingState) ? ("carico" | uppercase) : "avanti" | uppercase}}</button>
            </div>
        </div>
    </div>
    <div *ngIf="currentState == states.CONFIRMATION">
        <div class="flex flex-wrap gap-6 justify-center">
            <div class="max-w-md w-full text-center">
                <h2 class="text-xl md:text-2xl lg:text-3xl font-semibold my-8">Conferma la prenotazione</h2>
                <div class="flex flex-col">
                    <img [src]="selectedOperator.img"
                        style="object-fit: cover; object-position: center; display: block;"
                        class="rounded-t-3xl w-[250px] self-center">
                    <!--Informazioni sulla prenotazione-->
                    <h1 class="text-l md:text-xl lg:text-2xl font-semibold mt-2">{{selectedStore.name}} </h1>
                    <p>{{selectedStore.address}}</p>
                    <h1 class="text-l md:text-xl lg:text-2xl font-semibold mt-2">{{selectedService.name}}</h1>
                    <p>Costo del servizio</p>
                    <h1 class="text-l md:text-xl lg:text-2xl font-semibold">{{amount.amount}}€</h1>
                    <p>La tua prenotazione inzia alle</p>
                    <h1 class="text-l md:text-xl lg:text-2xl font-semibold">{{selectedSlot.startDate | date:
                        'HH:mm'}}</h1>
                    <h1 class="text-l md:text-xl lg:text-2xl font-semibold">{{selectedDate | date: 'dd MMMM yyyy'}}
                    </h1>
                    <button (click)="book()"
                        class="w-full rounded-lg bg-default_grey font-semibold text-white my-4 py-4" [ngClass]="(changingState) ? 'opacity-60': ''" [disabled]="changingState">{{(changingState) ? ("carico" | uppercase) : "conferma" | uppercase}}</button>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="currentState == states.LOADING">
        <div class="h-screen flex justify-center items-center">
            <ng-lottie [options]="loader" class="lg:h-[300px]"></ng-lottie>
        </div>
    </div>
    <div *ngIf="currentState == states.ERROR">
        <div class="h-screen flex justify-center p-12">
            <div class="text-center w-full">
                <div class="flex flex-col gap-y-12">
                    <h2 class="text-xl md:text-2xl lg:text-3xl font-bold text-center mb-6">{{error}}</h2>
                    <p class="text-l md:text-xl lg:text-2xl font-semibold text-center">trace: {{trace}}</p>
                    <ng-lottie [options]="errorAnimation" class="lg:h-[300px]"></ng-lottie>
                </div>
                <button (click)="loadData()"
                    class="max-w-md w-full rounded-lg bg-default_grey font-semibold text-white py-2 mt-6">VA
                    BENE</button>
            </div>
        </div>
    </div>
    <div *ngIf="currentState == states.SUCCESS">
        <div class="min-h-screen flex justify-center">
            <div *ngIf="showSuccessAnimation">
                <div class="flex flex-col gap-y-12">
                    <h2 class="text-xl md:text-2xl lg:text-3xl font-bold text-center mb-6">Prenotazione effettuata</h2>
                    <ng-lottie [options]="success" class="lg:h-[300px]"></ng-lottie>
                </div>
            </div>
            <div *ngIf="!showSuccessAnimation">
                <div class="flex flex-wrap justify-center">
                    <div class="max-w-lg w-full text-center">
                        <div class="flex w-full justify-end">
                            <app-cross (click)="exit()"></app-cross>
                        </div>
                        <div *ngIf="needToPay">
                            <h2 class="text-xl md:text-2xl lg:text-3xl font-semibold my-8">{{paymentTitle}}</h2>
                            <p>{{paymentMessage}}</p>
                            <div class="flex w-full justify-center">
                                <app-credit-card class="my-6"></app-credit-card>
                            </div>
                            <div class="flex gap-x-3 w-full">
                                <button (click)="bookAgain()"
                                    class="w-full rounded-lg bg-default_grey font-semibold text-white py-2">PRENOTA
                                    ANCORA</button>
                                <button (click)="addProduct()"
                                    class="w-full rounded-lg bg-default_grey font-semibold text-white py-2">AGGIUNGI
                                    PRODOTTO</button>
                            </div>
                        </div>
                        <div *ngIf="!needToPay" class="h-full">

                            <h2 class="text-xl md:text-2xl lg:text-3xl font-semibold my-8">Cosa vuoi fare?</h2>
                            <div class="flex gap-x-3 w-full">
                                <button (click)="bookAgain()"
                                    class="w-full rounded-lg bg-default_grey font-semibold text-white py-2">PRENOTA
                                    ANCORA</button>
                                <button (click)="addProduct()"
                                    class="w-full rounded-lg bg-default_grey font-semibold text-white py-2">AGGIUNGI
                                    PRODOTTO</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="currentState == states.LOADING">
        <div class="h-screen flex justify-center items-center">
            <ng-lottie [options]="loader" class="lg:h-[300px]"></ng-lottie>
        </div>
    </div>
</div>
<app-snackbar></app-snackbar>