import { Component, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnimationOptions, LottieModule } from 'ngx-lottie';
import { ReservationService } from '../../../services/reservation/reservation.service';
import { Reservation } from '../../../models/reservation';
import { QRCodeModule } from 'angularx-qrcode';
import { CrossComponent } from '../../miscellaneous/cross/cross.component';
import { ReservationCardComponent } from '../../utilities/reservation-card/reservation-card.component';
import { StringsService } from '../../../services/strings/strings.service';
import { SnackbarComponent } from '../../utilities/snackbar/snackbar.component';
import { CartService } from '../../../services/cart/cart.service';
import { UserReservation } from '../../../models/user-reservations';
import { Cart } from '../../../models/cart';

import { SettingsComponent } from '../../miscellaneous/settings/settings.component';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { UserReservationInfoComponent } from '../../utilities/user-reservation-info/user-reservation-info.component';
import { UserCartInfoComponent } from '../../utilities/user-cart-info/user-cart-info.component';


@Component({
  selector: 'app-user-reservations',
  standalone: true,
  imports: [ UserReservationInfoComponent, UserCartInfoComponent, FormsModule, ReactiveFormsModule, SettingsComponent, CommonModule, LottieModule, QRCodeModule, CrossComponent, ReservationCardComponent, SnackbarComponent],
  templateUrl: './user-reservations.component.html',
  styleUrl: './user-reservations.component.css'
})
export class UserReservationsComponent {
  @ViewChild(SnackbarComponent) snackbar!: SnackbarComponent;
  protected userReservations: UserReservation[] = [];
  protected filteredUserReservations: UserReservation[] = [];
  protected selectedReservation: Reservation | null = null;
  protected selectedCart: Cart | null = null;
  protected currentState: number = 0;
  protected states = {
    LOADING: 0,
    DEFAULT: 1,
    EMPTY: 2,
    SETTINGS: 3
  }
  protected loader: AnimationOptions = {
    path: 'assets/lottie/loader.json'
  };
  protected empty: AnimationOptions = {
    path: 'assets/lottie/emptyOffers.json'
  };
  protected addingToCart: boolean = false;
  protected filterForm = new FormGroup({
    startDate: new FormControl(new Date(), Validators.required),
    endDate: new FormControl(new Date(), Validators.required)
  });

  constructor(private reservationService: ReservationService, private stringsService: StringsService, private cartService: CartService) {
    this.reservationsObserver();
  }

  private reservationsObserver() {
    this.reservationService.reservationsObserver.subscribe({
      next: (res) => {
        this.userReservations = res;
        this.filteredUserReservations = this.userReservations;
        if (this.userReservations.length > 0 ) {
          this.currentState = this.states.DEFAULT;
        } else {
          this.currentState = this.states.EMPTY;
        }
      },
      error: async (error) => {
        this.snackbar.showMessage(await this.stringsService.getString(error));
      }
    });
  }

  protected setFilter() {
    if (this.filterForm.value.startDate) {
      let startDate = new Date(this.filterForm.value.startDate);
      let endDate: Date;
      if (this.filterForm.value.endDate) {
        endDate = new Date(this.filterForm.value.endDate);
        if(endDate < startDate){
          endDate = new Date(startDate);
        }
      }else{
        endDate = new Date(startDate);
      }
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 59, 999);
      this.filteredUserReservations = [];
      this.userReservations.forEach((ur: UserReservation) => {
        if(ur.cart != null){
          if(ur.cart.timestamp >= startDate && ur.cart.timestamp <= endDate){
            this.filteredUserReservations.push(ur);
          }
        }
        if(ur.reservation != null){
          if(ur.reservation.executionStart >= startDate && ur.reservation.executionStart <= endDate){
            this.filteredUserReservations.push(ur);
          }
        }
      });
    }
    this.currentState = this.states.DEFAULT; 
  }

  protected resetFilter() {
    this.filteredUserReservations = this.userReservations;
    this.currentState = this.states.DEFAULT; 
  }

  protected showSettings() {
    if (this.currentState == this.states.SETTINGS) {
      this.currentState = this.states.DEFAULT;
    } else {
      this.currentState = this.states.SETTINGS;
    }
  }

  protected async deleteReservation(ur: UserReservation) {
    this.currentState = this.states.LOADING;
    if(ur.reservation != null){
      this.reservationService.delete(ur.reservation).subscribe({
        next: () => {
          this.snackbar.showMessage("Prenotazione eliminata con successo");
          this.currentState = this.states.DEFAULT;
        },
        error: async (error) => {
          this.snackbar.showMessage(await this.stringsService.getString(error));
          this.currentState = this.states.DEFAULT;
        }
      });
    }
  }

  protected deletePayedCart() {
    if(this.selectedCart){
      this.reservationService.deletePayedCart(this.selectedCart.id).subscribe({
        next: (next) => {
          this.selectedCart = null;
        },
        error: async (error) => {
          this.snackbar.showMessage(await this.stringsService.getString(error));
        }
      });
    }
  }

  protected deleteProductFromCart(productId: string) {
    if(this.selectedCart){
      this.reservationService.removeProductFromPayedCart(this.selectedCart.id, productId).subscribe({
        next: (next) => {
          this.selectedCart = null;
        },
        error: async (error) => {
          this.snackbar.showMessage(await this.stringsService.getString(error));
        }
      });
    }
  }

  protected async showCart(ur: UserReservation) {
    this.selectedCart = ur.cart;
  }

  protected async showReservation(ur: UserReservation) {
    this.selectedReservation = ur.reservation;
  }

  protected payReservation() {
    if(this.selectedReservation){
      this.addingToCart = true;
      this.cartService.addReservationToCart(this.selectedReservation).subscribe({
        next: (next) => {
          this.addingToCart = false;
          this.selectedReservation = null;
          this.snackbar.showMessage("Prenotazione aggiunta al carrello, puoi procedere con il pagamento!");
        },
        error: async (error) => {
          this.addingToCart = false;
          this.selectedReservation = null;
          this.snackbar.showMessage(await this.stringsService.getString(error));
        }
      });
    }
  }

  
}
