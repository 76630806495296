import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CartItemComponent } from '../../utilities/cart-item/cart-item.component';
import { Cart } from '../../../models/cart';
import { CartService } from '../../../services/cart/cart.service';
import { Product } from '../../../models/product';
import { CartProduct } from '../../../models/cart-product';
import { AuthService } from '../../../services/auth/auth.service';
import { getAuth } from 'firebase/auth';
import { StringsService } from '../../../services/strings/strings.service';
import { SnackbarComponent } from '../snackbar/snackbar.component';
import { CrossComponent } from '../../miscellaneous/cross/cross.component';
import { PayCardComponent } from '../pay-card/pay-card.component';
import { AnimationOptions, LottieModule } from 'ngx-lottie';

@Component({
  selector: 'app-cart',
  standalone: true,
  imports: [CommonModule, CartItemComponent, SnackbarComponent, CrossComponent, PayCardComponent, LottieModule],
  templateUrl: './cart.component.html',
  styleUrl: './cart.component.css'
})
export class CartComponent {
  @Output() snackEmitter = new EventEmitter<string>();
  @ViewChild(SnackbarComponent) snackbar!: SnackbarComponent;
  @Output() hideCart = new EventEmitter<boolean>();
  protected cart: Cart = new Cart();
  protected isLogged: boolean = false;
  protected currentState: number = 0;
  protected states = {
    LOADING: 0,
    DEFAULT: 1,
    PAYMENT: 2,
    EMPTY: 3
  }
  protected loader: AnimationOptions = {
    path: 'assets/lottie/loader.json'
  };
  protected empty: AnimationOptions = {
    path: 'assets/lottie/emptyCart.json'
  };
  protected error: string = "";
  protected trace: string = "";
  protected first: boolean = true;


  constructor(private cartService: CartService, private stringsService: StringsService) {
    this.userStatusObserver();
  }

  protected showSnackBar(message: string){
    this.snackEmitter.emit(message);
  }

  private userStatusObserver() {
    getAuth().onAuthStateChanged((user) => {
      if (user) {
        this.isLogged = true;
        this.cartStatusObserver();
      } else {
        this.isLogged = false;
      }
    });
  }

  private async cartStatusObserver() {
    this.cartService.cartObserver.subscribe({
      next: (cart) => {
        this.cart = cart;
        if(this.cart.products.length == 0 && this.cart.reservations.length == 0) {
          this.currentState = this.states.EMPTY;
        }else{
          this.currentState = this.states.DEFAULT;
          if(!this.first){
            this.hideCart.emit(false);
          }
          this.first = false;
        }
      },
      error: async (error) => {
        this.snackEmitter.emit( await this.stringsService.getString(error));
      }
    });
  }

  protected addProductQuantity(p: CartProduct) {
    this.currentState = this.states.LOADING;
    this.cartService.addProductQuantity(p).subscribe({
      error: async (error) => {
        this.snackEmitter.emit(await this.stringsService.getString(error));
      }
    });
  }

  protected removeProductQuantity(p: CartProduct) {
    this.currentState = this.states.LOADING;
    this.cartService.removeProductQuantity(p).subscribe({
      error: async (error) => {
        this.snackEmitter.emit(await this.stringsService.getString(error));
      }
    });
  }

  protected close() {
    this.hideCart.emit(true);
  }

  protected async payment() {
    this.currentState = this.states.LOADING;
    this.cartService.getCartPayment().subscribe({
      next: (payment => {
        this.currentState = this.states.PAYMENT;
      }),
      error: async (error) => {
        this.currentState = this.states.DEFAULT
        this.snackbar.showMessage(await this.stringsService.getString(error));
      }
    });
  }

}
