import { Component, EventEmitter, Output, ViewChild, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxStripeModule, StripeElementsDirective, StripePaymentElementComponent, StripeServiceInterface, injectStripe } from 'ngx-stripe';
import { Cart } from '../../../models/cart';
import { AuthService } from '../../../services/auth/auth.service';
import { Appearance, StripeElementsOptions } from '@stripe/stripe-js';
import { CartService } from '../../../services/cart/cart.service';
import { Router } from '@angular/router';
import { catchError, from, of, switchMap } from 'rxjs';
import { Response } from '../../../models/response';
import { SnackbarComponent } from '../snackbar/snackbar.component';
import { ReservationService } from '../../../services/reservation/reservation.service';
import { getAuth } from 'firebase/auth';
import { User } from '../../../models/user';

@Component({
  selector: 'app-pay-card',
  standalone: true,
  imports: [SnackbarComponent, CommonModule, NgxStripeModule, StripeElementsDirective, StripePaymentElementComponent],
  templateUrl: './pay-card.component.html',
  styleUrl: './pay-card.component.css'
})
export class PayCardComponent {
  @ViewChild(StripePaymentElementComponent)
  protected paymentElement!: StripePaymentElementComponent;
  protected appearance: Appearance = {
    theme: 'stripe',
    labels: 'floating',
    variables: {
      colorPrimary: '#673ab7',
    },
  };
  protected elementsOptions!: StripeElementsOptions;
  protected paying: boolean = false;
  protected cart!: Cart;
  protected stripe!: StripeServiceInterface;
  @Output() closePayment = new EventEmitter<void>();
  @Output() snackEmitter = new EventEmitter<string>();
  private user: User | null = null;

  constructor(private reservationService: ReservationService, private authService: AuthService, private cartService: CartService, private router: Router) {
    this.userStatusObserver();

  }

  private userStatusObserver() {
    this.authService.userObserver.subscribe({
      next: (user) => {
        this.user = user;
        if(user)
          this.cartStatusObserver();
      }
    });
  }

  private cartStatusObserver() {
    this.cartService.cartObserver.subscribe((cart: Cart) => {
      this.cart = cart;
      this.stripe = injectStripe(this.cart.payment.paymentInfo.defaultPublishableKey);
      this.elementsOptions = {
        locale: 'it',
        clientSecret: this.cart.payment.paymentInfo.clientSecret
      }
    });
  }


  protected backToCart() {
    this.closePayment.emit()
  }

  protected async collectPayment() {
    if (this.paying) return;
    if (this.user) {
      this.paying = true;
      this.stripe.confirmPayment({
        elements: this.paymentElement.elements,
        confirmParams: {
          payment_method_data: {
            billing_details: {
              name: this.user.name,
              email: this.user.email,
            },
          },
        },
        redirect: 'if_required',
      }).subscribe({
        next: (result: any) => {
          this.paying = false;
          if (result.error) {
            this.snackEmitter.emit("Si è verificato un'errore durante il pagamento!");
          } else if (result.paymentIntent.status === 'requires_capture') {
            this.snackEmitter.emit("Pagamento riuscito!");
            this.cartService.clearCart();
          }
        },
        error: (err: any) => {
          this.snackEmitter.emit("Si è verificato un'errore durante il pagamento!");
          this.paying = false;
        }
      });
    }
  }
}




