export const environment = {
    homePageUrl: 'https://retroscenabarbieri.it',
    wordPressApiBaseUrl: 'https://retroscenabarbieri.it/b',
    apiBaseUrl: 'https://app.retroscenabarbieri.it/restApi',
    getReservationUrl: '/reservation/secure/v1/getSlotOperators',
    postReservationUrl: '/reservation/secure/v1/reserve',
    getAmountUrl: '/payment/secure/v1/getAmount',
    getUserStatusUrl: '/user/secure/v1/status',
    deleteReservationUrl: '/reservation/secure/v1/reservations',
    offersUrl: '/offers/secure/v1/getOffers',
    createCart: '/payment/secure/v1/createCart',
    getCartPayment: '/payment/secure/v1/createPaymentForCart',
    updateCart: '/reservation/secure/v1/carts',
    getReviews: '/webUtils/v1/getReviews',
    getIGLastImages: '/webUtils/v1/getIGLastImages',
    payCart: '/payment/secure/v1/createPaymentForCart',
     firebase: {
      apiKey: "AIzaSyBIvTmmuPsIPYBbfZJIKDgG1fjqeyxYT_0",
      authDomain: "retroscena-console.firebaseapp.com",
      databaseURL: "https://retroscena-console.firebaseio.com",
      projectId: "retroscena-console",
      storageBucket: "retroscena-console.appspot.com",
      messagingSenderId: "130084823891",
      appId: "1:130084823891:web:35dfc9f5bb43fe4a1277a6",
      measurementId: "G-K5DHEMECCH"
    }
  };