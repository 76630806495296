import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-snackbar',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './snackbar.component.html',
  styleUrl: './snackbar.component.css'
})
export class SnackbarComponent {
  protected message: string = "";
  protected show: boolean = false;


  public showMessage(m: string){
    this.show = true;
    this.message = m;
    setTimeout(() => {
      this.show = false;
    }, 4000);
  }
}
