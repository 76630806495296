<header class="w-full bg-retro_gray">
    <div class="mx-6 md:mx-12 flex h-[126px] items-center ">
        <a [href]="home">
            <img src="assets/images/white_logo.png" class="w-[190px] lg:w-[260px]">
        </a>
            
        <nav class="w-full h-full hidden xl:grid items-center justify-items-end">
            <ul class="flex h-full gap-16 items-center ">
                <li>
                    <a class="font-bold text-sm bg-white text-black px-5 py-2 rounded-md" routerLink="/book">
                        PRENOTA
                    </a>
                </li>
                <li>
                    <a class="font-bold text-sm text-white" routerLink="/offers">
                        OFFERTE
                    </a>
                </li>
                <li>
                    <a class="font-bold text-sm text-white" routerLink="/products">
                        PRODOTTI
                    </a>
                </li>
                <li *ngIf="isLogged">
                    <a class="font-bold text-sm text-white" routerLink="/user-reservations">
                        PRENOTAZIONI
                    </a>
                </li>
                <!--
                <li disabled>
                    <a class="font-bold text-sm text-white" routerLink="/news">
                        NEWS
                    </a>
                </li>
                -->
                
                <li routerLink="/profile">
                    <app-user></app-user>
                </li>
                <li (click)="hideCart(!isCartHidden)" class="relative">
                    <div *ngIf="cartItems > 0" class="absolute bottom-5 left-5 bg-red-600 rounded-full px-2 text-white">{{cartItems}}</div>
                    <app-bag class="bg-white"></app-bag>
                </li>
            </ul>

        </nav>
        <div class="w-full justify-items-end grid xl:hidden">
            <!--Creo una croce per chiudere il menu utilizzando gli span-->
            <div *ngIf="!hidden" class="menu-toggle" (click)="showMenu()">
                <app-cross [color]="'white'"></app-cross>
            </div>
            <div *ngIf="hidden" class="menu-toggle gap-1" (click)="showMenu()">
                <span class="bar"></span>
                <span class="bar"></span>
                <span class="bar"></span>
            </div>
        </div>
    </div>
</header>
<nav class="bg-retro_gray pb-8 h-screen w-full items-center xl:hidden" [hidden]="hidden">
    <ul class="pr-6 flex flex-col gap-5 items-center">
        <li>
            <a class="font-bold text-sm bg-white text-black px-4 py-2  rounded-md "
                (click)="showMenu()" routerLink="/book">
                PRENOTA
            </a>
        </li>
        <li>
            <a class="font-bold text-sm text-white" (click)="showMenu()" routerLink="/offers">
                OFFERTE
            </a>
        </li>
        <li>
            <a class="font-bold text-sm text-white" (click)="showMenu()" routerLink="/products">
                PRODOTTI
            </a>
        </li>
        <li *ngIf="isLogged">
            <a class="font-bold text-sm text-white" (click)="showMenu()" routerLink="/user-reservations">
                PRENOTAZIONI
            </a>
        </li>
        <!--
        <li>
            <a class="font-bold text-sm text-white" (click)="showMenu()" routerLink="/news">
                NEWS
            </a>
        </li>
        -->
       
        <li>
            <a class="font-bold text-sm text-white" (click)="showMenu()" routerLink="/profile">
                ACCOUNT
            </a>
        </li>
        <li class="relative">
            <div *ngIf="cartItems > 0" class="absolute bottom-3 left-16 bg-red-600 rounded-full px-2 text-white text-sm">{{cartItems}}</div>
            <a class="font-bold text-sm text-white" (click)="showMenu()" (click)="hideCart(!isCartHidden)">
                CARRELLO
            </a>
        </li>
    </ul>
</nav>