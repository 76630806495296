<div *ngIf="currentState == states.LOADING">
    <div class="h-full flex justify-center items-center">
        <ng-lottie [options]="loader" class="lg:h-[300px]"></ng-lottie>
    </div>
</div>
<div *ngIf="currentState == states.DEFAULT">
    <app-header class="fixed top-0 left-0 w-screen z-20" (cartEmitter)="setHide($event)"></app-header>
    <main class="mt-[126px] z-0">
        <router-outlet></router-outlet>
        <app-cart [hidden]="hideCart" (hideCart)="setHide($event)" (snackEmitter)="showSnackBar($event)" class="fixed top-[123px] right-0 h-[89vh] w-full lg:w-[33vw] z-5 bg-white overflow-scroll"></app-cart>
    </main>
    <app-footer class="relative z-30"></app-footer>
    <app-snackbar></app-snackbar>
</div>