import { Injectable } from '@angular/core';
import { Store } from '../../models/store';
import { CollectionReference, Firestore, collection, getDocs, getFirestore, orderBy, query } from 'firebase/firestore';
import { BehaviorSubject, Observable } from 'rxjs';
import { FirebaseApp } from '@angular/fire/app';

@Injectable({
  providedIn: 'root'
})
export class StoreService {
  private storesObj: BehaviorSubject<Store[]> = new BehaviorSubject<Store[]>([]);
  public storesObserver: Observable<Store[]> = this.storesObj.asObservable();
  private db: Firestore;
  private storeCollection: CollectionReference;

  constructor(private app: FirebaseApp) {
    this.db = getFirestore(this.app);
    this.storeCollection = collection(this.db, 'stores');
    this.getStores();
  }

  private getStores() {
    const q = query(this.storeCollection, orderBy('position', 'asc'));
    getDocs(q).then((docs) => {
      let stores: Store[] = [];
      docs.forEach((doc) => {
        let store = doc.data() as Store;
        store.id = doc.id;
        stores.push(store);
      });
      this.storesObj.next(stores);
    }).catch((error) => {
      throw new Error(error);
    });
  }
}
