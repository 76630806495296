import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { FacebookAuthProvider, GoogleAuthProvider, UserCredential, createUserWithEmailAndPassword, getAuth, sendEmailVerification, sendSignInLinkToEmail, signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import { User } from '../../models/user';
import { deleteDoc, doc, getDoc, getFirestore, setDoc } from 'firebase/firestore';
import { FirebaseApp } from '@angular/fire/app';
import { User as FireUser } from 'firebase/auth';
import { BehaviorSubject, Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private userObj: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);
  public userObserver: Observable<User | null> = this.userObj.asObservable(); 
  private auth = getAuth(this.app);
  private db = getFirestore(this.app);

  constructor(private app: FirebaseApp, private router: Router) { 
    this.auth.onAuthStateChanged(async (userAuth) => {
      this.getUser(userAuth);
    });
  }

  private async getUser(userAuth: FireUser | null) {
    if (userAuth) {

      const docRef = doc(this.db, 'users', userAuth.uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        let user = docSnap.data() as User;
        user.birthDay = new Date(docSnap.data()['birthDay'])
        user.email = docSnap.data()['email'] as string;
        user.uid = docSnap.id;
        user.verified = userAuth.emailVerified
        user.token = await userAuth.getIdToken();
        this.userObj.next(user);
      }
    } else {
      this.userObj.next(null);
    }
  }

  public async isLoggedIn(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      getAuth().onAuthStateChanged(async (authUser) => {
        if (authUser) {
          resolve(true);
        } else {
          this.router.navigate(["/login"]);
          resolve(false);
        }
      });
    });
  }

  public updateUserData(user: User) {
    //Aggiorno il documento firestore dell'utente
    const userDoc = doc(this.db, 'users', user.uid as string);
    setDoc(userDoc, {
      name: user.name,
      phoneNumber: user.phoneNumber,
      birthDay: user.birthDay,
      favouriteStoreId: user.favouriteStoreId
    }, { merge: true }).then(() => {
      this.userObj.next(user);
    });
  }

  public async deleteAccount(password: string) {
    if (this.userObj.value != null) {
      this.reauthenticate(this.userObj.value.email, password).then((del) => {
        if (del) {
          if (this.userObj.value != null) {
            deleteDoc(doc(this.db, "users", this.userObj.value.uid as string)).then(() => {
              this.auth.currentUser?.delete().then(() => {
                this.router.navigate(['/']);
              }).catch((error) => {
                throw error;
              });
            }).catch((error) => {
              throw error;
            });
          }
        } else {
          throw Error('Angular: La password inserita non è corretta!');
        }
      }).catch((error) => {
        throw error;
      });
    } else {
      throw Error('Angular: Non sei loggato!');
    }
  }

  private async reauthenticate(email: string, password: string): Promise<boolean> {
    if (this.auth.currentUser) {
      const provider = this.auth.currentUser.providerData[0].providerId;
      if (provider == 'password') {
        signInWithEmailAndPassword(this.auth, this.auth.currentUser.email as string, password).then((userCredential) => {
          return userCredential.user?.email == email;
        });
      }
      else if (provider == 'google.com') {
        const provider = new GoogleAuthProvider();
        signInWithPopup(this.auth, provider).then((userCredential) => {
          return userCredential.user?.email == email;
        });
      }
      else if (provider == 'facebook.com') {
        const provider = new FacebookAuthProvider();
        signInWithPopup(this.auth, provider).then((userCredential) => {
          return userCredential.user?.email == email;
        });
      }
    }
    return false;
  }

  public logout() {
    this.auth.signOut();
  }

  public async register(email: string, password: string): Promise<UserCredential> {
    return new Promise(async (resolve, reject) => {
      createUserWithEmailAndPassword(this.auth, email, password).then((userC) => {
        resolve(userC);
      }).catch((error) => {
        reject(error);
      });
    });
  }


  public async login(email: string, password: string): Promise<UserCredential> {
    return new Promise((resolve, reject) => {
      signInWithEmailAndPassword(this.auth, email, password).then(userC => {
        resolve(userC);
      }).catch(error => {
        reject(error);
      });
    });
  }

  public async loginWithGoogle(): Promise<UserCredential> {
    return new Promise((resolve, reject) => {
      signInWithPopup(this.auth, new GoogleAuthProvider()).then(userC => {
        this.firstLogin(userC).then(firstLogin => {
          if (firstLogin) {
            this.createUser(userC).then(() => {
              resolve(userC);
            }).catch(error => {
              reject(error);
            });
          } else {
            resolve(userC);
          }
        }).catch(error => {
          reject(error);
        });
      }).catch(error => {
        reject(error);
      });
    });
  }

  private async firstLogin(userC: UserCredential): Promise<boolean> {
    let docSnap = await getDoc(doc(this.db, 'users', userC.user.uid));
    return !docSnap.exists();
  }

  private async createUser(userC: UserCredential, name: string = "", surname: string = "") {
    let user = userC.user;
    await setDoc(doc(this.db, 'users', user.uid), {
      uid: user.uid,
      name: (name != "") ? name + " " + surname : user.displayName,
      email: user.email,
      phoneNumber: user.phoneNumber,
      birthDay: new Date(),
      favouriteStoreId: "rende1"
    });
  }
}
