export class Status {
    code: string = "";
    error: string = "";
    message: string = "";

    constructor(message: string) {
        this.code = "KO";
        this.error = message;
        this.message = message;
    }
}