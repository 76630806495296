import { ApplicationConfig, LOCALE_ID, NgModule, importProvidersFrom } from '@angular/core';
import { RouterModule, provideRouter, withViewTransitions } from '@angular/router';
import { routes } from './app.routes';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getDatabase, provideDatabase } from '@angular/fire/database';
import { provideHttpClient, withFetch } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideNgxStripe } from 'ngx-stripe';
import { LottieCacheModule, LottieModule, provideLottieOptions } from 'ngx-lottie';
import { environment } from '../environments/environment';

export async function playerFactory() {
  let player = await import(/* webpackChunkName: 'lottie-web' */ 'lottie-web'); 
  return player;
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(withFetch()), provideRouter(routes, withViewTransitions()), importProvidersFrom(provideFirebaseApp(() => initializeApp(environment.firebase))), 
    importProvidersFrom(provideAuth(() => getAuth())), importProvidersFrom(provideFirestore(() => getFirestore())), 
    importProvidersFrom(provideDatabase(() => getDatabase())), provideAnimations(), provideNgxStripe(), provideLottieOptions({ player: playerFactory }),
    provideNgxStripe()
]
};
