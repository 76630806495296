<div class="container mx-auto px-6 p-6 min-h-screen">
  <div class="container mx-auto bg-white rounded-lg  shadow-md p-6">

    <h1 class="text-4xl font-bold mb-4 text-default_grey">Privacy Policy</h1>
    <p class="mb-4 text-gray-700">
      Il tuo rispetto per la privacy è molto importante per noi. Questa politica sulla privacy spiega quali informazioni
      raccogliamo, come le usiamo e come le proteggiamo.
    </p>
    <h2 class="text-2xl font-bold mb-2 text-default_grey">Quali informazioni raccogliamo?</h2>
    <p class="mb-4 text-gray-700">
      Raccogliamo solo le informazioni necessarie per fornire i nostri servizi. Questo può includere il tuo nome,
      indirizzo email e altre informazioni di contatto.
    </p>
    <h2 class="text-2xl font-bold mb-2 text-default_grey">Come usiamo le tue informazioni?</h2>
    <p class="mb-4 text-gray-700">
      Utilizziamo le tue informazioni solo per fornire i servizi che hai richiesto. Non vendiamo, affittiamo o
      condividiamo le tue informazioni con terze parti senza il tuo consenso.
    </p>
    <h2 class="text-2xl font-bold mb-2 text-default_grey">Come proteggiamo le tue informazioni?</h2>
    <p class="mb-4 text-gray-700">
      Proteggiamo le tue informazioni con misure di sicurezza appropriate e le conserviamo solo per il tempo necessario
      per fornire i nostri servizi.
    </p>
    <p class="mb-4 text-gray-700">
      Se hai domande sulla nostra politica sulla privacy, non esitare a contattarci.
    </p>
    <h2 class="text-2xl font-bold mb-2 text-default_grey">Vuoi cancellare il tuo account ed i dati associati al tuo account?</h2>
    <p class="mb-4 text-gray-700">
      Puoi procedere all'eliminazione del tuo account e dei dati ad esso associati direttamente in app, nella sezione dedicata al tuo profilo. Troverai il tasto "Elimina Account", ti basterá cliccarlo e confermare!
      Se dovessi trovare difficoltá o se preferisci contattarci via mail, scrivici su retroscenabarbieriapp&#64;gmail.com. Saremo lieti di aiutarti!
    </p>
  </div>
</div>
