import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Offer } from '../../../models/offer';
import { User } from '../../../models/user';
import { ServicesService } from '../../../services/services/services.service';
import { Service } from '../../../models/service';

@Component({
  selector: 'app-offer-card',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './offer-card.component.html',
  styleUrl: './offer-card.component.css'
})
export class OfferCardComponent {
  @Output() offerEmitter = new EventEmitter<void>();
  @Output() descriptionEmitter = new EventEmitter<void>();
  @Input() offer!: Offer;
  @Input() service!: Service;


  

}
