import { Injectable } from '@angular/core';
import { String } from '../../models/string';
import { initializeApp } from 'firebase/app';
import { environment } from '../../../environments/environment';
import { CollectionReference, collection, getDocs, getFirestore, query } from 'firebase/firestore';
import { Response } from '../../models/response';
import { FirebaseApp } from '@angular/fire/app';
import { Firestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class StringsService {
  private strings: String[] = [];
  private db: Firestore;
  private stringCollection: CollectionReference;
  

  constructor(private app: FirebaseApp) {
    this.db = getFirestore(this.app);
    this.stringCollection = collection(this.db, 'strings');
   }

  public async getStrings(forceUpdate: boolean = false): Promise<String[]> {
    if(forceUpdate || this.strings.length == 0){
      this.strings = [];
      const docs = await getDocs(this.stringCollection);
      docs.forEach((doc) => {
        let string = doc.data() as String;
        this.strings.push(string);
      });
    }
    return this.strings;
  }

  public async getString(r: Response): Promise<string>{
    if(this.strings.length == 0){
      await this.getStrings();
    }
    for(let i = 0; i < this.strings.length; i++){
      if(this.strings[i].key == r.status.error){
        return this.strings[i].value;
      }
    }
    return r.status.message;
  }
}
