import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Cart } from '../../../models/cart';
import { CrossComponent } from '../../miscellaneous/cross/cross.component';
import { QRCodeComponent, QRCodeModule } from 'angularx-qrcode';

@Component({
  selector: 'app-user-cart-info',
  standalone: true,
  imports: [CommonModule, CrossComponent, QRCodeModule],
  templateUrl: './user-cart-info.component.html',
  styleUrl: './user-cart-info.component.css'
})
export class UserCartInfoComponent {
  @Input() cart: Cart | null = null;
  @Output() deletePayedCart: EventEmitter<void> = new EventEmitter<void>();
  @Output() deleteProductFromCart: EventEmitter<string> = new EventEmitter<string>();
  @Output() closeCart: EventEmitter<void> = new EventEmitter<void>();

  deleteCart() {
    this.deletePayedCart.emit();
  }
  
  deleteProduct(p: string) {
    this.deleteProductFromCart.emit(p);
  }

  
}
