import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Product } from '../../../models/product';
import { CartService } from '../../../services/cart/cart.service';
import { StringsService } from '../../../services/strings/strings.service';
import { User } from '../../../models/user';

@Component({
  selector: 'app-product-card',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './product-card.component.html',
  styleUrl: './product-card.component.css'
})
export class ProductCardComponent {
  @Input() product!: Product;
  @Input() user: User | null = null;
  @Output() snackEmitter: EventEmitter<string> = new EventEmitter<string>();
  @Output() descriptionEmitter = new EventEmitter<void>();
  protected addingToCart = false;

  constructor(private cartService: CartService, private stringsService: StringsService) { }

  protected showDescription() {
    this.descriptionEmitter.emit();
  }

  protected async addToCart() {
    if (this.user) {
      if (this.user.verified) {
        if (this.user.birthDay && this.user.favouriteStoreId) {
          if (this.addingToCart) return;
          this.addingToCart = true;
          this.cartService.addProductToCart(this.product).subscribe({
            next: () => {
              this.snackEmitter.emit("Prodotto aggiunto al carrello!");
              this.addingToCart = false;
            },
            error: async (error) => {
              this.snackEmitter.emit(await this.stringsService.getString(error));
              this.addingToCart = false;
            }
          });
        }else{
          this.snackEmitter.emit("Devi completare il tuo profilo per aggiungere prodotti al carrello!");
        }
      } else {
        this.snackEmitter.emit("Devi verificare la tua email per aggiungere prodotti al carrello!");
      }
    } else {
      this.snackEmitter.emit("Devi essere loggato per aggiungere prodotti al carrello!");
    }
  }

}
