<div *ngIf="userReservation.reservation">
    <div class="rounded-xl shadow-2xl w-[350px] h-[250px] md:w-[350px] lg:w-[400px] mx-auto grid">
        <div class="w-full grid grid-cols-12 p-6">
            <div class="col-span-3 grid place-items-center">
                <p class="font-semibold text-lg">{{userReservation.reservation.executionStart | date: 'dd'}}</p>
                <p class="font-semibold text-lg">{{userReservation.reservation.executionStart | date: 'MMMM'}}</p>
                <p class="font-semibold text-lg">{{userReservation.reservation.executionStart | date: 'yyyy'}}</p>
            </div>
            <div class="col-span-6 grid place-items-center">
                <p class="font-light">{{userReservation.reservation.store.name}}</p>
                <h1 class="text-lg md:text-xl lg:text-2xl font-semibold mt-2">{{userReservation.reservation.service.name}}</h1>
                <p class="font-light">Durata {{userReservation.reservation.duration}} minuti</p>
                <p class="font-light">{{userReservation.reservation.operator.name}} {{userReservation.reservation.operator.surname}}</p>
            </div>
            <div class="col-span-3 grid place-items-center">
                <app-clock></app-clock>
                <p class="font-semibold text-lg">{{userReservation.reservation.executionStart | date : 'HH:mm'}}</p>
            </div>
        </div>
        <div class="w-full flex self-end" *ngIf="canDelete()">
            <button class="w-1/2 rounded-bl-xl  bg-red-900 font-semibold text-white py-4" (click)="deleteReservation()">ELIMINA</button>
            <button class="w-1/2 rounded-br-xl  bg-retro_gray font-semibold text-white py-4" (click)="showReservation()">VISUALIZZA</button>
        </div>
        <div class="w-full flex self-end" *ngIf="!canDelete()">
            <button class="w-full rounded-b-xl  bg-retro_gray font-semibold text-white py-4" (click)="showReservation()">VISUALIZZA</button>
        </div>
    </div>
</div>
<div *ngIf="userReservation.cart">
    <div class="rounded-xl shadow-2xl w-[350px] h-[250px] md:w-[350px] lg:w-[400px] mx-auto grid">
        <div class="w-full grid grid-cols-12 p-6">
            <div class="col-span-3 grid place-items-center">
                <p class="font-semibold text-lg">{{userReservation.cart.timestamp | date: 'dd'}}</p>
                <p class="font-semibold text-lg">{{userReservation.cart.timestamp | date: 'MMMM'}}</p>
                <p class="font-semibold text-lg">{{userReservation.cart.timestamp | date: 'yyyy'}}</p>
            </div>
            <div class="col-span-6 grid place-items-center">
                <h1 class="text-lg md:text-xl lg:text-2xl font-semibold mt-2">{{userReservation.cart.store.name}}</h1>
            </div>
            <div class="col-span-3 grid place-items-center">
                <app-bag></app-bag>
            </div>
        </div>
        <div class="w-full flex self-end" >
            <button class="w-full rounded-b-xl  bg-retro_gray font-semibold text-white py-4" (click)="showCart()">VISUALIZZA</button>
        </div>
    </div>
</div>