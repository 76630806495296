import { InMemoryScrollingFeature, InMemoryScrollingOptions, Routes, provideRouter, withInMemoryScrolling } from '@angular/router';
import { HomeComponent } from './components/pages/home/home.component';
import { ProductsComponent } from './components/pages/products/products.component';
import { ProfileComponent } from './components/pages/profile/profile.component';
import { BookComponent } from './components/pages/book/book.component';
import { OffersComponent } from './components/pages/offers/offers.component';
import { NewsComponent } from './components/pages/news/news.component';
import { LoginComponent } from './components/pages/login/login.component';
import { RegisterComponent } from './components/pages/register/register.component';
import { authenticateGuard } from './guards/authenticate/authenticate.guard';
import { UserReservationsComponent } from './components/pages/user-reservations/user-reservations.component';
import { CookiePolicyComponent } from './components/pages/cookie-policy/cookie-policy.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';

export const routes: Routes = [
    {path: "products", component: ProductsComponent},
    {path: "profile", component: ProfileComponent, canActivate: [authenticateGuard]},
    {path: "book", component: BookComponent},
    {path: "offers", component: OffersComponent},
    {path: "news", component: NewsComponent},
    {path: "login", component: LoginComponent},
    {path: "register", component: RegisterComponent},
    {path: "cookie-policy", component: CookiePolicyComponent},
    {path: "privacy-policy", component: PrivacyPolicyComponent},
    {path: "user-reservations", component: UserReservationsComponent, canActivate: [authenticateGuard]},
    {path: "**", redirectTo: "book"}
];
