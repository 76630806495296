import { Component, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';
import { BagComponent } from '../../miscellaneous/bag/bag.component';
import { UserComponent } from '../../miscellaneous/user/user.component';
import { getAuth } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { environment } from '../../../../environments/environment.development';
import { CartService } from '../../../services/cart/cart.service';
import { CrossComponent } from '../../miscellaneous/cross/cross.component';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CrossComponent,CommonModule, RouterLink, BagComponent, UserComponent],
  templateUrl: './header.component.html',
  styleUrl: './header.component.css'
})
export class HeaderComponent {
  @Output() cartEmitter = new EventEmitter<boolean>();
  protected hidden: boolean = true;
  protected isCartHidden: boolean = true;
  protected isLogged: boolean = false;
  protected cartItems: number = 0;
  protected home: string = environment.homePageUrl;
  constructor(private cartService: CartService){
    this.userObserver();
    this.cartObserver();
  }

  private cartObserver(){
    this.cartService.cartObserver.subscribe({
      next: (cart) => {
        this.cartItems = 0;
        cart.products.forEach(p => {
          this.cartItems += p.quantity;
        })
        cart.reservations.forEach(r => {
          this.cartItems++;
        })
      }
    });
  }

  private userObserver(){
    getAuth().onAuthStateChanged(async (authUser) => {
      if(authUser){
        this.isLogged = true;
      }
      else
        this.isLogged = false;
    });
  }

  protected showMenu() {
    this.hidden = !this.hidden;
    if(this.hidden){
      this.hideCart(true);
    }
  }

  protected hideCart(h: boolean) {
    this.isCartHidden = h;
    this.cartEmitter.emit(this.isCartHidden);
  }






}
