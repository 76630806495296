<svg version="1.1" id="_x34_" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
	 viewBox="0 0 512 512"  xml:space="preserve" width="200px" height="200px">
<g>
	<g>
		<linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="256" y1="1.2927" x2="256" y2="349.1949">
			<stop  offset="0" style="stop-color:#44484A"/>
			<stop  offset="1" style="stop-color:#193741"/>
		</linearGradient>
		<path style="fill:url(#SVGID_1_);" d="M512,17.574v315.264c0,8.977-7.38,16.357-16.357,16.357H16.356
			C7.379,349.195,0,341.815,0,332.838V17.574C0,8.597,7.379,1.293,16.356,1.293h479.287C504.62,1.293,512,8.597,512,17.574z"/>
		<g style="opacity:0.2;">
			<path style="fill:#9FA6A9;" d="M299.373,104.564c-1.107-1.245-1.798-2.351-3.181-1.729c-1.383,0.622-1.383,1.521-2.836,0.415
				c-1.452-1.106-0.83-2.421-4.081-3.596c-3.251-1.176-3.942-1.867-7.47-2.351c-3.527-0.484-6.086-0.968-6.294-0.968
				c-0.207,0,0.761,0.622-0.622,1.314c-1.383,0.692-2.49,0.83-3.873-0.069c-1.383-0.899-1.314-1.176-3.665-0.968
				c-2.352,0.208-3.735,0.761-4.772,0.899c-1.037,0.138,0-0.069-1.591-1.176c-1.591-1.107-0.484-1.729-3.251-1.522
				c-2.766,0.208-3.942,0.336-4.634-0.04c-0.692-0.375,0.138-2.312-2.559-3.142c-2.697-0.83-3.251-0.207-4.634-0.415
				c-1.383-0.207-1.314-0.553-5.256-0.83c-3.942-0.277-3.735,0.346-6.432,1.176c-2.697,0.83-3.389,0.83-5.879,0.692
				c-2.49-0.138-1.867,1.383-3.389,1.591c-1.522,0.207-3.942-1.314-4.219-2.213c-0.277-0.899,2.075-1.867,0-2.697
				c-2.075-0.83-5.948-2.421-6.709-2.144c-0.761,0.277-0.346,1.522-1.729,1.867c-1.383,0.346-3.181,0.346-5.325-0.622
				s-2.974-1.447-5.256-1.277c-2.282,0.17-3.527,0.862-5.464,1c-1.937,0.138-3.112-0.692-1.798-1.314
				c1.314-0.622,2.974-2.49,2.974-3.389c0-0.899,0.968-2.836-1.245-3.043c-2.213-0.207-2.628,1.314-4.703,0
				c-2.075-1.314-4.772-1.383-5.533-0.968c-0.761,0.415-0.761,1.107-2.075,1.314c-1.314,0.207-0.83,1.383-2.421,1.798
				c-1.591,0.415-1.383-0.83-5.049-0.484c-3.666,0.346-7.193,0.83-8.092,1.867c-0.899,1.037,0.208,2.213-1.729,3.112
				c-1.937,0.899-6.363,0.207-6.916,0.968c-0.553,0.761,2.421,3.389,0.415,4.565c-2.006,1.176-3.251-0.83-7.884,0
				c-4.634,0.83-4.081,0.692-5.809,1.729c-1.729,1.037-2.075,0.346-3.112,1.798s-4.15,2.767-6.294,2.767s-3.043-1.314-5.81-0.207
				c-2.766,1.107-2.351,1.867-6.709,1.522c-4.357-0.346-6.155-0.277-6.985,0.277c-0.83,0.553-0.83,1.937-2.974,2.559
				c-2.144,0.622-5.464,1.729-6.294,1.522c-0.83-0.207-3.665,1.176-5.602,1.383c-1.937,0.208-3.043,0.069-3.665-0.692
				c-0.623-0.761-1.314-2.075-0.346-1.66c0.968,0.415,1.798,0.968,3.527,0.899c1.729-0.069,3.666-1.452,3.251-2.213
				c-0.415-0.761-0.069-1.867-3.458-2.697c-3.389-0.83-4.841-0.692-7.4-2.559c-2.559-1.867,0.968-3.112-5.74-2.421
				c-6.709,0.692-8.507,1.383-11.204,3.389c-2.697,2.006-3.251,3.735-6.017,6.155c-2.766,2.421-1.798,3.112-5.256,4.841
				c-3.458,1.729-4.011,0.899-5.049,3.527c-1.038,2.628-1.729,5.671,0.415,6.225c2.144,0.553,4.357-0.415,5.464-0.83
				c1.107-0.415,1.937,0.346,2.075,1.591s0.207,3.32,1.591,3.596c1.383,0.277,4.288-1.66,4.15-3.665
				c-0.138-2.006-0.622-3.389,0.761-5.395s2.282-3.458,3.735-4.15c1.452-0.692,3.458-1.936,2.697-0.346
				c-0.761,1.591-2.144,3.458-1.176,5.049c0.968,1.591,3.181,0.484,3.735,2.075c0.553,1.591-0.761,3.112-1.107,3.666
				c-0.346,0.553,0.83,1.245-0.761,2.352c-1.591,1.106-2.628,2.282-4.357,3.596c-1.729,1.314-6.847,2.213-8.023,1.937
				c-1.176-0.277-3.112,0-3.251-1.591c-0.138-1.591,1.729-3.32-0.207-4.081c-1.937-0.761-4.081,0.277-4.703,2.974
				c-0.623,2.697-1.591,3.181-3.181,4.495c-1.591,1.314-0.277,5.325-4.91,6.086c-4.634,0.761-7.262-0.346-6.916,1.314
				c0.346,1.66,3.251,0.622,3.32,3.389c0.069,2.766,0.761,3.735-1.245,4.288s-2.49-0.484-5.187,0.415s-2.559,4.288-3.112,5.74
				c-0.553,1.452-0.83,2.766,0.968,3.389c1.798,0.622,4.703,1.729,6.847,0.83c2.144-0.899,3.389-1.037,3.666-2.697
				s0.968-2.697,2.421-2.974c1.452-0.277,2.421-1.937,3.25-2.905c0.83-0.968,0-0.622,2.144-0.553c2.144,0.069,4.15-2.213,4.91-1.591
				c0.761,0.622,3.043,1.937,4.495,3.32c1.452,1.383,3.458,1.798,3.112,3.873c-0.346,2.075-0.484,4.481,0.899,3.866
				c1.383-0.615,0.899-2.483,1.798-3.382c0.899-0.899,2.075-0.069,1.937-1.66c-0.138-1.591-2.49-2.006-3.735-3.458
				c-1.245-1.452-2.213-3.043-0.692-2.559c1.521,0.484,4.426,1.798,4.703,2.905c0.277,1.107,2.836,3.389,3.112,5.533
				c0.277,2.144,1.798,4.081,2.49,2.974c0.692-1.107,0.069-1.937,1.591-2.213c1.521-0.277,0.968-1.383,1.798-2.836
				c0.83-1.452,3.112,0.692,3.942-2.006c0.83-2.697-1.037-3.626,1.037-5.098c2.075-1.472,2.974-2.44,3.596-1.126
				c0.622,1.314,2.213,2.697,2.974,1.314c0.761-1.383-1.383-1.798,0.415-2.836c1.798-1.037,3.389-2.282,2.905-0.484
				c-0.484,1.798-1.176,2.974,0.069,4.219c1.245,1.245,4.91,2.006,2.905,3.112c-2.006,1.107-4.91,0.484-7.608,1.66
				c-2.697,1.176-3.112,0.622-3.804,2.836c-0.692,2.213-0.761,2.905,1.521,2.905c2.282,0,3.32-1.591,3.942,0.415
				c0.622,2.006,0.484,6.501-0.484,6.916c-0.968,0.415-1.383-0.207-5.671,0.277c-4.288,0.484-4.081,1.522-7.539-0.208
				c-3.458-1.729-4.288-3.873-5.533-1.729c-1.245,2.144-0.346,3.235-3.043,2.69c-2.697-0.546-0.761-1.721-4.357-2.62
				c-3.596-0.899-4.288-0.622-4.288-2.213s0.484-3.666-2.352-3.873c-2.836-0.207-7.815,0.968-11.896,2.144
				c-4.081,1.176-3.251-0.277-7.954,1.383c-4.703,1.66-7.608,2.213-8.092,4.772s-2.075,4.011-3.112,5.395
				c-1.037,1.383-1.591,7.47-1.867,11.204c-0.277,3.735,0.692,2.006-0.346,4.772c-1.038,2.767,1.867,7.124,3.043,8.299
				c1.176,1.176,4.98,4.91,7.4,5.187c2.421,0.277,2.213-0.899,5.948-0.346c3.735,0.553,3.666-1.591,7.47-0.622
				c3.804,0.968,5.187,1.66,7.4,2.836c2.213,1.176,2.421,2.144,2.421,4.98c0,2.836,0.138,0.692,2.144,4.703
				c2.006,4.011,5.533,7.746,3.458,11.204c-2.075,3.458-2.974,4.08-1.66,7.331c1.314,3.251,4.98,9.821,5.395,13.141
				c0.415,3.32-0.622,3.527-0.207,5.533c0.415,2.006,4.15,4.219,7.539,2.836c3.389-1.383,4.426-1.452,7.193-4.011
				c2.766-2.559,5.533-0.622,7.608-5.948c2.075-5.325,1.383-5.948,2.282-8.092c0.899-2.144,6.294-5.256,6.501-9.129
				c0.207-3.873-1.176-6.778-0.692-10.512c0.484-3.735,0.83-6.985,4.426-9.959c3.596-2.974,4.426-0.069,6.916-5.671
				s5.464-6.985,2.697-6.916c-2.767,0.069-8.161,2.282-10.443,1.037c-2.282-1.245-6.017-6.916-6.778-9.544
				c-0.761-2.628-0.484-1.314-1.798-5.049c-1.314-3.735-2.559-5.671-3.112-7.124c-0.553-1.452-0.899-4.011,0.761-1.936
				c1.66,2.075,3.251,5.187,5.464,9.959c2.213,4.772,3.527,8.438,5.464,9.199c1.937,0.761,2.559,1.66,5.464,0.484
				c2.905-1.176,3.942-1.314,6.709-2.836c2.766-1.522,4.426-1.798,6.432-3.804c2.006-2.006,6.57-2.836,1.522-4.772
				c-5.049-1.936-6.778-0.83-8.23-3.043c-1.452-2.213-3.804-4.426-1.66-4.08c2.144,0.346,1.314,0.968,3.735,1.452
				c2.421,0.484,4.15-0.606,5.464,1.149c1.314,1.756,3.527,3.001,5.602,2.032c2.075-0.968,2.905-1.521,5.118-0.277
				c2.213,1.245,0.761,4.772,4.081,5.74c3.32,0.968,3.873-2.628,4.565,1.937c0.692,4.565,2.006,8.783,3.181,9.683
				c1.176,0.899-0.622,5.049,3.112,3.942c3.735-1.107,4.841-0.968,4.841-4.841c0-3.873-0.138-4.841,1.867-6.225
				c2.006-1.383,4.772-2.766,6.017-4.219c1.245-1.452,0.484-2.352,2.974-2.075c2.49,0.277,8.645,7.193,8.368,8.645
				c-0.277,1.452-0.553,2.006,0.622,2.905c1.176,0.899,1.107,1.176,2.006,4.426c0.899,3.251,0.692,2.766,3.527,5.81
				c2.836,3.043,6.847,5.187,6.501,2.974c-0.346-2.213-1.729-1.937-2.974-5.464c-1.245-3.527-2.697-6.709-1.037-5.809
				c1.66,0.899,1.176,2.213,2.905,2.49c1.729,0.277,2.006-1.729,3.32-2.352c1.314-0.622,3.666-5.671,1.798-7.884
				c-1.867-2.213-3.804-3.735-1.867-4.426c1.937-0.692,1.037-0.207,3.32,0.207c2.282,0.415,1.591-0.692,4.357-1.452
				c2.766-0.761,6.778-2.006,8.922-4.772c2.144-2.766,4.495-3.596,3.873-7.262c-0.622-3.665-1.452-5.256-2.766-6.225
				c-1.314-0.968-1.383-1.66-0.208-2.075c1.176-0.415,4.219-0.484,2.49-1.383c-1.729-0.899-0.899-0.415-2.905-0.899
				c-2.006-0.484-4.15,0.484-2.352-1.176c1.798-1.66,3.181-3.043,3.181-3.043s-0.484,0.346,0.692,1.591
				c1.176,1.245,1.937,0.692,2.49,0.415c0.553-0.277,2.213-1.452,2.421-0.138c0.208,1.314-2.144,2.282-0.761,2.766
				s2.628-0.761,2.075,1.729c-0.553,2.49,0.553,4.772,1.729,3.527c1.176-1.245,2.905-4.91,2.559-6.432
				c-0.346-1.521-1.522-0.761,0.069-3.527c1.591-2.766,1.591-4.703,3.735-4.357c2.144,0.346,4.219-1.729,5.049-2.559
				c0.83-0.83,4.357-2.767,4.91-5.118c0.553-2.352,0.277-2.697,1.037-5.395c0.761-2.697,2.421-2.697,0.346-3.942
				c-2.075-1.245-3.666-0.968-4.011-2.352c-0.346-1.383,5.533-8.092,8.092-9.129c2.559-1.037,4.357-0.553,6.086,0.207
				c1.729,0.761,2.282,1.521,3.873,0.553c1.591-0.968,2.628-1.522,3.527-1.591c0.899-0.069,3.389-1.937,4.426-1.107
				c1.037,0.83,1.176,1.729,2.421,1.176s2.905-3.458,3.458-2.421c0.553,1.037,0.415,2.282-1.107,4.288
				c-1.522,2.006-2.836,2.352-4.772,3.942c-1.937,1.591-2.697,3.251-2.697,4.703s-0.553,6.294,0.207,6.363
				c0.761,0.069,0.968-0.138,2.974-2.697c2.006-2.559,3.043-2.697,4.357-3.666c1.314-0.968,1.798-2.144,2.006-4.426
				c0.208-2.282-1.245-2.836,0.899-4.011c2.144-1.176,4.703-2.421,6.847-2.075c2.144,0.346,1.66,0.277,3.251-1.314
				c1.591-1.591,2.697-2.628,4.357-2.49c1.66,0.138,4.357,0.484,4.772-0.346c0.415-0.83,0.069-1.521,0.138-2.697
				c0.069-1.176-0.069-1.66,1.522-2.075c1.591-0.415,1.521-0.968,2.282-1.245c0.761-0.277,1.245,0.553,2.49,1.037
				c1.245,0.484,4.288,0.553,4.98-0.346C298.266,105.325,299.373,104.564,299.373,104.564z"/>
			<path style="fill:#9FA6A9;" d="M238.649,142.188c0.904,0.333,2.628,1.729,3.666,1.66c1.037-0.069,1.591,1.314,0.761,1.452
				c-0.83,0.138-3.596,1.452-3.596,1.452s-0.83-1.037-1.314,0c-0.484,1.037-0.761,1.798-0.622,2.421
				c0.138,0.622,1.107,2.421,0.553,3.32c-0.553,0.899-1.314,1.383-1.314,2.49c0,1.106,0.692,2.282-0.277,2.766
				c-0.968,0.484-1.937,0.623-2.766,0.899c-0.83,0.277-1.452,0.346-1.798,1.107c-0.346,0.761-1.176,1.867-2.905,1.798
				c-1.729-0.069-1.729-1.452-3.32-0.138c-1.591,1.314-1.66,2.075-2.49,1.729c-0.83-0.346-0.208-0.83,0-2.144
				c0.207-1.314-2.836-0.968-0.069-2.49c2.766-1.522,2.766-1.729,4.634-2.006c1.867-0.277,2.905,0.622,3.665-0.968
				c0.761-1.591,0-2.213,1.522-2.282c1.522-0.069,2.49,0.623,2.766-1.107c0.277-1.729,0.069-3.181,0-4.288
				c-0.069-1.107-0.484-2.144,0.761-2.559c1.245-0.415,1.383,0.346,1.522-0.622C238.165,143.709,237.335,141.704,238.649,142.188z"
				/>
			<path style="fill:#9FA6A9;" d="M210.846,179.673c1.314,0.208,1.936,2.559,1.522,3.527c-0.415,0.968-0.83,2.075,0.346,2.282
				c1.176,0.208,2.006,0.346,2.006,1.176c0,0.83,0.484-0.899,1.383,1.314c0.899,2.213,0.069,2.559,1.107,4.219
				c1.037,1.66,2.282,2.49,0.83,3.32s-0.346,1.729-1.867,1.245c-1.521-0.484-0.899-0.208-1.66-1.66
				c-0.761-1.452-1.245-0.277-1.936-0.346c-0.692-0.069-0.899-0.069-0.346-1.037c0.553-0.968,0.83-0.899,0.208-2.006
				c-0.622-1.107,0.346-2.421-0.553-2.628c-0.899-0.207-1.176,1.314-2.766,2.282c-1.591,0.968-3.942,1.245-3.873,0.899
				c0.069-0.346,0.968-0.83,2.421-2.628c1.452-1.798,2.352-0.692,2.075-3.458c-0.277-2.766-1.245-2.006-0.346-3.942
				C210.293,180.296,210.846,179.673,210.846,179.673z"/>
			<path style="fill:#9FA6A9;" d="M206.42,195.373c1.047,1.047,2.421,0.761,2.006,1.798c-0.415,1.037-1.647,1.176-0.962,2.213
				c0.685,1.038,1.308,2.559,2.207,2.421s0.83-0.83,3.043-0.83c2.213,0,3.596-0.484,3.735,0.415
				c0.138,0.899-0.968,0.968-2.421,2.282c-1.452,1.314-2.905,2.628-1.729,3.458c1.176,0.83,1.66,2.352,1.245,2.905
				c-0.415,0.553-0.83,1.107,0.138,1.798c0.968,0.692,5.602,0.484,4.495,2.144c-1.107,1.66-3.804,2.559-6.64,2.628
				c-2.836,0.069-0.556-0.761-6.295-1.314c-5.739-0.553-6.707,0.899-10.718-1.037c-4.011-1.937-6.64-0.277-8.853-3.804
				c-2.213-3.527-1.314-1.798-4.081-5.464c-2.766-3.665-1.452-3.665-3.804-5.118c-2.352-1.452-4.357-3.527-2.766-3.527
				c1.591,0,0.83-1.867,5.74,2.213c4.91,4.08,3.596,3.873,7.193,6.294c3.596,2.421,4.772,2.697,6.086,2.421
				c1.314-0.277,3.251,0,2.006-1.591c-1.245-1.591-1.937-3.596-0.899-3.942c1.038-0.346,0.968,1.383,2.697-0.484
				c1.729-1.867,1.729-2.352,3.251-2.974c1.522-0.622,0.692,0,2.144-0.899C204.691,196.48,205.244,194.197,206.42,195.373z"/>
			<path style="fill:#9FA6A9;" d="M225.439,203.396c0.752,0.967,2.006,2.213,2.559,3.251s1.591-0.878,3.596-0.82
				c2.006,0.059,4.98,1.165,6.225,1.719s3.389,0.899,4.703,2.697s2.282,0.761,2.352,2.559c0.069,1.798,0.346,1.591,1.521,2.628
				c1.176,1.037,1.383,2.974-0.692,2.144c-2.075-0.83-2.697-3.665-4.91-3.389c-2.213,0.277-1.314,0.899-3.043,1.245
				c-1.729,0.346-2.49-0.277-4.565-0.415c-2.075-0.138-3.596,0.553-2.628-1.314c0.968-1.867,1.66-1.936-1.729-3.32
				c-3.389-1.383-4.703-0.692-4.703-2.144c0-1.452,0.553-1.591-0.761-2.836c-1.314-1.245-1.729-2.144-0.899-2.767
				C223.295,202.012,224.955,202.773,225.439,203.396z"/>
			<path style="fill:#9FA6A9;" d="M229.451,223.314c2.282,1.176,3.181,3.527,4.288,3.112c1.107-0.415,0.692-1.107,1.521-3.596
				c0.83-2.49,0.899-5.049,1.937-4.011c1.037,1.037,0.069,3.062,1.729,3.71c1.66,0.647,2.006,1.132,2.352,3.068
				c0.346,1.937,0.692,1.314,2.421,3.32c1.729,2.006,3.282,1.66,3.577,3.942c0.296,2.282,3.201,4.634,3.339,6.985
				c0.138,2.352,0.553,4.011-1.176,6.985c-1.729,2.974-2.421,4.495-2.974,5.741c-0.553,1.245-2.213,1.867-4.011,1.867
				s-2.836-0.761-3.942-0.484c-1.107,0.277-1.867,1.66-3.181,0.692c-1.314-0.968-2.075-2.213-2.49-2.974
				c-0.415-0.761-0.622-3.32-2.559-3.043c-1.936,0.277-1.66,1.245-2.836,0c-1.176-1.245-0.346-2.282-3.804-2.836
				c-3.458-0.553-4.357-0.83-5.81-0.484c-1.452,0.346-2.974,0.83-4.565,2.075c-1.591,1.245-4.081,0.623-5.464,1.245
				c-1.383,0.622-3.458,2.006-4.98,1.176c-1.522-0.83-0.968-1.245-0.899-3.181c0.069-1.937-0.069-0.622-1.037-3.942
				c-0.968-3.32-0.553-2.836-1.452-4.426c-0.899-1.591-1.591-0.83-0.346-3.112c1.245-2.282,0.83-2.766,1.521-3.527
				c0.692-0.761,1.314,0.208,3.666-1.383c2.352-1.591,3.043-0.899,5.187-1.729c2.144-0.83,2.628-4.011,3.942-4.98
				c1.314-0.968,1.66-1.729,2.628-1.729c0.968,0,2.075,0.553,3.804,1.245c1.729,0.692,1.383,0.761,2.836-0.83
				c1.452-1.591,0-3.112,2.628-2.836c2.628,0.277,3.596-0.138,3.942,1.383C229.589,222.277,229.451,223.314,229.451,223.314z"/>
			<path style="fill:#9FA6A9;" d="M277.449,255.958c1.383-1.798,2.075-2.006,1.591-4.081c-0.484-2.075-0.346-3.702,1.452-1.851
				c1.798,1.851,3.804,2.889,5.187,2.958c1.383,0.069,4.703,1.107,1.314,3.666c-3.389,2.559-2.766,0.761-5.464,4.565
				c-2.697,3.804-0.622,3.873-3.873,5.74c-3.251,1.867-6.847,2.697-6.155,1.038c0.692-1.66,0.346-1.66,3.32-3.873
				c2.974-2.213,4.91-3.873,4.91-5.74C279.731,256.512,277.449,255.958,277.449,255.958z"/>
			<path style="fill:#9FA6A9;" d="M112.291,237.285c-1.245-1.798-1.245-2.352-1.383-5.256c-0.138-2.905-1.107-4.288,0-5.948
				c1.107-1.66,4.011-4.426,5.118-6.363c1.107-1.936,1.383-6.086,2.075-2.352c0.692,3.735,2.905,5.948-0.415,10.928
				C114.366,233.273,112.291,237.285,112.291,237.285z"/>
			<path style="fill:#9FA6A9;" d="M45.758,134.372c-0.277-0.553,0.968-2.352,0.968-2.352s1.522-0.83,1.107-3.873
				c-0.415-3.043-0.692-1.245-1.798-4.15c-1.107-2.905-2.766-2.352-1.107-4.288c1.66-1.937,3.596-4.841,4.011-2.49
				s-0.553,3.873,0.415,4.98c0.968,1.107,2.905,1.335,4.011,3.157c1.107,1.822,5.533,2.514,2.213,4.865
				c-3.32,2.352-4.841,0.83-6.086,2.49C48.247,134.372,45.758,134.372,45.758,134.372z"/>
			<path style="fill:#9FA6A9;" d="M41.331,132.159c2.213-1.245,3.022-0.968,2.894-3.182c-0.128-2.213,1.394-4.15-0.958-4.288
				c-2.351-0.138-3.043,1.245-3.32,2.213c-0.277,0.968-0.277,1.245,0.415,2.213c0.692,0.968,0.83,2.352,0.553,3.043
				C40.64,132.851,41.331,132.159,41.331,132.159z"/>
			<path style="fill:#9FA6A9;" d="M33.308,110.581c1.798-1.245,4.703-0.138,4.841-2.767c0.138-2.628,0.83-3.32-3.181-3.596
				c-4.011-0.277-4.011-0.277-6.501,0c-2.49,0.277-4.841-2.213-2.905,1.522c1.936,3.735,2.213,4.565,3.735,4.841
				C30.819,110.857,33.308,110.581,33.308,110.581z"/>
			<path style="fill:#9FA6A9;" d="M311.753,100.622c-2.159-2.468-5.671-2.628-2.905-4.15c2.766-1.521,4.426-2.49,7.055-3.043
				c2.628-0.553,5.395,0.553,9.683,1.66s11.204,0.138,14.386,1.107c3.181,0.968,4.703,2.905,5.671,2.766
				c0.968-0.138,3.735-3.043,6.501-3.043c2.766,0,4.15-0.415,9.821,0.277c5.671,0.692,11.204,0.968,11.342,2.352
				c0.138,1.383,0.138,2.075,2.213,2.075c2.075,0,3.873-1.245,5.118,0.692c1.245,1.937,3.181,3.32,2.905,1.798
				c-0.277-1.521-1.66-2.766-0.553-3.32c1.107-0.553,4.288-0.83,5.118-0.553c0.83,0.277,3.458,0.692,4.288,0.553
				c0.83-0.138,0.277-1.522,3.043-0.692c2.766,0.83,3.181,2.905,3.735,3.043c0.553,0.138,1.798-2.127,1.936-3.622
				c0.138-1.496,1.383-1.496-1.106-3.432c-2.49-1.937-0.241-4.288,1.539-3.458c1.78,0.83,0.674,1.937,2.472,3.735
				c1.798,1.798,2.075,3.32,3.32,2.766c1.245-0.553,0.553-2.905,2.213-1.107c1.66,1.798,2.075,0.892,3.596,0.584
				c1.522-0.308,0.277-1.276,2.075-0.308c1.798,0.968,4.841,2.075,2.628,3.873c-2.213,1.798-5.533,1.522-7.884,2.905
				c-2.352,1.383-1.66,0.968-2.352,2.628c-0.692,1.66-0.415,1.798-3.181,2.49c-2.767,0.692-3.596,0.138-3.458,2.49
				c0.138,2.352-0.138,2.628-1.107,3.873c-0.968,1.245-0.415,2.352,0.692,2.905c1.107,0.553,2.351-0.415,2.351,1.245
				c0,1.66,2.075,2.905,4.841,2.905c2.766,0,4.011,0.692,5.671,2.766c1.66,2.075-0.692,2.213,2.075,4.841
				c2.766,2.628,5.395,3.873,5.81,1.522c0.415-2.351,0-2.905,0.415-5.671c0.415-2.766,3.043-3.043,1.66-5.533
				c-1.383-2.49-3.458-3.596-1.522-4.98c1.937-1.383-0.415-2.661,2.905-3.336c3.32-0.675,5.533-1.229,6.916,1.261
				c1.383,2.49-0.138,4.15,1.245,5.256c1.383,1.107,5.671-0.553,6.501-1.521c0.83-0.968,3.181-0.83,4.011,2.766
				s1.383,4.565,2.628,4.98c1.245,0.415,1.245,1.245,2.767,2.628c1.521,1.383,4.426,3.596,0,4.011
				c-4.426,0.415-8.023-0.138-9.683,1.383c-1.66,1.521-1.798,0.415-0.692,2.49c1.107,2.075,0.277,0.692,1.107,3.32
				c0.83,2.628,4.98,2.628,1.936,4.426s-2.49,2.213-4.288,1.66s-2.213-4.525-4.288-1.294c-2.075,3.231-1.798,4.476-3.596,5.444
				c-1.798,0.968-1.937-0.968-3.596,2.075c-1.66,3.043,0.968,3.32-2.766,6.778c-3.735,3.458-5.533,1.798-5.81,4.703
				c-0.277,2.905,1.522,6.363,0.553,7.055c-0.968,0.692-0.83,0.553-1.936-1.383c-1.107-1.937-0.553-3.043-4.426-3.32
				c-3.873-0.277-3.596-1.245-5.118,0.415s0.553,2.213-3.735,1.66c-4.288-0.553-4.841-5.671-5.671,0.277
				c-0.83,5.948-0.968,6.639,0.415,8.299s5.118,5.671,6.778,3.873c1.66-1.798-0.415-4.011,2.767-3.32
				c3.181,0.692,4.011-0.138,3.458,1.66c-0.553,1.798-4.841,2.075-1.383,3.735c3.458,1.66,6.363-0.277,6.639,2.352
				c0.277,2.628-0.415,3.32-0.277,4.841c0.138,1.521,0.83,2.628,4.011,2.628c3.181,0,3.043-1.245,3.873,0.415
				c0.83,1.66,3.458,0.83,4.565-1.522s1.937-4.98,3.596-2.213c1.66,2.766,1.936,3.32,3.596,2.213c1.66-1.107,1.66-1.107,1.66-1.107
				s1.798-1.66,3.32-0.277c1.522,1.383,4.011-0.138,4.565,1.798c0.553,1.936-0.277,2.213,2.075,2.905
				c2.352,0.692,5.118-0.415,6.501,1.521c1.383,1.937-2.352,0.692,1.66,3.043c4.011,2.352,5.256,2.628,4.98,4.15
				c-0.277,1.522,0.83,1.798,4.426,2.628c3.596,0.83,3.043,0.415,5.948,2.628c2.905,2.213,5.256,1.245,6.225,2.352
				c0.968,1.107,2.628,1.383,1.245,4.426c-1.383,3.043-2.766,2.352-3.873,4.703c-1.107,2.352-2.075,2.905-1.798,7.055
				c0.277,4.15,0.415,3.735-0.415,5.81c-0.83,2.075-3.873,2.351-6.363,3.873c-2.49,1.522-2.628,0.277-4.288,4.426
				c-1.66,4.15-0.692,1.937-2.075,5.533c-1.383,3.596,0.553,1.798-3.458,3.873c-4.011,2.075-5.533,0.277-5.948,1.522
				c-0.415,1.245,2.352,0.277-0.553,2.905c-2.905,2.628-4.15,3.32-5.948,4.426s-2.905,2.352-2.49,4.703
				c0.415,2.351,2.49,2.213,0.138,4.426c-2.352,2.213-3.32,0-4.011,3.596c-0.692,3.596-1.383,1.937-0.692,5.671
				c0.692,3.735,4.288,3.735,0.968,5.81c-3.32,2.075-6.916,3.043-8.991-1.936c-2.075-4.98-2.075-6.916-2.075-10.236
				c0-3.32-0.415-0.83,1.245-6.501c1.66-5.671-1.107-3.735,0.277-9.959c1.383-6.225,1.107-12.864,2.49-17.429
				c1.383-4.565,4.426-3.32,1.383-7.469c-3.043-4.15-3.596-3.458-6.224-6.225c-2.628-2.766-0.968-2.213-2.49-5.533
				c-1.522-3.32-2.49-3.043-3.735-5.533c-1.245-2.49-1.937-1.66-0.692-5.809c1.245-4.15-1.521-4.011,1.245-5.256
				c2.766-1.245,2.352-4.288,1.937-5.395c-0.415-1.107,2.075-1.522-1.798-2.075c-3.873-0.553-4.98,0.692-6.363-1.66
				c-1.383-2.352-0.277-2.766-3.181-4.15c-2.905-1.383-6.086-1.383-7.193-2.49c-1.107-1.107-1.107-1.107-1.107-1.107
				s2.075-0.83-2.628-1.383c-4.703-0.553-8.161,0-10.513-3.32c-2.352-3.32-1.798-1.383-2.352-4.426
				c-0.553-3.043-1.245-3.043-3.458-4.98c-2.213-1.936-2.213-3.043-4.288-3.873c-2.075-0.83-2.49-0.553-2.49-0.138
				c0,0.415-0.138,2.075,1.798,3.596c1.937,1.522,2.075,2.905,2.075,3.596c0,0.692-2.628,1.522-2.628,1.522s0-0.83-0.83-2.766
				c-0.83-1.937-1.107-2.905-2.767-5.256s-2.075-2.628-2.075-2.628s-1.937-3.043-5.256-7.054c-3.32-4.011-4.288-1.522-4.288-6.086
				c0-4.565,0.83-4.565,0-7.746c-0.83-3.181-2.766-4.703-1.107-5.256c1.66-0.553,2.628-1.383-0.415-4.011
				c-3.043-2.628-4.15-1.245-5.256-4.841c-1.107-3.596,1.245-3.32-3.32-4.98c-4.565-1.66-5.118-3.043-7.193-4.011
				s-3.32-1.521-3.32-1.521s0.415-0.415-4.565-0.83c-4.98-0.415-4.426-2.766-8.161-1.245c-3.735,1.522-4.288,1.798-4.288,1.798
				s2.075-2.49-1.521-2.213c-3.596,0.277-2.767,1.107-2.905,2.767c-0.138,1.66,0.415,3.596-2.075,4.565
				c-2.49,0.968-6.086,3.596-4.703,0.83c1.383-2.767,4.841-4.703,3.596-5.395c-1.245-0.692-3.873,1.521-4.15-0.692
				c-0.277-2.213,0.277-3.32-2.628-3.458c-2.905-0.138-5.256-1.383-3.043-2.352c2.213-0.968,4.288-0.553,4.98-2.905
				c0.692-2.352,5.81-2.905,0.138-2.766c-5.671,0.138-8.161,0.138-7.469-0.968c0.692-1.107,0.415-3.181,4.426-2.628
				C312.721,103.388,312.721,101.728,311.753,100.622z"/>
		</g>
		<g>
			<path style="fill:#CECECF;" d="M84.902,218.494c-0.609-1.369-1.369-2.51-2.358-3.423c-1.065-0.913-2.206-1.597-3.576-2.13
				c-1.293-0.457-2.739-0.684-4.184-0.684c-1.522,0-2.967,0.228-4.26,0.684c-0.152,0.076-0.38,0.152-0.533,0.229
				c-1.065,0.533-2.13,1.14-2.967,1.902c-1.065,0.913-1.826,2.054-2.435,3.423c-0.609,1.294-0.913,2.891-0.913,4.565v15.368
				c0,1.75,0.304,3.271,0.913,4.64c0.608,1.37,1.369,2.435,2.435,3.424c0.989,0.913,2.206,1.597,3.499,2.053
				c1.293,0.457,2.739,0.685,4.26,0.685c0.685,0,1.446-0.076,2.13-0.152c0.685-0.152,1.445-0.305,2.054-0.533
				c1.37-0.456,2.511-1.14,3.576-2.053c0.989-0.989,1.75-2.054,2.358-3.424c0.609-1.369,0.913-2.89,0.913-4.64v-15.368
				C85.815,221.385,85.511,219.787,84.902,218.494z M79.196,238.351c0,1.597-0.456,2.738-1.217,3.652
				c-0.761,0.761-1.674,1.216-2.815,1.293h-0.38c-1.369,0-2.435-0.457-3.271-1.293c-0.761-0.914-1.217-2.054-1.217-3.652v-15.139
				c0-0.305,0-0.533,0.076-0.761c0-0.076,0.076-0.152,0.076-0.152c0.076-1.14,0.456-1.978,1.065-2.739
				c0.152-0.151,0.304-0.304,0.456-0.38c0.761-0.685,1.674-0.914,2.815-0.914c1.294,0,2.358,0.381,3.196,1.294
				c0.761,0.913,1.217,2.13,1.217,3.652V238.351z"/>
			<path style="fill:#CECECF;" d="M102.552,212.559l-7.38,6.467v7.151l7.38-6.466v29.213h6.695v-36.365H102.552z"/>
			<path style="fill:#CECECF;" d="M121.495,212.559v11.716h6.315v-5.706h9.661l-11.868,30.355h7.227l11.792-30.355v-6.01H121.495z"
				/>
			<path style="fill:#CECECF;" d="M172.011,218.494c-0.609-1.369-1.369-2.51-2.358-3.423c-1.065-0.913-2.206-1.597-3.576-2.13
				c-1.369-0.457-2.739-0.684-4.184-0.684c-1.522,0-2.967,0.228-4.26,0.684c-1.369,0.533-2.511,1.217-3.5,2.13
				c-1.065,0.913-1.902,2.054-2.435,3.423c-0.608,1.294-0.913,2.891-0.913,4.565v15.368c0,1.75,0.305,3.271,0.913,4.64
				c0.533,1.37,1.37,2.435,2.435,3.424c0.989,0.913,2.13,1.597,3.5,2.053c1.293,0.457,2.739,0.685,4.26,0.685
				c1.445,0,2.815-0.228,4.184-0.685c1.369-0.456,2.511-1.14,3.576-2.053c0.989-0.989,1.75-2.054,2.358-3.424
				c0.608-1.369,0.913-2.89,0.913-4.64v-15.368C172.923,221.385,172.619,219.787,172.011,218.494z M166.229,238.351
				c0,1.597-0.38,2.738-1.141,3.652c-0.837,0.836-1.902,1.293-3.195,1.293c-1.369,0-2.434-0.457-3.271-1.293
				c-0.76-0.914-1.217-2.054-1.217-3.652v-15.139c0-1.522,0.457-2.74,1.217-3.652c0.837-0.913,1.902-1.294,3.271-1.294
				c1.293,0,2.358,0.381,3.195,1.294c0.761,0.913,1.141,2.13,1.141,3.652V238.351z"/>
			<path style="fill:#CECECF;" d="M227.699,233.405c-0.456-1.369-1.217-2.51-2.13-3.423c-0.913-0.913-1.902-1.597-3.043-2.13
				c-1.141-0.456-2.359-0.685-3.576-0.685c-0.456,0-0.913,0-1.369,0.076c-0.456,0.076-0.913,0.152-1.293,0.38l2.51-4.944
				l5.021-10.119h-7.227l-0.761,1.522v0.076l-0.913,1.826l-6.01,12.324c-0.076,0.152-0.152,0.305-0.228,0.457
				c-0.761,1.521-1.369,2.966-1.902,4.412c-0.609,1.521-0.913,3.196-0.913,4.869c0,1.75,0.228,3.347,0.837,4.792
				c0.609,1.37,1.369,2.587,2.435,3.5c0.761,0.761,1.521,1.294,2.51,1.75h0.076c0.305,0.152,0.685,0.305,0.989,0.456
				c1.369,0.457,2.891,0.685,4.489,0.685c1.597,0,3.043-0.228,4.412-0.76c1.445-0.457,2.587-1.217,3.652-2.207l0.076-0.076
				c0.989-0.989,1.75-2.053,2.282-3.499c0.609-1.37,0.913-2.967,0.913-4.793C228.536,236.22,228.232,234.774,227.699,233.405z
				 M217.201,232.645c1.445,0,2.586,0.456,3.424,1.445c0.837,0.913,1.293,2.283,1.293,3.88c0,1.673-0.456,3.042-1.369,3.956
				c-0.913,0.912-1.978,1.369-3.348,1.369c-1.369,0-2.511-0.457-3.424-1.369c-0.837-0.914-1.293-2.283-1.293-3.956
				c0-1.674,0.456-3.043,1.293-3.956C214.69,233.101,215.831,232.645,217.201,232.645z"/>
			<path style="fill:#CECECF;" d="M257.065,231.579c-0.304-1.597-1.218-3.195-2.663-4.563c-0.761-0.762-1.673-1.37-2.815-1.827
				c-1.141-0.456-2.434-0.684-4.032-0.684c-1.217,0-2.282,0.229-3.271,0.609c-0.989,0.38-1.674,0.76-2.13,1.217v-7.76h14.683v-6.01
				h-20.769v20.541h6.086c0.228-0.684,0.685-1.37,1.369-1.978c0.456-0.456,0.989-0.761,1.75-0.837
				c0.38-0.152,0.837-0.152,1.293-0.152c1.673,0,2.815,0.609,3.423,1.673c0.685,1.141,1.065,2.74,1.065,4.869
				c0,1.141-0.152,2.206-0.305,3.196c0,0.152-0.076,0.228-0.152,0.305c0,0.076,0,0.076,0,0.076c-0.228,0.76-0.532,1.369-0.989,1.826
				c-0.837,0.836-1.826,1.217-3.195,1.217c-1.369,0-2.435-0.381-3.119-1.217c-0.685-0.762-1.141-1.826-1.293-3.272h-6.695
				c0.152,1.294,0.38,2.586,0.761,3.88c0.38,1.37,1.065,2.51,2.206,3.652c0.761,0.837,1.902,1.446,3.272,2.054
				c1.293,0.608,2.967,0.837,4.869,0.837c0.685,0,1.217,0,1.75-0.152h0.076c1.141-0.076,2.13-0.305,3.043-0.684
				c1.293-0.608,2.358-1.294,3.195-2.054c1.294-1.37,2.13-2.815,2.587-4.336c0.38-1.522,0.609-3.272,0.609-5.326
				C257.674,234.927,257.445,233.253,257.065,231.579z"/>
			<path style="fill:#CECECF;" d="M263.988,212.559v11.716h6.315v-5.706h9.662l-11.792,30.355h7.151l11.868-30.355v-6.01H263.988z"
				/>
			<path style="fill:#CECECF;" d="M314.883,218.722c-0.533-1.445-1.369-2.586-2.358-3.575c-1.065-0.913-2.206-1.674-3.651-2.13
				c-1.369-0.533-2.815-0.761-4.413-0.761c-1.597,0-3.119,0.228-4.488,0.761c-1.369,0.533-2.587,1.293-3.652,2.206
				c-0.989,0.988-1.75,2.207-2.358,3.575c-0.608,1.445-0.913,3.043-0.913,4.793c0,1.75,0.305,3.272,0.837,4.564
				c0.533,1.294,1.294,2.435,2.13,3.347c0.913,0.914,1.978,1.674,3.119,2.13c1.141,0.533,2.358,0.762,3.576,0.762
				c0.456,0,0.913,0,1.369-0.076c0.456-0.076,0.913-0.229,1.293-0.381l-7.532,14.987h7.227l7.684-15.671
				c0.837-1.674,1.522-3.348,2.13-4.869c0.609-1.597,0.913-3.195,0.913-4.869C315.796,221.689,315.492,220.091,314.883,218.722z
				 M307.808,227.471c-0.913,0.913-1.978,1.37-3.347,1.37c-1.445,0-2.586-0.457-3.424-1.37c-0.913-0.989-1.293-2.283-1.293-3.88
				c0-1.75,0.456-3.043,1.293-3.956c0.913-0.913,2.054-1.37,3.424-1.37c1.369,0,2.434,0.457,3.347,1.37s1.293,2.206,1.293,3.956
				C309.102,225.265,308.721,226.558,307.808,227.471z"/>
			<path style="fill:#CECECF;" d="M358.476,212.559l-7.456,6.467v7.151l7.456-6.466v29.213h6.619v-36.365H358.476z"/>
			<path style="fill:#CECECF;" d="M377.343,212.559v11.716h6.39v-5.706h9.586l-11.792,30.355h7.227l11.792-30.355v-6.01H377.343z"/>
			<path style="fill:#CECECF;" d="M416.523,212.559l-7.38,6.467v7.151l7.38-6.466v29.213h6.694v-36.365H416.523z"/>
			<path style="fill:#CECECF;" d="M457.3,218.722c-0.533-1.445-1.369-2.586-2.358-3.575c-1.065-0.913-2.207-1.674-3.652-2.13
				c-1.369-0.533-2.815-0.761-4.412-0.761c-1.598,0-3.119,0.228-4.489,0.761c-1.369,0.533-2.586,1.293-3.652,2.206
				c-0.989,0.988-1.826,2.207-2.358,3.575c-0.609,1.445-0.913,3.043-0.913,4.793c0,1.75,0.304,3.272,0.837,4.564
				c0.533,1.294,1.293,2.435,2.13,3.347c0.913,0.914,1.902,1.674,3.119,2.13c1.141,0.533,2.358,0.762,3.575,0.762
				c0.456,0,0.913,0,1.369-0.076c0.457-0.076,0.913-0.229,1.294-0.381l-7.532,14.987h7.227l7.684-15.671
				c0.837-1.674,1.522-3.348,2.13-4.869c0.608-1.597,0.913-3.195,0.913-4.869C458.213,221.689,457.909,220.091,457.3,218.722z
				 M450.225,227.471c-0.913,0.913-1.978,1.37-3.347,1.37c-1.445,0-2.587-0.457-3.424-1.37c-0.913-0.989-1.293-2.283-1.293-3.88
				c0-1.75,0.456-3.043,1.293-3.956c0.913-0.913,2.054-1.37,3.424-1.37c1.369,0,2.434,0.457,3.347,1.37s1.293,2.206,1.293,3.956
				C451.518,225.265,451.138,226.558,450.225,227.471z"/>
		</g>
		<g>
			<path style="fill:#CECECF;" d="M323.404,308.341c-0.456-0.989-1.065-1.826-1.75-2.511c-0.761-0.684-1.598-1.217-2.587-1.521
				c-0.989-0.381-2.054-0.533-3.119-0.533c-1.141,0-2.13,0.152-3.119,0.533c-0.989,0.305-1.826,0.837-2.587,1.521
				c-0.761,0.685-1.369,1.522-1.826,2.511c-0.38,0.989-0.609,2.13-0.609,3.347v11.259c0,1.294,0.228,2.435,0.609,3.424
				c0.457,0.989,1.065,1.826,1.826,2.51c0.761,0.685,1.598,1.217,2.587,1.521c0.989,0.381,1.978,0.533,3.119,0.533
				c1.065,0,2.13-0.152,3.119-0.533c0.989-0.304,1.826-0.836,2.587-1.521c0.685-0.684,1.293-1.521,1.75-2.51
				c0.457-0.989,0.609-2.13,0.609-3.424v-11.259C324.013,310.472,323.861,309.331,323.404,308.341z M319.144,322.948
				c0,1.141-0.304,1.978-0.837,2.663c-0.609,0.608-1.369,0.912-2.358,0.912c-0.989,0-1.75-0.304-2.358-0.912
				c-0.609-0.685-0.913-1.522-0.913-2.663v-11.183c0-1.064,0.304-1.978,0.913-2.663c0.609-0.608,1.369-0.913,2.358-0.913
				c0.989,0,1.75,0.305,2.358,0.913c0.533,0.685,0.837,1.598,0.837,2.663V322.948z"/>
			<path style="fill:#CECECF;" d="M345.163,318.003c-0.229-1.217-0.913-2.359-1.978-3.424c-0.533-0.532-1.217-0.989-2.054-1.294
				c-0.837-0.38-1.826-0.532-2.967-0.532c-0.837,0-1.673,0.152-2.358,0.456c-0.761,0.228-1.293,0.533-1.597,0.914v-5.782h10.803
				v-4.337h-15.292v15.063h4.489c0.152-0.532,0.533-0.988,0.989-1.445c0.532-0.457,1.217-0.76,2.282-0.76
				c1.141,0,1.978,0.456,2.51,1.294c0.457,0.836,0.685,1.978,0.685,3.575c0,0.837,0,1.597-0.152,2.283
				c-0.152,0.684-0.456,1.217-0.837,1.673c-0.609,0.608-1.369,0.836-2.358,0.836c-0.989,0-1.826-0.228-2.282-0.836
				c-0.533-0.609-0.837-1.37-0.989-2.435h-4.869c0.076,0.989,0.228,1.902,0.533,2.891c0.304,0.913,0.837,1.826,1.674,2.662
				c0.532,0.533,1.369,1.065,2.358,1.521c0.989,0.381,2.206,0.609,3.575,0.609s2.587-0.228,3.576-0.609
				c0.913-0.456,1.75-0.988,2.358-1.597c0.913-0.912,1.597-1.978,1.902-3.118c0.304-1.065,0.456-2.435,0.456-3.88
				C345.619,320.438,345.467,319.22,345.163,318.003z"/>
			<path style="fill:#CECECF;" d="M359.084,301.191l-10.727,32.333h4.564l10.727-32.333H359.084z"/>
			<path style="fill:#CECECF;" d="M373.767,304.004l-5.477,4.718v5.249l5.477-4.717v21.453h4.869v-26.703H373.767z"/>
			<path style="fill:#CECECF;" d="M395.069,304.004l-5.478,4.718v5.249l5.478-4.717v21.453h4.869v-26.703H395.069z"/>
			<path style="fill:#CECECF;" d="M409.296,317.699v4.564h11.563v-4.564H409.296z"/>
			<path style="fill:#CECECF;" d="M441.705,308.341c-0.456-0.989-1.065-1.826-1.826-2.511c-0.684-0.684-1.597-1.217-2.586-1.521
				c-0.989-0.381-1.978-0.533-3.043-0.533c-1.141,0-2.13,0.152-3.119,0.533c-0.989,0.305-1.826,0.837-2.587,1.521
				c-0.761,0.685-1.369,1.522-1.826,2.511c-0.456,0.989-0.609,2.13-0.609,3.347v11.259c0,1.294,0.152,2.435,0.609,3.424
				c0.456,0.989,1.065,1.826,1.826,2.51c0.761,0.685,1.598,1.217,2.587,1.521c0.989,0.381,1.978,0.533,3.119,0.533
				c1.065,0,2.054-0.152,3.043-0.533c0.989-0.304,1.902-0.836,2.586-1.521c0.761-0.684,1.37-1.521,1.826-2.51
				c0.38-0.989,0.608-2.13,0.608-3.424v-11.259C442.313,310.472,442.085,309.331,441.705,308.341z M437.444,322.948
				c0,1.141-0.305,1.978-0.913,2.663c-0.532,0.608-1.369,0.912-2.282,0.912c-0.989,0-1.826-0.304-2.358-0.912
				c-0.609-0.685-0.913-1.522-0.913-2.663v-11.183c0-1.064,0.304-1.978,0.913-2.663c0.533-0.608,1.369-0.913,2.358-0.913
				c0.913,0,1.75,0.305,2.282,0.913c0.609,0.685,0.913,1.598,0.913,2.663V322.948z"/>
			<path style="fill:#CECECF;" d="M463.006,308.341c-0.456-0.989-1.065-1.826-1.75-2.511c-0.761-0.684-1.597-1.217-2.586-1.521
				c-0.989-0.381-2.054-0.533-3.12-0.533c-1.141,0-2.13,0.152-3.119,0.533c-0.989,0.305-1.826,0.837-2.587,1.521
				c-0.76,0.685-1.369,1.522-1.826,2.511c-0.38,0.989-0.608,2.13-0.608,3.347v11.259c0,1.294,0.228,2.435,0.608,3.424
				c0.456,0.989,1.065,1.826,1.826,2.51c0.761,0.685,1.598,1.217,2.587,1.521c0.989,0.381,1.978,0.533,3.119,0.533
				c1.065,0,2.13-0.152,3.12-0.533c0.989-0.304,1.826-0.836,2.586-1.521c0.685-0.684,1.294-1.521,1.75-2.51
				c0.456-0.989,0.608-2.13,0.608-3.424v-11.259C463.615,310.472,463.463,309.331,463.006,308.341z M458.746,322.948
				c0,1.141-0.305,1.978-0.837,2.663c-0.608,0.608-1.369,0.912-2.358,0.912c-0.988,0-1.75-0.304-2.358-0.912
				c-0.608-0.685-0.913-1.522-0.913-2.663v-11.183c0-1.064,0.305-1.978,0.913-2.663c0.609-0.608,1.37-0.913,2.358-0.913
				c0.989,0,1.75,0.305,2.358,0.913c0.533,0.685,0.837,1.598,0.837,2.663V322.948z"/>
		</g>
		<g>
			<path style="fill:#CECECF;" d="M67.48,258.282v2.435h3.652v11.488h2.739v-11.488h3.652v-2.435H67.48z"/>
			<path style="fill:#CECECF;" d="M89.847,261.934c-0.152-0.456-0.229-0.836-0.456-1.217c-0.152-0.381-0.457-0.762-0.837-1.141
				c-0.456-0.456-0.989-0.837-1.597-1.065c-0.609-0.228-1.294-0.381-2.054-0.381c-0.837,0-1.522,0.152-2.13,0.381
				c-0.608,0.228-1.141,0.609-1.597,1.065c-0.38,0.38-0.685,0.761-0.837,1.141c-0.229,0.381-0.305,0.761-0.456,1.217
				c-0.076,0.457-0.076,0.99-0.152,1.522v3.575c0.076,0.532,0.076,0.989,0.152,1.446c0.152,0.456,0.228,0.913,0.456,1.293
				c0.152,0.305,0.456,0.685,0.837,1.065c0.456,0.457,0.989,0.837,1.597,1.065c0.609,0.305,1.294,0.381,2.13,0.381
				c0.761,0,1.446-0.076,2.054-0.381c0.608-0.228,1.141-0.608,1.597-1.065c0.38-0.38,0.685-0.761,0.837-1.065
				c0.228-0.38,0.304-0.837,0.456-1.293c0.076-0.457,0.152-0.914,0.152-1.446v-3.575C89.999,262.923,89.923,262.391,89.847,261.934z
				 M87.26,266.88c0,0.456-0.076,0.76-0.152,1.064c0,0.305-0.076,0.533-0.152,0.685c-0.076,0.152-0.229,0.304-0.381,0.456
				c-0.152,0.229-0.38,0.456-0.684,0.532c-0.304,0.152-0.609,0.228-0.989,0.228s-0.76-0.076-1.065-0.228
				c-0.228-0.076-0.533-0.304-0.76-0.532c-0.076-0.152-0.229-0.304-0.305-0.456c-0.076-0.152-0.152-0.381-0.152-0.685
				c-0.076-0.305-0.152-0.608-0.152-1.064v-3.348c0-0.38,0.076-0.761,0.152-1.065c0-0.305,0.076-0.533,0.152-0.684
				c0.076-0.152,0.228-0.305,0.305-0.457c0.228-0.228,0.533-0.38,0.76-0.532c0.305-0.152,0.685-0.228,1.065-0.228
				s0.685,0.076,0.989,0.228c0.304,0.152,0.533,0.305,0.684,0.532c0.152,0.152,0.305,0.305,0.381,0.457
				c0.076,0.151,0.152,0.38,0.152,0.684c0.076,0.305,0.152,0.685,0.152,1.065V266.88z"/>
			<path style="fill:#CECECF;" d="M102.932,260.945c-0.152-0.533-0.456-0.99-0.913-1.37c-0.38-0.456-0.837-0.76-1.445-0.989
				c-0.533-0.229-1.217-0.305-1.902-0.305H93.27v13.923h2.739v-5.249h2.662c0.685,0,1.369-0.152,1.902-0.381
				c0.609-0.229,1.065-0.533,1.445-0.914c0.457-0.38,0.761-0.836,0.913-1.369c0.229-0.533,0.304-1.14,0.304-1.673
				C103.236,262.01,103.161,261.478,102.932,260.945z M100.041,263.988c-0.38,0.305-0.913,0.533-1.522,0.533h-2.51v-3.804h2.51
				c0.609,0,1.141,0.152,1.522,0.457c0.304,0.38,0.533,0.836,0.533,1.445C100.574,263.152,100.345,263.608,100.041,263.988z"/>
			<path style="fill:#CECECF;" d="M115.181,260.717v-2.435h-9.129v13.923h9.129v-2.435h-6.39v-3.423h5.477v-2.435h-5.477v-3.195
				H115.181z"/>
			<path style="fill:#CECECF;" d="M124.614,269.314c-0.38,0.38-0.913,0.532-1.597,0.532c-0.38,0-0.685-0.076-0.989-0.152
				c-0.304-0.152-0.533-0.304-0.761-0.532c-0.076-0.152-0.228-0.304-0.304-0.532c-0.076-0.152-0.152-0.381-0.228-0.685
				c0-0.305-0.076-0.608-0.076-1.064c0-0.457-0.076-0.99-0.076-1.674c0-0.684,0.076-1.217,0.076-1.673
				c0-0.38,0.076-0.761,0.076-1.065c0.076-0.305,0.152-0.533,0.228-0.684c0.076-0.152,0.228-0.381,0.304-0.457
				c0.228-0.228,0.457-0.456,0.761-0.532c0.304-0.152,0.609-0.228,0.989-0.228c0.684,0,1.217,0.152,1.597,0.533
				c0.381,0.38,0.609,0.836,0.761,1.445h2.739c-0.228-1.445-0.837-2.587-1.75-3.271c-0.913-0.762-2.054-1.142-3.347-1.142
				c-0.761,0-1.522,0.152-2.13,0.381c-0.533,0.228-1.141,0.609-1.597,1.065c-0.305,0.38-0.609,0.761-0.837,1.141
				c-0.152,0.381-0.304,0.761-0.38,1.217c-0.076,0.457-0.152,0.99-0.152,1.522v3.575c0,0.532,0.076,0.989,0.152,1.446
				c0.076,0.456,0.229,0.913,0.38,1.293c0.229,0.305,0.533,0.685,0.837,1.065c0.456,0.457,1.065,0.837,1.597,1.065
				c0.609,0.305,1.369,0.381,2.13,0.381c1.293,0,2.435-0.381,3.347-1.065c0.913-0.761,1.522-1.826,1.75-3.348h-2.739
				C125.223,268.477,124.995,268.933,124.614,269.314z"/>
			<path style="fill:#CECECF;" d="M140.743,263.456c0-0.532-0.076-1.065-0.152-1.522c-0.076-0.456-0.229-0.836-0.456-1.217
				c-0.152-0.381-0.456-0.762-0.761-1.141c-0.456-0.456-0.989-0.837-1.597-1.065c-0.609-0.228-1.294-0.381-2.13-0.381
				c-0.837,0-1.522,0.152-2.13,0.381c-0.609,0.228-1.141,0.609-1.598,1.065c-0.38,0.38-0.609,0.761-0.837,1.141
				c-0.152,0.381-0.305,0.761-0.38,1.217c-0.076,0.457-0.152,0.99-0.152,1.522c0,0.532-0.076,1.065-0.076,1.75
				c0,0.684,0.076,1.294,0.076,1.826c0,0.532,0.076,0.989,0.152,1.446c0.076,0.456,0.228,0.913,0.38,1.293
				c0.228,0.305,0.456,0.685,0.837,1.065c0.456,0.457,0.989,0.837,1.598,1.065c0.608,0.305,1.293,0.381,2.13,0.381
				c0.837,0,1.522-0.076,2.13-0.381c0.608-0.228,1.141-0.608,1.597-1.065c0.305-0.38,0.609-0.761,0.761-1.065
				c0.228-0.38,0.38-0.837,0.456-1.293c0.076-0.457,0.152-0.914,0.152-1.446c0-0.532,0.076-1.141,0.076-1.826
				C140.819,264.521,140.743,263.988,140.743,263.456z M138.004,266.88c0,0.456-0.076,0.76-0.076,1.064
				c-0.076,0.305-0.152,0.533-0.228,0.685c-0.076,0.152-0.152,0.304-0.304,0.456c-0.229,0.229-0.456,0.456-0.761,0.532
				c-0.304,0.152-0.609,0.228-0.989,0.228c-0.38,0-0.684-0.076-0.989-0.228c-0.304-0.076-0.533-0.304-0.761-0.532
				c-0.152-0.152-0.228-0.304-0.304-0.456c-0.152-0.152-0.152-0.381-0.229-0.685c-0.076-0.305-0.076-0.608-0.152-1.064v-3.348
				c0.076-0.38,0.076-0.761,0.152-1.065c0.076-0.305,0.076-0.533,0.229-0.684c0.076-0.152,0.152-0.305,0.304-0.457
				c0.228-0.228,0.456-0.38,0.761-0.532c0.305-0.152,0.609-0.228,0.989-0.228c0.38,0,0.685,0.076,0.989,0.228
				c0.305,0.152,0.533,0.305,0.761,0.532c0.152,0.152,0.228,0.305,0.304,0.457c0.076,0.151,0.152,0.38,0.228,0.684
				c0,0.305,0.076,0.685,0.076,1.065c0.076,0.456,0.076,0.989,0.076,1.673C138.08,265.89,138.08,266.423,138.004,266.88z"/>
			<path style="fill:#CECECF;" d="M152.002,258.282v8.521l-5.553-8.521h-2.435v13.923h2.739v-8.597l5.553,8.597h2.434v-13.923
				H152.002z"/>
			<path style="fill:#CECECF;" d="M173.38,266.88c-0.152-0.381-0.38-0.762-0.685-1.066c-0.152-0.152-0.304-0.304-0.456-0.38
				c-0.152-0.152-0.304-0.228-0.608-0.38c1.141-0.685,1.75-1.598,1.75-2.968c0-0.608-0.152-1.141-0.304-1.597
				c-0.228-0.457-0.533-0.837-0.913-1.216c-0.304-0.229-0.532-0.381-0.913-0.533c-0.076-0.076-0.228-0.152-0.38-0.228
				c-0.609-0.152-1.141-0.228-1.826-0.228h-5.553v13.923h5.782c0.684,0,1.293-0.152,1.75-0.305c0.533-0.228,0.989-0.457,1.369-0.837
				c0.38-0.304,0.685-0.761,0.837-1.217c0.228-0.456,0.305-1.064,0.305-1.673C173.532,267.716,173.456,267.259,173.38,266.88z
				 M166.152,260.717h2.663c0.152,0,0.305,0,0.381,0.076c0.456,0,0.684,0.076,0.989,0.305c0.304,0.304,0.456,0.685,0.456,1.217
				c0,0.457-0.152,0.838-0.456,1.141c-0.381,0.304-0.761,0.456-1.37,0.456h-2.663V260.717z M170.337,269.314
				c-0.304,0.304-0.761,0.456-1.293,0.456h-2.891v-3.423h2.891c0.533,0,0.989,0.152,1.293,0.457
				c0.305,0.304,0.533,0.685,0.533,1.217C170.869,268.553,170.641,269.009,170.337,269.314z"/>
			<path style="fill:#CECECF;" d="M182.509,258.282h-2.13l-5.097,13.923h2.815l0.837-2.511h4.945l0.837,2.511h2.815L182.509,258.282
				z M179.694,267.412l1.826-5.021l1.674,5.021H179.694z"/>
			<path style="fill:#CECECF;" d="M197.725,258.282v8.521l-5.554-8.521h-2.358v13.923h2.663v-8.597l5.554,8.597h2.435v-13.923
				H197.725z"/>
			<path style="fill:#CECECF;" d="M210.506,263.759l4.564-5.477h-3.271l-4.945,6.01v-6.01h-2.663v13.923h2.663v-4.185l1.826-2.207
				l3.575,6.391h3.195L210.506,263.759z"/>
		</g>
		<g>
			<path style="fill:#CECECF;" d="M355.813,171.098c-0.38,0.381-0.913,0.533-1.597,0.533c-0.381,0-0.685-0.076-0.989-0.229
				c-0.305-0.076-0.533-0.305-0.761-0.457c-0.076-0.152-0.228-0.305-0.304-0.532c-0.076-0.152-0.152-0.381-0.228-0.684
				c0-0.305-0.076-0.685-0.076-1.066c0-0.456-0.076-0.988-0.076-1.673c0-0.685,0.076-1.217,0.076-1.674
				c0-0.457,0.076-0.761,0.076-1.064c0.076-0.305,0.152-0.533,0.228-0.685c0.076-0.228,0.228-0.381,0.304-0.533
				c0.228-0.227,0.456-0.38,0.761-0.532c0.304-0.076,0.608-0.151,0.989-0.151c0.684,0,1.217,0.151,1.597,0.532
				c0.38,0.38,0.609,0.837,0.761,1.445h2.739c-0.229-1.521-0.837-2.586-1.75-3.347c-0.913-0.684-2.054-1.065-3.347-1.065
				c-0.761,0-1.522,0.152-2.054,0.381c-0.609,0.228-1.141,0.608-1.674,1.064c-0.304,0.381-0.608,0.761-0.761,1.141
				c-0.228,0.305-0.38,0.76-0.456,1.217c-0.076,0.457-0.152,0.913-0.152,1.445v3.652c0,0.533,0.076,0.99,0.152,1.446
				c0.076,0.456,0.228,0.837,0.456,1.217c0.152,0.381,0.457,0.761,0.761,1.14c0.533,0.457,1.065,0.838,1.674,1.066
				c0.533,0.228,1.293,0.38,2.054,0.38c1.293,0,2.434-0.38,3.347-1.141c0.913-0.684,1.521-1.826,1.75-3.271h-2.739
				C356.422,170.261,356.193,170.717,355.813,171.098z"/>
			<path style="fill:#CECECF;" d="M369.583,167.903c0.38-0.076,0.685-0.229,0.989-0.457c0.305-0.229,0.609-0.457,0.837-0.761
				c0.228-0.305,0.381-0.684,0.533-1.065c0.076-0.229,0.152-0.533,0.152-0.837c0.076-0.152,0.076-0.381,0.076-0.532
				c0-0.533-0.152-1.142-0.305-1.598c-0.228-0.532-0.532-0.989-0.913-1.37c-0.38-0.38-0.837-0.684-1.445-0.912
				c-0.304-0.152-0.532-0.229-0.913-0.305h-0.076c-0.304-0.076-0.608-0.076-0.913-0.076h-5.401v13.922h2.663v-5.553h1.978
				l2.739,5.553h3.119L369.583,167.903z M368.899,165.62c-0.381,0.305-0.837,0.533-1.445,0.533h-2.587v-3.729h2.587
				c0.608,0,1.065,0.152,1.445,0.532c0.38,0.305,0.533,0.762,0.533,1.295C369.431,164.859,369.279,165.24,368.899,165.62z"/>
			<path style="fill:#CECECF;" d="M384.647,162.425v-2.434h-9.206v13.922h9.206v-2.434h-6.467v-3.348h5.478v-2.434h-5.478v-3.272
				H384.647z"/>
			<path style="fill:#CECECF;" d="M397.808,164.479c0-0.152-0.076-0.304-0.076-0.38c0-0.381-0.152-0.761-0.228-1.066
				c-0.152-0.38-0.305-0.684-0.533-0.988c-0.456-0.685-1.065-1.217-1.75-1.522c-0.684-0.304-1.445-0.532-2.435-0.532h-5.021v13.922
				h5.021c0.989,0,1.75-0.151,2.435-0.456c0.381-0.152,0.685-0.381,0.989-0.609c0.305-0.229,0.533-0.532,0.761-0.913
				c0.228-0.304,0.38-0.608,0.533-0.989c0.076-0.305,0.228-0.684,0.228-1.064c0.076-0.381,0.076-0.838,0.152-1.294v-3.196
				C397.808,165.087,397.808,164.784,397.808,164.479z M395.145,168.435c0,0.38-0.076,0.684-0.076,0.989
				c-0.076,0.305-0.076,0.533-0.152,0.684c-0.076,0.228-0.229,0.381-0.304,0.532c-0.456,0.609-1.141,0.838-2.054,0.838h-2.054
				v-9.054h2.054c0.913,0,1.598,0.305,2.054,0.913c0.076,0.152,0.228,0.305,0.304,0.533c0.076,0.152,0.076,0.381,0.152,0.684
				c0,0.304,0.076,0.608,0.076,0.989V168.435z"/>
			<path style="fill:#CECECF;" d="M401.155,159.991v13.922h2.663v-13.922H401.155z"/>
			<path style="fill:#CECECF;" d="M406.557,159.991v2.434h3.652v11.488h2.663v-11.488h3.652v-2.434H406.557z"/>
			<path style="fill:#CECECF;" d="M430.521,171.098c-0.38,0.381-0.913,0.533-1.598,0.533c-0.38,0-0.684-0.076-0.989-0.229
				c-0.305-0.076-0.533-0.305-0.761-0.457c-0.076-0.152-0.228-0.305-0.304-0.532c-0.076-0.152-0.152-0.381-0.228-0.684
				c0-0.305-0.076-0.685-0.076-1.066c-0.076-0.456-0.076-0.988-0.076-1.673c0-0.685,0-1.217,0.076-1.674
				c0-0.457,0.076-0.761,0.076-1.064c0.076-0.305,0.152-0.533,0.228-0.685c0.076-0.228,0.228-0.381,0.304-0.533
				c0.228-0.227,0.456-0.38,0.761-0.532c0.304-0.076,0.608-0.151,0.989-0.151c0.685,0,1.217,0.151,1.598,0.532
				c0.38,0.38,0.609,0.837,0.761,1.445h2.739c-0.228-1.521-0.837-2.586-1.75-3.347c-0.913-0.684-2.054-1.065-3.347-1.065
				c-0.761,0-1.521,0.152-2.13,0.381c-0.533,0.228-1.141,0.608-1.598,1.064c-0.38,0.381-0.608,0.761-0.837,1.141
				c-0.152,0.305-0.304,0.76-0.38,1.217c-0.076,0.457-0.152,0.913-0.152,1.445v3.652c0,0.533,0.076,0.99,0.152,1.446
				c0.076,0.456,0.228,0.837,0.38,1.217c0.228,0.381,0.456,0.761,0.837,1.14c0.456,0.457,1.065,0.838,1.598,1.066
				c0.609,0.228,1.369,0.38,2.13,0.38c1.294,0,2.435-0.38,3.347-1.141c0.913-0.684,1.522-1.826,1.75-3.271h-2.739
				C431.13,170.261,430.901,170.717,430.521,171.098z"/>
			<path style="fill:#CECECF;" d="M442.541,159.991h-2.13l-5.097,13.922h2.891l0.837-2.434h4.945l0.837,2.434h2.815L442.541,159.991
				z M439.803,169.196l1.75-5.097l1.673,5.097H439.803z"/>
			<path style="fill:#CECECF;" d="M457.224,167.903c0.38-0.076,0.684-0.229,0.989-0.457c0.304-0.229,0.609-0.457,0.837-0.761
				c0.228-0.305,0.38-0.684,0.533-1.065c0.152-0.381,0.228-0.837,0.228-1.369c0-0.533-0.076-1.142-0.304-1.598
				c-0.228-0.532-0.533-0.989-0.913-1.37c-0.38-0.38-0.837-0.684-1.445-0.912c-0.533-0.229-1.141-0.381-1.826-0.381h-5.478v13.922
				h2.739v-5.553h1.902l2.739,5.553h3.119L457.224,167.903z M456.539,165.62c-0.38,0.305-0.837,0.533-1.445,0.533h-2.51v-3.729h2.51
				c0.609,0,1.065,0.152,1.445,0.532c0.381,0.305,0.533,0.762,0.533,1.295C457.072,164.859,456.92,165.24,456.539,165.62z"/>
			<path style="fill:#CECECF;" d="M473.124,164.099c-0.076-0.381-0.152-0.761-0.304-1.066c-0.076-0.38-0.304-0.684-0.456-0.988
				c-0.533-0.685-1.141-1.217-1.826-1.522c-0.609-0.304-1.445-0.532-2.434-0.532h-5.021v13.922h5.021
				c0.989,0,1.826-0.151,2.434-0.456c0.684-0.305,1.293-0.838,1.826-1.522c0.152-0.304,0.38-0.608,0.456-0.989
				c0.152-0.305,0.228-0.684,0.304-1.064c0-0.381,0.076-0.838,0.076-1.294v-3.196C473.2,164.935,473.124,164.479,473.124,164.099z
				 M470.462,168.435c0,0.38,0,0.684-0.076,0.989c0,0.305-0.076,0.533-0.152,0.684c-0.076,0.228-0.152,0.381-0.305,0.532
				c-0.456,0.609-1.141,0.838-2.054,0.838h-2.054v-9.054h2.054c0.913,0,1.597,0.305,2.054,0.913
				c0.152,0.152,0.228,0.305,0.305,0.533c0.076,0.152,0.152,0.381,0.152,0.684c0.076,0.304,0.076,0.608,0.076,0.989V168.435z"/>
		</g>
		<g>
			<g>
				<path style="fill:#F7D072;" d="M474.622,33.917v53.619c0,2.158-1.765,3.923-3.923,3.923h-66.043
					c-2.158,0-3.923-1.765-3.923-3.923V33.917c0-2.158,1.765-3.923,3.923-3.923h66.043
					C472.856,29.993,474.622,31.759,474.622,33.917z"/>
			</g>
			<path style="opacity:0.2;fill:#FFFFFF;" d="M460.115,42.984l11.488-11.488h-3.728l-9.89,9.966l-1.521,1.522l-1.141,1.065
				l-7.836,7.836h-17.041l-1.522-1.522l-6.314-6.314l-2.511-2.587l-9.966-9.966h-3.728l9.89,9.966l1.522,1.522h-15.748v2.586h18.41
				l4.869,4.945l1.293,1.37v3.042h-24.573v2.663h24.573v9.357h-24.573v2.586h24.573v3.119h0.152L422,77.37l-1.522,1.522h-18.41
				v2.663h15.748l-9.89,9.89h3.728l9.89-9.89l8.901-8.9h17.041l18.868,18.791h3.651l-9.89-9.89h14.53v-2.663h-17.117l-6.238-6.238
				v-3.119h23.356v-2.586H451.29v-9.357h23.356v-2.663H451.29v-3.042l6.238-6.315h17.117v-2.586H460.115z M448.704,69.991h-19.476
				V54.472h19.476V69.991z"/>
			<g>
				<g>
					<path style="fill:#717070;" d="M429.152,50.363l2.587,2.586h11.868l1.065-1.064l1.522-1.522H429.152z M429.152,50.363
						l2.587,2.586h11.868l1.065-1.064l1.522-1.522H429.152z M429.152,50.363l2.587,2.586h11.868l1.065-1.064l1.522-1.522H429.152z
						 M429.152,50.363l2.587,2.586h11.868l1.065-1.064l1.522-1.522H429.152z M445.128,69.991l-1.521-1.521h-11.868l-1.445,1.521
						h-0.076l-1.065,1.141h17.041L445.128,69.991z M429.152,50.363l2.587,2.586h11.868l1.065-1.064l1.522-1.522H429.152z
						 M425.348,50.363v20.769h24.649V50.363H425.348z M447.41,68.47h-19.476V52.95h19.476V68.47z M429.152,50.363l2.587,2.586
						h11.868l1.065-1.064l1.522-1.522H429.152z M429.152,50.363l2.587,2.586h11.868l1.065-1.064l1.522-1.522H429.152z
						 M429.152,50.363l2.587,2.586h11.868l1.065-1.064l1.522-1.522H429.152z M429.152,50.363l2.587,2.586h11.868l1.065-1.064
						l1.522-1.522H429.152z M429.152,50.363l2.587,2.586h11.868l1.065-1.064l1.522-1.522H429.152z M429.152,50.363l2.587,2.586
						h11.868l1.065-1.064l1.522-1.522H429.152z"/>
				</g>
				<g>
					<g>
						<polygon style="fill:#717070;" points="420.234,41.436 422.85,44.052 400.732,44.052 400.732,41.436 						"/>
						<polygon style="fill:#717070;" points="474.622,41.436 474.622,44.052 452.504,44.052 455.119,41.436 						"/>
					</g>
					<g>
						<rect x="400.732" y="53.425" style="fill:#717070;" width="27.218" height="2.616"/>
						<rect x="447.403" y="53.425" style="fill:#717070;" width="27.218" height="2.616"/>
					</g>
					<g>
						<rect x="400.732" y="65.415" style="fill:#717070;" width="27.218" height="2.616"/>
						<rect x="447.403" y="65.415" style="fill:#717070;" width="27.218" height="2.616"/>
					</g>
					<g>
						<polygon style="fill:#717070;" points="422.853,77.4 420.237,80.016 400.732,80.016 400.732,77.4 						"/>
						<polygon style="fill:#717070;" points="474.622,77.4 474.622,80.016 455.116,80.016 452.501,77.4 						"/>
					</g>
				</g>
				<g>
					<polygon style="fill:#717070;" points="431.762,68.491 408.794,91.459 405.097,91.459 428.065,68.491 					"/>
					<polygon style="fill:#717070;" points="470.26,29.993 447.292,52.961 443.595,52.961 466.563,29.993 					"/>
				</g>
				<g>
					<polygon style="fill:#717070;" points="431.759,52.961 428.061,52.961 405.093,29.993 408.791,29.993 					"/>
					<polygon style="fill:#717070;" points="470.257,91.459 466.559,91.459 446.207,71.107 445.118,70.018 443.591,68.491 
						447.289,68.491 448.711,69.913 449.905,71.107 451.327,72.529 456.198,77.4 457.725,78.927 458.814,80.016 460.341,81.543 
											"/>
				</g>
			</g>
		</g>
		<g>
			<path style="fill:#DC3B39;" d="M79.019,53.425c0-8.186,3.376-15.58,8.806-20.878c-5.258-5.13-12.442-8.296-20.368-8.296
				c-16.113,0-29.174,13.062-29.174,29.174c0,16.113,13.062,29.174,29.174,29.174c7.927,0,15.111-3.166,20.368-8.296
				C82.395,69.006,79.019,61.611,79.019,53.425z"/>
			<path style="fill:#E6AB2D;" d="M108.193,24.251c-7.927,0-15.11,3.166-20.368,8.296c5.43,5.298,8.806,12.692,8.806,20.878
				c0,8.186-3.376,15.58-8.806,20.878c5.258,5.13,12.442,8.296,20.368,8.296c16.113,0,29.174-13.062,29.174-29.174
				C137.367,37.313,124.305,24.251,108.193,24.251z"/>
			<path style="fill:#D67261;" d="M96.631,53.425c0-8.186-3.376-15.58-8.806-20.878c-5.43,5.298-8.806,12.692-8.806,20.878
				c0,8.186,3.376,15.58,8.806,20.878C93.255,69.006,96.631,61.611,96.631,53.425z"/>
			<g>
				<g>
					<path style="fill:#E6AB2D;" d="M96.631,53.424c0-0.616-0.025-1.226-0.063-1.833L83.26,38.284
						c-0.975,1.601-1.802,3.302-2.458,5.087l15.34,15.34C96.457,56.995,96.631,55.231,96.631,53.424z"/>
				</g>
				<path style="fill:#E6AB2D;" d="M79.316,49.297c-0.191,1.349-0.297,2.726-0.297,4.127c0,1.12,0.069,2.224,0.192,3.311
					l12.653,12.653c1.018-1.551,1.891-3.204,2.602-4.942L79.316,49.297z"/>
			</g>
		</g>
		<rect y="186.085" style="opacity:0.2;fill:#040000;" width="512" height="6.391"/>
	</g>
	<path style="opacity:0.2;fill:#040000;" d="M512,16.281v315.265c0,8.977-7.38,16.356-16.357,16.356H82.087l81.403-81.402
		l2.662-2.663l3.043-3.042l2.054-2.054l42.375-42.376l2.206-2.206v-0.076l1.445-1.37l0.152-0.152l6.467-6.542l2.054-1.978
		l11.564-11.564l6.391-6.391l68.393-68.393l21.986-21.985l94.336-94.413L429.912,0h65.731C504.62,0,512,7.303,512,16.281z"/>
</g>
</svg>