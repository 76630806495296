import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CrossComponent } from '../../miscellaneous/cross/cross.component';

@Component({
  selector: 'app-info-card',
  standalone: true,
  imports: [CommonModule, CrossComponent],
  templateUrl: './info-card.component.html',
  styleUrl: './info-card.component.css'
})
export class InfoCardComponent {
  protected show: boolean = false;
  protected titles: string[] = [];
  protected texts: string[] = [];
  protected ctas: string[] = [];
  protected current: number = 0;

  public addInfos(title: string, text: string, cta: string = ""){
    this.titles.push(title);
    //sostiusci i \n con dei degli accapo e i <br> con degli accapo
    text = text.replace(/\\n/g, "\n");
    text = text.replace(/<br>/g, "\n");
    
    this.texts.push(text);
    if(cta != ""){
      this.ctas.push(cta);
    }else{
      this.ctas.push("Avanti");
    }
  }

  public open(){
    this.show = true;
  }

  public next(){
    this.current++;
    if(this.current == this.ctas.length){
      this.show = false;
    }
  }
}
