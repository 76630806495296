<div class="bg-black flex justify-center" style="background-image: url('assets/images/grunge_bg.png');">
    <h1 class="text-3xl md:text-5xl lg:text-6xl font-bold my-8 text-white">REGISTER</h1>
</div>
<div class="container mx-auto my-6 px-6 min-h-screen">
    <div *ngIf="currentState == states.LOADING">
        <div class="h-screen flex justify-center items-center">
            <ng-lottie [options]="loader" class="lg:h-[300px]"></ng-lottie>
        </div>
    </div>

    <div *ngIf="currentState == states.DEFAULT">
        
        <div class="mt-6 mx-2 text-center">
            <form [formGroup]="form" (ngSubmit)="register()">
                <div class="max-w-md mx-auto my-8">
                    <label class="block text-gray-700 text-left font-bold mb-2">Nome</label>
                    <div class="relative rounded-md shadow-lg">
                        <input type="text" id="name" name="name" placeholder="Esempio: Mario" formControlName="name"
                            class="form-input py-2 px-4 block w-full leading-5 rounded-md transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
                    </div>
                </div>
                <div class="max-w-md mx-auto my-8">
                    <label class="block text-gray-700 text-left font-bold mb-2">Cognome</label>
                    <div class="relative rounded-md shadow-lg">
                        <input type="text" id="surname" name="surname" placeholder="Esempio: Rossi"
                            formControlName="surname"
                            class="form-input py-2 px-4 block w-full leading-5 rounded-md transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
                    </div>
                </div>
                <div class="max-w-md mx-auto my-8">
                    <label class="block text-gray-700 text-left font-bold mb-2">Email</label>
                    <div class="relative rounded-md shadow-lg">
                        <input type="email" id="email" name="email" placeholder="Esempio: nome@mail.it"
                            formControlName="email"
                            class="form-input py-2 px-4 block w-full leading-5 rounded-md transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
                    </div>
                </div>
                <div class="max-w-md mx-auto my-8">
                    <label class="block text-gray-700 text-left font-bold mb-2">Password</label>
                    <div class="relative rounded-md shadow-lg">
                        <input type="password" id="password" name="password" formControlName="password"
                            class="form-input py-2 px-4 block w-full leading-5 rounded-md transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
                    </div>
                </div>
                <div class="max-w-md mx-auto my-8">
                    <label class="block text-gray-700 text-left font-bold mb-2">Ripeti Password</label>
                    <div class="relative rounded-md shadow-lg">
                        <input type="password" id="passwordConfirm" name="passwordConfirm"
                            formControlName="passwordConfirm"
                            class="form-input py-2 px-4 block w-full leading-5 rounded-md transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
                    </div>
                </div>
                <div class="grid grid-rows-1 justify-center gap-4 my-4">
                    <button type="submit"
                        class="bg-default_grey font-serif font-semibold text-white px-8 py-3">REGISTRATI</button>
                </div>
            </form>

            <div class="flex justify-center">
                <p class="mr-2">hai già un account?</p>
                <button class="underline" routerLink="/login">accedi</button>
            </div>
            <p class="my-4">oppure</p>
            <div class="max-w-md mx-auto ">
                <app-google-button (click)="loginWithGoogle()"></app-google-button>
            </div>
        </div>
    </div>

    <div *ngIf="currentState == states.VERIFICATION">
        <div class="h-screen grid justify-center items-center">
            <h2 class="text-xl md:text-2xl lg:text-3xl font-bold text-center">Ti abbiamo inviato una email di verifica</h2>
            <ng-lottie [options]="success" class="lg:h-[300px]"></ng-lottie>
        </div>
    </div>


</div>
<app-snackbar></app-snackbar>