import { Component, Input, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductCardComponent } from '../../utilities/product-card/product-card.component';
import { Product } from '../../../models/product';
import { ProductService } from '../../../services/product/product.service';
import { ProductCategory } from '../../../models/productCategory';
import { CartService } from '../../../services/cart/cart.service';
import { SnackbarComponent } from '../../utilities/snackbar/snackbar.component';
import { CrossComponent } from "../../miscellaneous/cross/cross.component";
import { AnimationOptions, LottieModule } from 'ngx-lottie';
import { AuthService } from '../../../services/auth/auth.service';
import { StringsService } from '../../../services/strings/strings.service';
import { InfoCardComponent } from '../../utilities/info-card/info-card.component';
import { getAuth } from 'firebase/auth';
import { User } from '../../../models/user';

@Component({
  selector: 'app-products',
  standalone: true,
  templateUrl: './products.component.html',
  styleUrl: './products.component.css',
  imports: [InfoCardComponent, CommonModule, ProductCardComponent, SnackbarComponent, CrossComponent, LottieModule]
})
export class ProductsComponent {
  @ViewChild(SnackbarComponent) snackbar!: SnackbarComponent;
  @ViewChild(InfoCardComponent) infoCard!: InfoCardComponent;
  protected user: User | null = null;
  protected products: Product[] = [];
  protected categories: ProductCategory[] = [];
  protected currentState: number = 0;
  protected isLoggedIn: boolean = false;
  protected states = {
    LOADING: 0,
    DEFAULT: 1,
  }
  protected loader: AnimationOptions = {
    path: 'assets/lottie/loader.json'
  };

  constructor(private authService: AuthService, private stringsService: StringsService, private productService: ProductService, private cartService: CartService) {
    this.loadData();
    this.userStatusObserver();
  }

  private async userStatusObserver() {
    this.authService.userObserver.subscribe({
      next: (user) => {
        this.user = user;
        this.isLoggedIn = user != null;
      }
    });
  }

  protected showSnackbar(message: string) {
    this.snackbar.showMessage(message);
  }

  private async loadData() {
    this.products = await this.productService.getProducts();
    this.categories = await this.productService.getProductCategories();
    this.currentState = this.states.DEFAULT;
  }

  protected showDescription(product: Product) {
    this.infoCard.addInfos("Descrizione Prodotto", product.description);
    this.infoCard.open();
  }

  protected getProducts(productCategory: ProductCategory): Product[] {
    return this.products.filter(product => product.category == productCategory.id);
  }


}
