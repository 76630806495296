<div
    class="grid grid-rows-2 rounded-3xl shadow-2xl w-[310px] h-[467px] md:w-[380px] lg:w-[442.26px] lg:h-[567px] relative">
    <div class="row-span-1">
        <div class="absolute left-5 top-5 bg-default_grey w-[130.96px] h-[37.8px] text-center rounded-md flex items-center justify-center"
            *ngIf="offer.bestSeller">
            <p class="text-white font-bold text-sm px-2">BEST SELLER</p>
        </div>
        <img [src]="offer.imageUrl" style="object-fit: cover; object-position: center; display: block;"
            class="rounded-t-3xl w-full">

    </div>
    
    <div class="row-span-1 h-full rounded-b-3xl bg-white flex flex-col justify-end items-center space-y-4 text-center pb-4">
        <h1 class="text-xl md:text-2xl lg:text-3xl font-bold mb-4">{{offer.name}}</h1>
        <div class="flex gap-4 justify-center">
            <p class="text-l md:text-xl lg:text-2xl font-extrabold">{{offer.price}}€</p>
            <div class="relative">
                <span class="absolute top-2 right-2 md:top-3 md:w-8 w-6 h-1 bg-red-600 rotate-45 "></span>
                <p class="text-l md:text-xl lg:text-2xl font-light text-gray-500">{{offer.price+5}}€</p>
            </div>
        </div>
        <button (click)="offerEmitter.emit();"
            class="rounded-md bg-default_grey font-semibold text-white h-[50px] w-[270px] lg:w-[379px]">PRENOTA</button>
        <button (click)="descriptionEmitter.emit();" class="underline font-light">leggi la descrizione</button>


    </div>
</div>