import { Injectable } from '@angular/core';
import { Service } from '../../models/service';
import { CollectionReference, collection, getDocs, getFirestore, orderBy, query, where } from 'firebase/firestore';
import { FirebaseApp } from '@angular/fire/app';

import { ServiceCategory } from '../../models/serviceCategory';
import { Firestore } from '@angular/fire/firestore';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ServicesService {
  private servicesObj: BehaviorSubject<Service[]> = new BehaviorSubject<Service[]>([]);
  public servicesObserver: Observable<Service[]> = this.servicesObj.asObservable();
  private serviceCategoryObj: BehaviorSubject<ServiceCategory | null> = new BehaviorSubject<ServiceCategory | null>(null);
  public serviceCategoryObserver: Observable<ServiceCategory | null> = this.serviceCategoryObj.asObservable();
  private serviceCategoriesObj: BehaviorSubject<ServiceCategory[]> = new BehaviorSubject<ServiceCategory[]>([]);
  public serviceCategoriesObserver: Observable<ServiceCategory[]> = this.serviceCategoriesObj.asObservable();
  private db: Firestore;
  private serviceCollection: CollectionReference;
  private categoryCollection: CollectionReference;

  constructor(private app: FirebaseApp) {
    this.db = getFirestore(this.app);
    this.serviceCollection = collection(this.db, 'services');
    this.categoryCollection = collection(this.db, 'servicesCategory');
    this.getServices();
    this.getServiceCategories();
  }

  private async getServices() {
    let services: Service[] = [];
    const q = query(this.serviceCollection, orderBy('position', 'asc'), where("available", "==", true));
    getDocs(q).then((docs) => {
      docs.forEach((doc) => {
        let service = doc.data() as Service;
        service.id = doc.id;
        if (!service.id.includes("busy"))
          services.push(service);
      });
      this.servicesObj.next(services);
    });
  }

  public setServiceCategory(serviceCategory: ServiceCategory | null) {
    this.serviceCategoryObj.next(serviceCategory);
  }

  private async getServiceCategories() {
    let serviceCategories: ServiceCategory[] = [];
    const q = query(this.categoryCollection, orderBy('position', 'asc'), where("available", "==", true));
    getDocs(q).then((docs) => {
      docs.forEach((doc) => {
        let service = doc.data() as ServiceCategory;
        service.id = doc.id;
        if (!service.id.includes("busy"))
          serviceCategories.push(service);
      });
      this.serviceCategoriesObj.next(serviceCategories);
    });
  }

}
