export class Offer { 
    id: string = "";
    description: string = "";
    serviceName: string = "";
    validStores: string[] = [];
    offerType: string = "";
    isRefresh: boolean = false;
    price: number = 0;
    service: string = "";
    name: string = "";
    refreshDays: number = 0;
    startDate: Date = new Date();
    endDate: Date = new Date();
    startTime: Date = new Date();
    endTime: Date = new Date();
    usageType: string = "";
    imageUrl: string = "";
    storagePath: string = "";
    active: boolean = false;
    validDays: any[] = [];
    canUse: boolean = false;
    used: boolean = false;
    refreshable: boolean = false;
    bestSeller: boolean = false;

    constructor() { }
  }