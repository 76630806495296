import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlaystoreIconComponent } from '../../miscellaneous/playstore-icon/playstore-icon.component';
import { AppstoreIconComponent } from '../../miscellaneous/appstore-icon/appstore-icon.component';
import { FacebookIconComponent } from '../../miscellaneous/facebook-icon/facebook-icon.component';
import { InstagramIconComponent } from '../../miscellaneous/instagram-icon/instagram-icon.component';
import { Store } from '../../../models/store';
import { StoreService } from '../../../services/store/store.service';
import { Router, RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faFacebook, faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faAppStore, faGooglePlay, faYoutube } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [ FontAwesomeModule, RouterModule,CommonModule, PlaystoreIconComponent, AppstoreIconComponent, FacebookIconComponent, InstagramIconComponent],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.css'
})
export class FooterComponent {
  protected stores: Store[] = [];
  protected faFacebook = faFacebook;
  protected faInstagram = faInstagram;
  protected faTiktok = faTiktok;
  protected faEnvelope = faEnvelope;
  protected faAppStore = faAppStore;
  protected faGooglePlay = faGooglePlay;
  protected faYoutube = faYoutube;
  

  protected urls = {
    playstore: 'https://play.google.com/store/apps/details?id=com.mavir.retroscenaapp&hl=it&gl=US&pli=1',
    appstore: 'https://apps.apple.com/it/app/retroscena/id1452944765',
    facebook: 'https://www.facebook.com/retroscenabarbieri/?locale=it_IT',
    instagram: 'https://www.instagram.com/retroscenabarbieri/?hl=it',
    tiktok: 'https://www.tiktok.com/@retroscenabarbieri?_t=8jJ2WFtg9Ro&_r=1',
    email: 'mailto:info@retroscenabarbieri.it',
    youtube: 'https://www.youtube.com/channel/UCjNFI8RWhZwlU_6hijoLZFw',
    workWithUs: 'https://guru.jobs/hrJobApplication/create?webCode=retroscenabarbieri&lang=Italian&vacancyId=4771&trackVacancy=NnpTK21nRktrK3VzbHMrcHJRa2I2UT09'
  };
  

  constructor(private storeService: StoreService, private router: Router) {
    
    this.storeService.storesObserver.subscribe((stores) => {
      this.stores = stores;
    });
  }
}
