import { AddOn } from "./addon";
import { Offer } from "./offer";
import { Operator } from "./operator";
import { Payment } from "./payment";
import { Service } from "./service";
import { Store } from "./store";

export class Reservation{
  id: string = "";
  addOns: AddOn[]= [];
  duration: number = 0;
  executionEnd: Date = new Date();
  executionStart: Date = new Date();
  fullPrice: number = 0;
  offer: Offer = new Offer();
  temporary: {addOns: AddOn[], endDate: any, status: string} = {addOns:[], endDate:"", status:""};
  operator: Operator = new Operator();
  paid: boolean = false;
  payment: Payment = new Payment();
  paymentStatus: string = "";
  paypalTransactionId: string = "";
  service: Service = new Service();
  status: string = "";
  statusNote: string = "";
  store: Store = new Store();
  thirdParties: boolean = false;
  thirdPartiesName: string = "";
  userDisplayName: string = "";
  userId: string = "";
  workstationId: string = "";
  cartId: string = "";
  timestamp: Date = new Date();
}