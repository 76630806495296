export const environment = {
    homePageUrl: 'https://retroscenabarbieri.it',
    wordPressApiBaseUrl: 'https://vmi383496.contaboserver.net/blog',
    apiBaseUrl: 'https://vmi383496.contaboserver.net/restApi',
    getReservationUrl: '/reservation/secure/v1/getSlotOperators',
    postReservationUrl: '/reservation/secure/v1/reserve',
    getAmountUrl: '/payment/secure/v1/getAmount',
    getUserStatusUrl: '/user/secure/v1/status',
    deleteReservationUrl: '/reservation/secure/v1/reservations',
    offersUrl: '/offers/secure/v1/getOffers',
    createCart: '/payment/secure/v1/createCart',
    getCartPayment: '/payment/secure/v1/createPaymentForCart',
    updateCart: '/reservation/secure/v1/carts',
    getReviews: '/webUtils/v1/getReviews',
    getIGLastImages: '/webUtils/v1/getIGLastImages',
    firebase: {
      projectId: 'retroscena-svil',
      appId: '1:790012150218:web:3ca1d716e16ab292468f33',
      databaseURL: 'https://retroscena-svil.firebaseio.com',
      storageBucket: 'retroscena-svil.appspot.com',
      locationId: 'europe-west',
      apiKey: 'AIzaSyA4z07ZOHG1H-wWr2ieLVfeiSI6PiDopm4',
      authDomain: 'retroscena-svil.firebaseapp.com',
      messagingSenderId: '790012150218',
      measurementId: 'G-96GKY3QPNG',
    },};