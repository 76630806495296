import { Injectable } from '@angular/core';
import { Operator } from '../../models/operator';
import { environment } from '../../../environments/environment';
import { CollectionReference, collection, getDocs, getFirestore, orderBy, query, where } from 'firebase/firestore';
import { FirebaseApp } from '@angular/fire/app';
import { Firestore } from '@angular/fire/firestore';
import { BehaviorSubject, Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class OperatorService {
  private operatorObj: BehaviorSubject<Operator[]> = new BehaviorSubject<Operator[]>([]);
  public operatorObserver: Observable<Operator[]> = this.operatorObj.asObservable();
  private db: Firestore;
  private operatorCollection: CollectionReference;

  constructor(private app: FirebaseApp) {
    this.db = getFirestore(this.app);
    this.operatorCollection = collection(this.db, 'operators');
    this.getOperators();
  }

  private getOperators() {
    let operators: Operator[] = [];
    const q = query(this.operatorCollection, orderBy('storeId', 'asc'), where("isAvailable", "==", true));
    getDocs(q).then((docs) => {
      docs.forEach(doc => {
        let operator = doc.data() as Operator;
        operator.id = doc.id;
        operators.push(operator);
      });
      this.operatorObj.next(operators);
    });
  }
}
