<div class="container mx-auto px-6 p-6 min-h-screen">
    <div class=" bg-white rounded-lg shadow-md  p-6">
        <h1 class="text-4xl font-bold mb-4 text-default_grey">Cookie Policy</h1>
        <p class="mb-4 text-gray-700">
            Il nostro sito web utilizza solo cookie funzionali per migliorare la tua esperienza di navigazione. Non
            utilizziamo alcun cookie di tracciamento.
        </p>
        <h2 class="text-2xl font-bold mb-2 text-default_grey">Cosa sono i cookie funzionali?</h2>
        <p class="mb-4 text-gray-700">
            I cookie funzionali sono utilizzati per ricordare le informazioni che cambiano il modo in cui il sito si
            comporta o si presenta, come la lingua preferita o la regione in cui ti trovi.
        </p>
        <h2 class="text-2xl font-bold mb-2 text-default_grey">Cosa sono i cookie di tracciamento?</h2>
        <p class="mb-4 text-gray-700">
            I cookie di tracciamento sono utilizzati per tracciare i visitatori nei siti web. L'intento è quello di
            visualizzare annunci pertinenti e coinvolgenti per il singolo utente e quindi più preziosi per editori e
            inserzionisti di terze parti.
        </p>
        <p class="mb-4 text-gray-700">
            Se hai domande sulla nostra politica sui cookie, non esitare a contattarci.
        </p>
    </div>
</div>