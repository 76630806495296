import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { BehaviorSubject, Observable, from, switchMap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Offer } from '../../models/offer';
import { User as FireUser } from 'firebase/auth';
import { Response } from '../../models/response';
import { getAuth } from 'firebase/auth';
import { User } from '../../models/user';
import { off } from 'firebase/database';

@Injectable({
  providedIn: 'root'
})
export class OfferService {
  private offersSubj: BehaviorSubject<Offer[]> = new BehaviorSubject<Offer[]>([]);
  public offersObserver: Observable<Offer[]> = this.offersSubj.asObservable();
  private offer: Offer | null = null;

  constructor(private http: HttpClient, private authService: AuthService) {
    this.userStatusObserver();
  }

  public setOffer(o: Offer | null ) {
    this.offer = o;
  }

  public getOffer(): Offer | null {
    let ris = this.offer;
    this.offer = null;
    return ris;
  }


  private userStatusObserver() {
    this.authService.userObserver.subscribe({
      next: (user) => {
        if (user) {
          this.getOffers(user);
        } else {
          this.offersSubj.next([]);
        }
      }
    });
  }

  private getOffers(user: User) {
    (this.getOffersRequest(user)).subscribe({
      next: (response) => {
        if (response.status.code == "OK") {
          let ris = response.data.offers as Offer[];
          console.log(ris);
          this.offersSubj.next(ris);
        } else {
          this.offersSubj.error(response);
        }
      },
      error: (error) => {
        this.offersSubj.error(error);
      }
    });
  }

  private getOffersRequest(user: User): Observable<Response> {
    const httpOptions = {
      headers: new HttpHeaders({
        'AuthToken': user.token,
      }),
    };
    return this.http.get<Response>(environment.apiBaseUrl + environment.offersUrl, { headers: httpOptions.headers });
  }

}
