<app-info-card></app-info-card>
<div class="flex justify-center" style="background-image: url('assets/images/grunge_bg.png');">
    <h1 class="text-3xl md:text-5xl lg:text-6xl font-bold my-8 text-white">PRODOTTI</h1>
</div>
<div class="container mx-auto my-6 px-6 min-h-screen">
    <div *ngIf="currentState == states.DEFAULT">
        <div class="text-center" *ngFor="let c of categories">
            <h1 class="text-2xl md:text-3xl lg:text-4xl font-bold my-8">{{c.name}}</h1>
            <div class="flex flex-wrap gap-6 justify-center">
                <app-product-card *ngFor="let p of getProducts(c)" [product]="p" [user]="user" (snackEmitter)="showSnackbar($event)"
                    (descriptionEmitter)="showDescription(p)"></app-product-card>
            </div>
        </div>
    </div>
    <div *ngIf="currentState == states.LOADING">
        <div class="h-screen flex justify-center items-center">
            <ng-lottie [options]="loader" class="lg:h-[300px]"></ng-lottie>
        </div>
    </div>
</div>
<app-snackbar></app-snackbar>