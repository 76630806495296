<div *ngIf="cartProduct">
    <div class="grid gap-y-2 bg-gray-100 p-6 rounded-lg">
        <div class="flex justify-center">
            <img src={{cartProduct.product.img}} class="rounded-lg max-w-[225px]">
        </div>
        <div class="flex justify-center">
            <div class="grid place-content-center text-center">
                <p class="font-bold text-center">{{cartProduct.product.name}}</p>
                <p class="font-light">{{cartProduct.price}}</p>
            </div>
        </div>
        <div class="grid place-content-center text-center">
            <div class="flex gap-4 mb-2">
                <button (click)="rmvQta()" class="px-12 py-2 bg-default_grey text-white">-</button>
                <button (click)="addQta()" class="px-12 py-2 bg-default_grey text-white">+</button>
            </div>
            <p class="font-bold">{{cartProduct.quantity}}</p>
        </div>
    </div>
</div>
<div *ngIf="cartReservation">
    <div class="grid gap-y-2 bg-gray-100 p-6 rounded-lg">
        <div class="flex justify-center">
            <img src={{cartReservation.reservation.service.img}} class="rounded-lg max-w-[225px]">
        </div>
        <div class="flex justify-center">
            <div class="grid place-content-center text-center">
                <p class="font-bold text-center">{{cartReservation.reservation.service.name}}</p>
            </div>
        </div>
    </div>
</div>