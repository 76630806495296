import { Component, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OfferCardComponent } from '../../utilities/offer-card/offer-card.component';
import { OfferService } from '../../../services/offer/offer.service';
import { Offer } from '../../../models/offer';
import { HttpClientModule } from '@angular/common/http';
import { StringsService } from '../../../services/strings/strings.service';
import { SnackbarComponent } from '../../utilities/snackbar/snackbar.component';
import { Router, RouterLink } from '@angular/router';
import { CrossComponent } from "../../miscellaneous/cross/cross.component";
import { AnimationOptions, LottieModule } from 'ngx-lottie';
import { AuthService } from '../../../services/auth/auth.service';
import { InfoCardComponent } from '../../utilities/info-card/info-card.component';
import { getAuth } from 'firebase/auth';
import { User } from '../../../models/user';
import { ServicesService } from '../../../services/services/services.service';
import { Service } from '../../../models/service';

@Component({
  selector: 'app-offers',
  standalone: true,
  templateUrl: './offers.component.html',
  styleUrl: './offers.component.css',
  imports: [RouterLink, InfoCardComponent, CommonModule, OfferCardComponent, SnackbarComponent, CrossComponent, LottieModule]
})
export class OffersComponent extends SnackbarComponent {
  @ViewChild(SnackbarComponent) snackbar!: SnackbarComponent;
  @ViewChild(InfoCardComponent) infoCard!: InfoCardComponent;
  protected offers: Offer[] = [];
  protected services: Service[] = [];
  protected currentState: number = 0;
  protected states = {
    LOADING: 0,
    DEFAULT: 1,
    NO_USER: 2,
    ERROR: 3,
    EMPTY: 4
  }
  protected loader: AnimationOptions = {
    path: 'assets/lottie/loader.json'
  };
  protected emptyOffers: AnimationOptions = {
    path: 'assets/lottie/emptyOffers.json'
  };
  protected errorAnimation: AnimationOptions = {
    path: 'assets/lottie/error.json'
  };
  protected error: string = "";
  protected trace: string = "";
  protected user: User | null = null;

  constructor(private servicesService: ServicesService,private authService: AuthService, private offerService: OfferService, private stringsService: StringsService, private router: Router) {
    super();
    this.userStatusObserver();
  }

  private async userStatusObserver() {
    this.authService.userObserver.subscribe({
      next: (user) => {
        this.user = user;
        if (user) {
          this.servicesStatusObserver();
        } else {
          this.currentState = this.states.NO_USER;
        }
      }
    });
  }

  private servicesStatusObserver() {
    this.servicesService.servicesObserver.subscribe({
      next: (services) => {
        this.services = services;
        this.offerStatusObserver();
      },
      error: async (error) => {
        this.snackbar.showMessage(await this.stringsService.getString(error));
      }
    });
  }

  private async offerStatusObserver() {
    this.offerService.offersObserver.subscribe({
      next: (offers) => {
        this.offers = offers;
        if (this.offers.length > 0) {
          this.currentState = this.states.DEFAULT;
        } else {
          this.currentState = this.states.EMPTY;
        }
      },
      error: async (error) => {
        this.snackbar.showMessage(await this.stringsService.getString(error));
      }
    });
  }

  protected showDescription(o: Offer) {
    this.infoCard.addInfos("Descrizione Offerta", o.description);
    this.infoCard.open();

  }

  protected bookOffer(o: Offer) {
    this.offerService.setOffer(o);
    this.router.navigate(['/book']);
  }

  protected getOfferService(o: Offer): Service { 
    return this.services.find((s) => s.id == o.service)!;
  }
}
