<div *ngIf="reservation" class="fixed z-10 w-full h-full backdrop-blur-xl flex justify-center items-center" >
    <div  class="mx-6 rounded-3xl shadow-2xl bg-white justify-center w-[400px] max-h-[450px] md:w-[500px] lg:w-[600px] lg:max-h-[600px] p-6 ">
        <div class="flex justify-between">
            <h2 class="text-xl md:text-2xl lg:text-3xl font-bold text-center">Prenotazione</h2>
            <app-cross (click)="closeReservation.emit()"></app-cross>
        </div>
        <div class="grid justify-center max-h-[268px]">
            <div class="flex flex-col items-center self-start">
                <qrcode [qrdata]="reservation.id"></qrcode>
                <p class="text-center font-semibold">{{reservation.id}}</p>
            </div>
            <div class="flex justify-between">
                <p>Totale</p>
                <p>{{reservation.fullPrice}}€</p>
            </div>
            <div *ngIf="reservation.status == 'CANCELED'">
                <div class="flex justify-center">
                    <p class="font-bold">{{reservation.statusNote}}</p>
                </div>
            </div>
            <div *ngIf="reservation.paymentStatus == 'CAPTURABLE'">
                <div class="flex justify-center">
                    <p class="font-bold">PAGATO</p>
                </div>
            </div>
            <div *ngIf="reservation.paymentStatus != 'CAPTURABLE'">
                <div *ngIf="canPay()">
                    <button [disabled]="addingToCart" [ngClass]="addingToCart ? 'disabled':''"
                        class="mt-auto w-full bg-green-500 text-white rounded-xl px-4 py-2"
                        (click)="pay()">Paga ora</button>
                </div>
            </div>
        </div>
    </div>
</div>