import { Product } from "./product";

export class CartProduct {
    price!: number;
    product!: Product;
    quantity!: number;

    constructor(product: Product, price: number, quantity: number){
        this.product = product;
        this.price = price;
        this.quantity = quantity;
    }
}