<app-info-card></app-info-card>
<app-snackbar></app-snackbar>
<div class="flex justify-center" style="background-image: url('assets/images/grunge_bg.png');">
    <h1 class="text-3xl md:text-5xl lg:text-6xl font-bold my-8 font-serif text-white">OFFERTE</h1>
</div>
<div class="container mx-auto my-6 px-6 min-h-screen">
    <div *ngIf="currentState == states.DEFAULT">
        <div class="flex flex-wrap gap-6 justify-center">
            <app-offer-card *ngFor="let o of offers" [offer]="o" [service]="getOfferService(o)" (descriptionEmitter)="showDescription(o)"
                (offerEmitter)="bookOffer(o)"></app-offer-card>
        </div>
    </div>
    <div *ngIf="currentState == states.LOADING">
        <div class="h-screen flex justify-center items-center">
            <ng-lottie [options]="loader" class="lg:h-[300px]"></ng-lottie>
        </div>
    </div>
    <div *ngIf="currentState == states.NO_USER">
        <div class="h-screen flex justify-center p-12">
            <div class="flex flex-col gap-y-12">
                <h2 class="text-xl md:text-2xl lg:text-3xl font-bold text-center">Accedi o registrati per visualizzare le offerte</h2>
                <ng-lottie [options]="emptyOffers" class="lg:h-[300px]"></ng-lottie>
                <div class="w-full flex justify-center gap-x-4">   
                    <button class="bg-default_grey  text-white font-bold py-2 px-4 rounded" routerLink="/login">Accedi</button>
                    <button class="bg-default_grey text-white font-bold py-2 px-4 rounded" routerLink="/register">Registrati</button>
                </div>
            
        </div>
    </div>
    <div *ngIf="currentState == states.ERROR">
        <div class="h-screen flex justify-center p-12">
            <div class="flex flex-col gap-y-12">
                <h2 class="text-xl md:text-2xl lg:text-3xl font-bold text-center mb-6">{{error}}</h2>
                <p class="text-l md:text-xl lg:text-2xl font-semibold text-center">trace: {{trace}}</p>
                <ng-lottie [options]="errorAnimation" class="lg:h-[300px]"></ng-lottie>
            </div>
        </div>
    </div>
    <div *ngIf="currentState == states.EMPTY">
        <div class="h-screen flex justify-center p-12">
            <div class="flex flex-col gap-y-12">
                <h2 class="text-xl md:text-2xl lg:text-3xl font-bold text-center">Non ci sono offerte disponibili al
                    momento</h2>
                <ng-lottie [options]="emptyOffers" class="lg:h-[300px]"></ng-lottie>
            </div>
        </div>
    </div>
</div>
<app-snackbar></app-snackbar>