import { Injectable } from '@angular/core';
import { Product } from '../../models/product';
import { Cart } from '../../models/cart';
import { CartProduct } from '../../models/cart-product';
import { AuthService } from '../auth/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Amount } from '../../models/amount';
import { BehaviorSubject, Observable, catchError, from, of, switchMap } from 'rxjs';
import { Response } from '../../models/response';
import { Reservation } from '../../models/reservation';
import { CartReservation } from '../../models/cart-reservation';
import { Payment } from '../../models/payment';
import { CollectionReference, DocumentData, collection, getDocs, getFirestore, orderBy, query, where } from 'firebase/firestore';
import { initializeApp } from 'firebase/app';
import { User } from '../../models/user';
import { FirebaseApp } from '@angular/fire/app';
import { Firestore } from '@angular/fire/firestore';
import { getAuth } from 'firebase/auth';


@Injectable({
  providedIn: 'root'
})
export class CartService {
  private db: Firestore;
  private cartsCollection: CollectionReference;
  private cartSubj: BehaviorSubject<Cart> = new BehaviorSubject<Cart>(new Cart());
  public cartObserver: Observable<Cart> = this.cartSubj.asObservable();
  private user: User | null = null;

  constructor(private app: FirebaseApp, private authService: AuthService, private httpClient: HttpClient) {
    this.db = getFirestore(this.app);
    this.cartsCollection = collection(this.db, 'carts');
    this.userStatusObserver();
  }

  private userStatusObserver() {
    this.authService.userObserver.subscribe({
      next: (user) => {
        this.user = user;
        if (user) {
          this.getCart();
        } else {
          this.cartSubj.next(new Cart());
        }
      }
    });
  }

  public clearCart() {
    this.cartSubj.next(new Cart());
  }

  public getCart() {
    this.getCartRequest().subscribe({
      next: (response) => {
        if (response.status.code == "OK") {
          let cart = response.data.cart as Cart;
          if (cart) {
            this.cartSubj.next(cart);
          } else {
            this.cartSubj.next(new Cart());
          }
        } else {
          throw response
        }
      },
      error: (error) => {
        throw error;
      }
    });
  }

  public getCartRequest(): Observable<Response> {
    if(this.user){
      const httpOptions = {
        headers: new HttpHeaders({
          'AuthToken': this.user.token as string,
        }),
      }
      return this.httpClient.get<Response>(environment.apiBaseUrl + environment.getUserStatusUrl, { headers: httpOptions.headers });
    }
    return new Observable<Response>();
  }

  public addReservationToCart(reservation: Reservation): Observable<boolean> {
    let cartReservation = new CartReservation();
    cartReservation.reservation = reservation;
    this.cartSubj.value.reservations.push(cartReservation);
    return this.updateCart();
  }

  public addProductQuantity(cartP: CartProduct): Observable<boolean> {
    this.cartSubj.value.products.forEach(p => {
      if (p.product.id == cartP.product.id) {
        p.quantity++;
      }
    })
    return this.updateCart();
  }

  public removeProductQuantity(cartP: CartProduct): Observable<boolean> {
    this.cartSubj.value.products.forEach(p => {
      if (p.product.id == cartP.product.id) {
        p.quantity--;
        if (p.quantity == 0) {
          this.removeProduct(p);
        }
      }
    })
    return this.updateCart();
  }

  public addProductToCart(product: Product): Observable<boolean> {
    let index = this.cartSubj.value.products.findIndex(p => p.product.id == product.id);
    if (index >= 0) {
      this.cartSubj.value.products[index].quantity++;
    } else {
      let cartProduct = new CartProduct(product, product.price, 1);
      this.cartSubj.value.products.push(cartProduct);
    }
    if (product.price)
      this.cartSubj.value.totalAmount += product.price;
    return this.updateCart();
  }

  public removeProduct(product: CartProduct): Observable<boolean> {
    this.cartSubj.value.products = this.cartSubj.value.products.filter(p => p.product.id != product.product.id);
    return this.updateCart();
  }



  public updateCart(): Observable<boolean> {
    return this.updateCartRequest().pipe(
      switchMap(response => {
        if (response.status.code == "OK") {
          let cart = response.data.cart as Cart;
          if (cart) {
            this.cartSubj.next(cart);
          } else {
            this.cartSubj.next(new Cart());
          }
          return of(true);
        } else {
          throw response;
        }
      }),
      catchError(error => {
        throw error;
      })
    );
  }

  private updateCartRequest(): Observable<Response> {
    if (this.user) {
      const options = {
        headers: new HttpHeaders({
          'AuthToken': this.user.token as string,
        }),
      };
      let products: { id: string, quantity: number }[] = [];
      let reservations: string[] = [];
      this.cartSubj.value.products.forEach(p => {
        if (p.quantity > 0)
          products.push({ id: p.product.id, quantity: p.quantity });
      });
      this.cartSubj.value.reservations.forEach(r => {
        reservations.push(r.reservation.id);
      });
      const data = {
        "reservations": reservations,
        "products": products,
      }
      return this.httpClient.post<Response>(environment.apiBaseUrl + environment.createCart, data, { headers: options.headers });
    }
    throw new Error("User not logged in");
  }

  public getAmount(store: string, date: string, service: string, offer: string): Observable<Amount> {
    return this.getAmountRequest(store, date, service, offer).pipe(
      switchMap((response: any) => {
        return of(response.data as Amount)
      }),
      catchError(error => { throw error; })
    );
  }

  private getAmountRequest(store: string, date: string, service: string, offer: string): Observable<Response> {
    if(this.user){
      const options = {
        headers: new HttpHeaders({
          'AuthToken': this.user.token as string,
        }),
      };
      const data = {
        "cartItems": [{
          "slotDate": date,
          "storeId": store,
          "serviceId": service,
          "offerId": offer
        }
        ]
      }
      return this.httpClient.post<Response>(environment.apiBaseUrl + environment.getAmountUrl, data, options);
    }
    throw new Error("User not logged in");
  }

  public getCartPayment(): Observable<Payment> {
    return this.getCartPaymentRequest().pipe(
      switchMap(response => {
        if (response.status.code == "OK") {
          let payment = response.data as Payment
          this.cartSubj.value.payment = payment;
          return of(payment);
        } else {
          throw response;
        }
      }),
      catchError(error => {
        throw error;
      })
    );
  }

  private getCartPaymentRequest(): Observable<Response> {
    if(this.user){
      const options = {
        headers: new HttpHeaders({
          'AuthToken': this.user.token as string,
        }),
      };
      const data = {
        'cartId': this.cartSubj.value.id,
      }
      return this.httpClient.post<Response>(environment.apiBaseUrl + environment.getCartPayment, data, options);
    }
    throw new Error("User not logged in");
  }

  private getCartFromData(id: string, data: DocumentData): Cart {
    let cart = data as Cart;
    cart.id = id;
    cart.timestamp = data["timestamp"].toDate();
    for (let i = 0; i < cart.reservations.length; i++) {
      cart.reservations[i].reservation.timestamp = data["reservations"][i].reservation["timestamp"].toDate();
      cart.reservations[i].reservation.executionEnd = data["reservations"][i].reservation["executionEnd"].toDate();
      cart.reservations[i].reservation.executionStart = data["reservations"][i].reservation["executionStart"].toDate();
    }
    return cart;
  }

  public getPayedCarts(): Observable<Cart[]> {
    let ris: Cart[] = [];
    if(this.user){
      let q = query(this.cartsCollection, where("userId", "==", this.user.uid), where("status", "==", "CLOSED"), orderBy("timestamp", "desc"));
      return from(getDocs(q)).pipe(
        switchMap((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            let cart = this.getCartFromData(doc.id, doc.data());
            if (cart.products.length > 0 || cart.reservations.length > 0)
              ris.push(cart);
          });
          return of(ris);
        }),
        catchError(error => { throw error; })
      );
    }
    return of(ris);
  }
}
