import { Inject, Injectable, inject } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { CanActivateFn, Router, UrlSegmentGroup, UrlTree } from '@angular/router';
import { getAuth } from 'firebase/auth';
import { AuthService } from '../../services/auth/auth.service';
import { Observable } from 'rxjs';
import { LoginComponent } from '../../components/pages/login/login.component';


export class AuthGuard {
  constructor(public router: Router) { }
}
export const authenticateGuard: CanActivateFn = (route, state) => {
    return inject(AuthService).isLoggedIn()
};
