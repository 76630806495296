import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Service } from '../../../models/service';

@Component({
  selector: 'app-service-card',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './service-card.component.html',
  styleUrl: './service-card.component.css'
})
export class ServiceCardComponent {
  @Input() service!: Service;
  @Output() descriptionEmitter = new EventEmitter<void>();
  @Output() serviceEmitter = new EventEmitter<boolean>();

  protected showDescription() {
    this.descriptionEmitter.emit();
  }

  protected book(forOther: boolean){
    this.serviceEmitter.emit(forOther);
  }
}
